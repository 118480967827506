<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-integration_hub"
                     *ngIf="!fieldsets.integration_hub.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.integration_hub.collapsible }">
                    <div *ngIf="!fieldsets.integration_hub.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.integration_hub.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.integration_hub.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.integration_hub.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.integration_hub.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.integration_hub.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.integration_hub.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-IntegrationHubId" *ngIf="!fields.IntegrationHubId.hidden" 
                            class="field-container full {{fields.IntegrationHubId.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.IntegrationHubId.styles.style"
                            [ngClass]="fields.IntegrationHubId.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.IntegrationHubId.label + (fields.IntegrationHubId.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.IntegrationHubId.label}}<span *ngIf="fields.IntegrationHubId.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="IntegrationHubId"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.IntegrationHubId.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.IntegrationHubId.control.placeholder}}"
                                [ngStyle]="fields.IntegrationHubId.control.styles.style"
                                [ngClass]="fields.IntegrationHubId.control.styles.classes"
                                [matTooltip]="fields.IntegrationHubId.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.IntegrationHubId.invalid">
                          <ng-container *ngFor="let error of fields.IntegrationHubId.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-IntegrationHubSourceId" *ngIf="!fields.IntegrationHubSourceId.hidden" 
                            class="field-container full {{fields.IntegrationHubSourceId.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.IntegrationHubSourceId.styles.style"
                            [ngClass]="fields.IntegrationHubSourceId.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.IntegrationHubSourceId.label + (fields.IntegrationHubSourceId.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.IntegrationHubSourceId.label}}<span *ngIf="fields.IntegrationHubSourceId.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="IntegrationHubSourceId"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.IntegrationHubSourceId.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.IntegrationHubSourceId.control.placeholder}}"
                                [ngStyle]="fields.IntegrationHubSourceId.control.styles.style"
                                [ngClass]="fields.IntegrationHubSourceId.control.styles.classes"
                                [matTooltip]="fields.IntegrationHubSourceId.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.IntegrationHubSourceId.invalid">
                          <ng-container *ngFor="let error of fields.IntegrationHubSourceId.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-IntegrationHubSourceLookupCode" *ngIf="!fields.IntegrationHubSourceLookupCode.hidden" 
                            class="field-container full {{fields.IntegrationHubSourceLookupCode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.IntegrationHubSourceLookupCode.styles.style"
                            [ngClass]="fields.IntegrationHubSourceLookupCode.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.IntegrationHubSourceLookupCode.label + (fields.IntegrationHubSourceLookupCode.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.IntegrationHubSourceLookupCode.label}}<span *ngIf="fields.IntegrationHubSourceLookupCode.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="IntegrationHubSourceLookupCode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.IntegrationHubSourceLookupCode.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.IntegrationHubSourceLookupCode.control.placeholder}}"
                                [ngStyle]="fields.IntegrationHubSourceLookupCode.control.styles.style"
                                [ngClass]="fields.IntegrationHubSourceLookupCode.control.styles.classes"
                                [matTooltip]="fields.IntegrationHubSourceLookupCode.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.IntegrationHubSourceLookupCode.invalid">
                          <ng-container *ngFor="let error of fields.IntegrationHubSourceLookupCode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-IntegrationHubSourcePlatform" *ngIf="!fields.IntegrationHubSourcePlatform.hidden" 
                            class="field-container full {{fields.IntegrationHubSourcePlatform.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.IntegrationHubSourcePlatform.styles.style"
                            [ngClass]="fields.IntegrationHubSourcePlatform.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.IntegrationHubSourcePlatform.label + (fields.IntegrationHubSourcePlatform.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.IntegrationHubSourcePlatform.label}}<span *ngIf="fields.IntegrationHubSourcePlatform.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="IntegrationHubSourcePlatform"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.IntegrationHubSourcePlatform.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.IntegrationHubSourcePlatform.control.placeholder}}"
                                [ngStyle]="fields.IntegrationHubSourcePlatform.control.styles.style"
                                [ngClass]="fields.IntegrationHubSourcePlatform.control.styles.classes"
                                [matTooltip]="fields.IntegrationHubSourcePlatform.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.IntegrationHubSourcePlatform.invalid">
                          <ng-container *ngFor="let error of fields.IntegrationHubSourcePlatform.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-inbound_transmission"
                     *ngIf="!fieldsets.inbound_transmission.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.inbound_transmission.collapsible }">
                    <div *ngIf="!fieldsets.inbound_transmission.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.inbound_transmission.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.inbound_transmission.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.inbound_transmission.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.inbound_transmission.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.inbound_transmission.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.inbound_transmission.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-In_Type" *ngIf="!fields.In_Type.hidden" 
                            class="field-container full {{fields.In_Type.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.In_Type.styles.style"
                            [ngClass]="fields.In_Type.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.In_Type.label + (fields.In_Type.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.In_Type.label}}<span *ngIf="fields.In_Type.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="In_Type"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.In_Type.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.In_Type.control.placeholder}}"
                                [ngStyle]="fields.In_Type.control.styles.style"
                                [ngClass]="fields.In_Type.control.styles.classes"
                                [matTooltip]="fields.In_Type.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.In_Type.invalid">
                          <ng-container *ngFor="let error of fields.In_Type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-In_CreatedSysDateTime" *ngIf="!fields.In_CreatedSysDateTime.hidden" 
                            class="field-container full {{fields.In_CreatedSysDateTime.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.In_CreatedSysDateTime.styles.style"
                            [ngClass]="fields.In_CreatedSysDateTime.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.In_CreatedSysDateTime.label + (fields.In_CreatedSysDateTime.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.In_CreatedSysDateTime.label}}<span *ngIf="fields.In_CreatedSysDateTime.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="In_CreatedSysDateTime"
                            [format]="fields.In_CreatedSysDateTime.control.format"
                            [mode]="fields.In_CreatedSysDateTime.control.mode"
                            [ngStyle]="fields.In_CreatedSysDateTime.control.styles.style"
                            [ngClass]="fields.In_CreatedSysDateTime.control.styles.classes"
                            [tooltip]="fields.In_CreatedSysDateTime.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.In_CreatedSysDateTime.invalid">
                          <ng-container *ngFor="let error of fields.In_CreatedSysDateTime.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-In_IntegrationId" *ngIf="!fields.In_IntegrationId.hidden" 
                            class="field-container full {{fields.In_IntegrationId.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.In_IntegrationId.styles.style"
                            [ngClass]="fields.In_IntegrationId.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.In_IntegrationId.label + (fields.In_IntegrationId.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.In_IntegrationId.label}}<span *ngIf="fields.In_IntegrationId.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="In_IntegrationId"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.In_IntegrationId.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.In_IntegrationId.control.placeholder}}"
                                [ngStyle]="fields.In_IntegrationId.control.styles.style"
                                [ngClass]="fields.In_IntegrationId.control.styles.classes"
                                [matTooltip]="fields.In_IntegrationId.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.In_IntegrationId.invalid">
                          <ng-container *ngFor="let error of fields.In_IntegrationId.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-In_TransactionId" *ngIf="!fields.In_TransactionId.hidden" 
                            class="field-container full {{fields.In_TransactionId.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.In_TransactionId.styles.style"
                            [ngClass]="fields.In_TransactionId.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.In_TransactionId.label + (fields.In_TransactionId.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.In_TransactionId.label}}<span *ngIf="fields.In_TransactionId.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="In_TransactionId"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.In_TransactionId.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.In_TransactionId.control.placeholder}}"
                                [ngStyle]="fields.In_TransactionId.control.styles.style"
                                [ngClass]="fields.In_TransactionId.control.styles.classes"
                                [matTooltip]="fields.In_TransactionId.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.In_TransactionId.invalid">
                          <ng-container *ngFor="let error of fields.In_TransactionId.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-In_Notes" *ngIf="!fields.In_Notes.hidden" 
                            class="field-container full {{fields.In_Notes.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.In_Notes.styles.style"
                            [ngClass]="fields.In_Notes.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.In_Notes.label + (fields.In_Notes.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.In_Notes.label}}<span *ngIf="fields.In_Notes.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" formControlName="In_Notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.In_Notes.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.In_Notes.control.placeholder}}"
                                [ngStyle]="fields.In_Notes.control.styles.style"
                                [ngClass]="fields.In_Notes.control.styles.classes"
                                [matTooltip]="fields.In_Notes.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> </textarea>
                        <ng-container *ngIf="fields.In_Notes.invalid">
                          <ng-container *ngFor="let error of fields.In_Notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-In_Payload_Xml" *ngIf="!fields.In_Payload_Xml.hidden" 
                            class="field-container full {{fields.In_Payload_Xml.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.In_Payload_Xml.styles.style"
                            [ngClass]="fields.In_Payload_Xml.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.In_Payload_Xml.label + (fields.In_Payload_Xml.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.In_Payload_Xml.label}}<span *ngIf="fields.In_Payload_Xml.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <ngx-codemirror
                                    formControlName="In_Payload_Xml"
                                    [options]="{
                                    theme: 'base16-light',
                                    mode: fields.In_Payload_Xml.control.codeMirrorMode,
                                    lineNumbers: true,
                                    lineWrapping: true,
                                    foldGutter: true,
                                    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
                                    autoCloseBrackets: true,
                                    matchBrackets: true,
                                    lint: true
                                    }"
                                    [CodeValidator]="fields.In_Payload_Xml.control.mode"
                                    [matTooltip]="fields.In_Payload_Xml.control.tooltip"
                                    matTooltipClass="datex-control-tooltip">
                        </ngx-codemirror>
                        
                        <button type="button margin-top-10"
                                (click)="fields.In_Payload_Xml.control.beautify();"
                                class="datex-button secondary ">Beautify</button>
                        <ng-container *ngIf="fields.In_Payload_Xml.invalid">
                          <ng-container *ngFor="let error of fields.In_Payload_Xml.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-In_Payload_Json" *ngIf="!fields.In_Payload_Json.hidden" 
                            class="field-container full {{fields.In_Payload_Json.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.In_Payload_Json.styles.style"
                            [ngClass]="fields.In_Payload_Json.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.In_Payload_Json.label + (fields.In_Payload_Json.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.In_Payload_Json.label}}<span *ngIf="fields.In_Payload_Json.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <ngx-codemirror
                                    formControlName="In_Payload_Json"
                                    [options]="{
                                    theme: 'base16-light',
                                    mode: fields.In_Payload_Json.control.codeMirrorMode,
                                    lineNumbers: true,
                                    lineWrapping: true,
                                    foldGutter: true,
                                    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
                                    autoCloseBrackets: true,
                                    matchBrackets: true,
                                    lint: true
                                    }"
                                    [CodeValidator]="fields.In_Payload_Json.control.mode"
                                    [matTooltip]="fields.In_Payload_Json.control.tooltip"
                                    matTooltipClass="datex-control-tooltip">
                        </ngx-codemirror>
                        
                        <button type="button margin-top-10"
                                (click)="fields.In_Payload_Json.control.beautify();"
                                class="datex-button secondary ">Beautify</button>
                        <ng-container *ngIf="fields.In_Payload_Json.invalid">
                          <ng-container *ngFor="let error of fields.In_Payload_Json.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-outbound_transmission"
                     *ngIf="!fieldsets.outbound_transmission.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.outbound_transmission.collapsible }">
                    <div *ngIf="!fieldsets.outbound_transmission.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.outbound_transmission.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.outbound_transmission.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.outbound_transmission.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.outbound_transmission.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.outbound_transmission.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.outbound_transmission.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-Out_Type" *ngIf="!fields.Out_Type.hidden" 
                            class="field-container full {{fields.Out_Type.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.Out_Type.styles.style"
                            [ngClass]="fields.Out_Type.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.Out_Type.label + (fields.Out_Type.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.Out_Type.label}}<span *ngIf="fields.Out_Type.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="Out_Type"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.Out_Type.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.Out_Type.control.placeholder}}"
                                [ngStyle]="fields.Out_Type.control.styles.style"
                                [ngClass]="fields.Out_Type.control.styles.classes"
                                [matTooltip]="fields.Out_Type.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.Out_Type.invalid">
                          <ng-container *ngFor="let error of fields.Out_Type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-Out_CreatedSysDateTime" *ngIf="!fields.Out_CreatedSysDateTime.hidden" 
                            class="field-container full {{fields.Out_CreatedSysDateTime.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.Out_CreatedSysDateTime.styles.style"
                            [ngClass]="fields.Out_CreatedSysDateTime.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.Out_CreatedSysDateTime.label + (fields.Out_CreatedSysDateTime.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.Out_CreatedSysDateTime.label}}<span *ngIf="fields.Out_CreatedSysDateTime.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="Out_CreatedSysDateTime"
                            [format]="fields.Out_CreatedSysDateTime.control.format"
                            [mode]="fields.Out_CreatedSysDateTime.control.mode"
                            [ngStyle]="fields.Out_CreatedSysDateTime.control.styles.style"
                            [ngClass]="fields.Out_CreatedSysDateTime.control.styles.classes"
                            [tooltip]="fields.Out_CreatedSysDateTime.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="fields.Out_CreatedSysDateTime.invalid">
                          <ng-container *ngFor="let error of fields.Out_CreatedSysDateTime.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-Out_IntegrationId" *ngIf="!fields.Out_IntegrationId.hidden" 
                            class="field-container full {{fields.Out_IntegrationId.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.Out_IntegrationId.styles.style"
                            [ngClass]="fields.Out_IntegrationId.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.Out_IntegrationId.label + (fields.Out_IntegrationId.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.Out_IntegrationId.label}}<span *ngIf="fields.Out_IntegrationId.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="Out_IntegrationId"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.Out_IntegrationId.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.Out_IntegrationId.control.placeholder}}"
                                [ngStyle]="fields.Out_IntegrationId.control.styles.style"
                                [ngClass]="fields.Out_IntegrationId.control.styles.classes"
                                [matTooltip]="fields.Out_IntegrationId.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.Out_IntegrationId.invalid">
                          <ng-container *ngFor="let error of fields.Out_IntegrationId.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-Out_TransactionId" *ngIf="!fields.Out_TransactionId.hidden" 
                            class="field-container full {{fields.Out_TransactionId.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.Out_TransactionId.styles.style"
                            [ngClass]="fields.Out_TransactionId.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.Out_TransactionId.label + (fields.Out_TransactionId.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.Out_TransactionId.label}}<span *ngIf="fields.Out_TransactionId.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="Out_TransactionId"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.Out_TransactionId.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.Out_TransactionId.control.placeholder}}"
                                [ngStyle]="fields.Out_TransactionId.control.styles.style"
                                [ngClass]="fields.Out_TransactionId.control.styles.classes"
                                [matTooltip]="fields.Out_TransactionId.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.Out_TransactionId.invalid">
                          <ng-container *ngFor="let error of fields.Out_TransactionId.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-Out_Notes" *ngIf="!fields.Out_Notes.hidden" 
                            class="field-container full {{fields.Out_Notes.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.Out_Notes.styles.style"
                            [ngClass]="fields.Out_Notes.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.Out_Notes.label + (fields.Out_Notes.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.Out_Notes.label}}<span *ngIf="fields.Out_Notes.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" formControlName="Out_Notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.Out_Notes.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.Out_Notes.control.placeholder}}"
                                [ngStyle]="fields.Out_Notes.control.styles.style"
                                [ngClass]="fields.Out_Notes.control.styles.classes"
                                [matTooltip]="fields.Out_Notes.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> </textarea>
                        <ng-container *ngIf="fields.Out_Notes.invalid">
                          <ng-container *ngFor="let error of fields.Out_Notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-Out_Payload_Xml" *ngIf="!fields.Out_Payload_Xml.hidden" 
                            class="field-container full {{fields.Out_Payload_Xml.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.Out_Payload_Xml.styles.style"
                            [ngClass]="fields.Out_Payload_Xml.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.Out_Payload_Xml.label + (fields.Out_Payload_Xml.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.Out_Payload_Xml.label}}<span *ngIf="fields.Out_Payload_Xml.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <ngx-codemirror
                                    formControlName="Out_Payload_Xml"
                                    [options]="{
                                    theme: 'base16-light',
                                    mode: fields.Out_Payload_Xml.control.codeMirrorMode,
                                    lineNumbers: true,
                                    lineWrapping: true,
                                    foldGutter: true,
                                    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
                                    autoCloseBrackets: true,
                                    matchBrackets: true,
                                    lint: true
                                    }"
                                    [CodeValidator]="fields.Out_Payload_Xml.control.mode"
                                    [matTooltip]="fields.Out_Payload_Xml.control.tooltip"
                                    matTooltipClass="datex-control-tooltip">
                        </ngx-codemirror>
                        
                        <button type="button margin-top-10"
                                (click)="fields.Out_Payload_Xml.control.beautify();"
                                class="datex-button secondary ">Beautify</button>
                        <ng-container *ngIf="fields.Out_Payload_Xml.invalid">
                          <ng-container *ngFor="let error of fields.Out_Payload_Xml.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-Out_Payload_Json" *ngIf="!fields.Out_Payload_Json.hidden" 
                            class="field-container full {{fields.Out_Payload_Json.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.Out_Payload_Json.styles.style"
                            [ngClass]="fields.Out_Payload_Json.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.Out_Payload_Json.label + (fields.Out_Payload_Json.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.Out_Payload_Json.label}}<span *ngIf="fields.Out_Payload_Json.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <ngx-codemirror
                                    formControlName="Out_Payload_Json"
                                    [options]="{
                                    theme: 'base16-light',
                                    mode: fields.Out_Payload_Json.control.codeMirrorMode,
                                    lineNumbers: true,
                                    lineWrapping: true,
                                    foldGutter: true,
                                    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
                                    autoCloseBrackets: true,
                                    matchBrackets: true,
                                    lint: true
                                    }"
                                    [CodeValidator]="fields.Out_Payload_Json.control.mode"
                                    [matTooltip]="fields.Out_Payload_Json.control.tooltip"
                                    matTooltipClass="datex-control-tooltip">
                        </ngx-codemirror>
                        
                        <button type="button margin-top-10"
                                (click)="fields.Out_Payload_Json.control.beautify();"
                                class="datex-button secondary ">Beautify</button>
                        <ng-container *ngIf="fields.Out_Payload_Json.invalid">
                          <ng-container *ngFor="let error of fields.Out_Payload_Json.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-Out_Delete" *ngIf="!fields.Out_Delete.hidden" 
                            class="field-container full {{fields.Out_Delete.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.Out_Delete.styles.style"
                            [ngClass]="fields.Out_Delete.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.Out_Delete.label + (fields.Out_Delete.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.Out_Delete.label}}<span *ngIf="fields.Out_Delete.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.Out_Delete.control.readOnly"
                                [ngStyle]="fields.Out_Delete.control.styles.style"
                                [ngClass]="fields.Out_Delete.control.styles.classes"
                                [matTooltip]="fields.Out_Delete.control.tooltip"
                                matTooltipClass="datex-control-tooltip"
                                (click)="on_out_delete($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.Out_Delete.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.Out_Delete.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.Out_Delete.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.Out_Delete.invalid">
                          <ng-container *ngFor="let error of fields.Out_Delete.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
      </ng-container>

    </div>
  </div>
</div>