import { BarcodeParsing_Types } from './BarcodeParsing.frontend.types'
import { Utilities_Types } from './Utilities.frontend.types'
import { Addresses_Types } from './Addresses.frontend.types'
import { Attachments_Types } from './Attachments.frontend.types'
import { Carriers_Types } from './Carriers.frontend.types'
import { Cartonization_Types } from './Cartonization.frontend.types'
import { EntityImport_Types } from './EntityImport.frontend.types'
import { Equipment_Types } from './Equipment.frontend.types'
import { ExcelMaterialImport_Types } from './ExcelMaterialImport.frontend.types'
import { ExcelProjectImport_Types } from './ExcelProjectImport.frontend.types'
import { Inspections_Types } from './Inspections.frontend.types'
import { Instructions_Types } from './Instructions.frontend.types'
import { InventoryTransfers_Types } from './InventoryTransfers.frontend.types'
import { LaborManagement_Types } from './LaborManagement.frontend.types'
import { LoadContainers_Types } from './LoadContainers.frontend.types'
import { PrintNode_Types } from './PrintNode.frontend.types'
import { PackVerification_Types } from './PackVerification.frontend.types'
import { PalletTransactions_Types } from './PalletTransactions.frontend.types'
import { Replenishments_Types } from './Replenishments.frontend.types'
import { Returns_Types } from './Returns.frontend.types'
import { Surveys_Types } from './Surveys.frontend.types'
import { TemperatureReadings_Types } from './TemperatureReadings.frontend.types'
import { TransloadOrders_Types } from './TransloadOrders.frontend.types'
import { WorkOrders_Types } from './WorkOrders.frontend.types'
import { Lots_Types } from './Lots.frontend.types'
import { ExcelLocationImport_Types } from './ExcelLocationImport.frontend.types'
import { SerialNumbers_Types } from './SerialNumbers.frontend.types'
import { InventoryCounts_Types } from './InventoryCounts.frontend.types'
import { AsnOrders_Types } from './AsnOrders.frontend.types'
import { Owners_Types } from './Owners.frontend.types'
import { Discussions_Types } from './Discussions.frontend.types'
import { WarehouseTransfers_Types } from './WarehouseTransfers.frontend.types'
import { ExcelOrderImport_Types } from './ExcelOrderImport.frontend.types'
import { Document360_Types } from './Document360.frontend.types'
import { Usersnap_Types } from './Usersnap.frontend.types'
import { BarTender_Types } from './BarTender.frontend.types'
import { Notifications_Types } from './Notifications.frontend.types'
import { Manifesting_Types } from './Manifesting.frontend.types'
import { ShippingContainers_Types } from './ShippingContainers.frontend.types'
import { Inventory_Types } from './Inventory.frontend.types'
import { DockAppointments_Types } from './DockAppointments.frontend.types'
import { Waves_Types } from './Waves.frontend.types'
import { Invoices_Types } from './Invoices.frontend.types'
import { SalesOrders_Types } from './SalesOrders.frontend.types'
import { Materials_Types } from './Materials.frontend.types'
import { Locations_Types } from './Locations.frontend.types'
import { PurchaseOrders_Types } from './PurchaseOrders.frontend.types'
import { Catalogs_Types } from './Catalogs.frontend.types'
import { ExcelInventoryImport_Types } from './ExcelInventoryImport.frontend.types'

export namespace FootPrintManager_Types {
}

export namespace $frontendTypes {
  export import BarcodeParsing =  BarcodeParsing_Types;
  export import Utilities =  Utilities_Types;
  export import Addresses =  Addresses_Types;
  export import Attachments =  Attachments_Types;
  export import Carriers =  Carriers_Types;
  export import Cartonization =  Cartonization_Types;
  export import EntityImport =  EntityImport_Types;
  export import Equipment =  Equipment_Types;
  export import ExcelMaterialImport =  ExcelMaterialImport_Types;
  export import ExcelProjectImport =  ExcelProjectImport_Types;
  export import Inspections =  Inspections_Types;
  export import Instructions =  Instructions_Types;
  export import InventoryTransfers =  InventoryTransfers_Types;
  export import LaborManagement =  LaborManagement_Types;
  export import LoadContainers =  LoadContainers_Types;
  export import PrintNode =  PrintNode_Types;
  export import PackVerification =  PackVerification_Types;
  export import PalletTransactions =  PalletTransactions_Types;
  export import Replenishments =  Replenishments_Types;
  export import Returns =  Returns_Types;
  export import Surveys =  Surveys_Types;
  export import TemperatureReadings =  TemperatureReadings_Types;
  export import TransloadOrders =  TransloadOrders_Types;
  export import WorkOrders =  WorkOrders_Types;
  export import Lots =  Lots_Types;
  export import ExcelLocationImport =  ExcelLocationImport_Types;
  export import SerialNumbers =  SerialNumbers_Types;
  export import InventoryCounts =  InventoryCounts_Types;
  export import AsnOrders =  AsnOrders_Types;
  export import Owners =  Owners_Types;
  export import Discussions =  Discussions_Types;
  export import WarehouseTransfers =  WarehouseTransfers_Types;
  export import ExcelOrderImport =  ExcelOrderImport_Types;
  export import Document360 =  Document360_Types;
  export import Usersnap =  Usersnap_Types;
  export import BarTender =  BarTender_Types;
  export import Notifications =  Notifications_Types;
  export import Manifesting =  Manifesting_Types;
  export import ShippingContainers =  ShippingContainers_Types;
  export import Inventory =  Inventory_Types;
  export import DockAppointments =  DockAppointments_Types;
  export import Waves =  Waves_Types;
  export import Invoices =  Invoices_Types;
  export import SalesOrders =  SalesOrders_Types;
  export import Materials =  Materials_Types;
  export import Locations =  Locations_Types;
  export import PurchaseOrders =  PurchaseOrders_Types;
  export import Catalogs =  Catalogs_Types;
  export import ExcelInventoryImport =  ExcelInventoryImport_Types;
  export import FootPrintManager = FootPrintManager_Types;
}




