<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-line_details" *ngIf="!fields.line_details.hidden" 
                            class="field-container double {{fields.line_details.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.line_details.styles.style"
                            [ngClass]="fields.line_details.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.line_details.label + (fields.line_details.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.line_details.label}}<span *ngIf="fields.line_details.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.line_details.control.styles.style"
                              [ngClass]="fields.line_details.control.styles.classes"
                              [matTooltip]="fields.line_details.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.line_details.control.text }}</div>
                        <ng-container *ngIf="fields.line_details.invalid">
                          <ng-container *ngFor="let error of fields.line_details.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.kit_components.hidden" class="tab" data-cy="tab-kit_components">
              <h2 [className]="tabs.kit_components.active? 'active': ''" (click)="tabs.kit_components.activate()">{{tabs.kit_components.title}}</h2>
            </div>
            <div *ngIf="!tabs.kit_lines.hidden" class="tab" data-cy="tab-kit_lines">
              <h2 [className]="tabs.kit_lines.active? 'active': ''" (click)="tabs.kit_lines.activate()">{{tabs.kit_lines.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-sales_order_line_kit_grid *ngIf="tabs.kit_components.active"
              #$tabs_kit_components
              [material_id]="$tabs_kit_components_sales_order_line_kit_grid_inParams_material_id"
              [packaging_id]="$tabs_kit_components_sales_order_line_kit_grid_inParams_packaging_id"
              [project_id]="$tabs_kit_components_sales_order_line_kit_grid_inParams_project_id"
              [order_id]="$tabs_kit_components_sales_order_line_kit_grid_inParams_order_id"
              [line_number]="$tabs_kit_components_sales_order_line_kit_grid_inParams_line_number"
              [line_status_id]="$tabs_kit_components_sales_order_line_kit_grid_inParams_line_status_id"
              [packaged_amount]="$tabs_kit_components_sales_order_line_kit_grid_inParams_packaged_amount"
              [warehouse_id]="$tabs_kit_components_sales_order_line_kit_grid_inParams_warehouse_id"
              [shipment_id]="$tabs_kit_components_sales_order_line_kit_grid_inParams_shipment_id"
              ($refreshEvent)="refresh(false, false, '$tabs_kit_components')">
              </FootPrintManager-sales_order_line_kit_grid>
              <FootPrintManager-sales_order_line_components_grid *ngIf="tabs.kit_lines.active"
              #$tabs_kit_lines
              [order_id]="$tabs_kit_lines_sales_order_line_components_grid_inParams_order_id"
              [shipment_id]="$tabs_kit_lines_sales_order_line_components_grid_inParams_shipment_id"
              [project_id]="$tabs_kit_lines_sales_order_line_components_grid_inParams_project_id"
              [order_status_id]="$tabs_kit_lines_sales_order_line_components_grid_inParams_order_status_id"
              [warehouse_id]="$tabs_kit_lines_sales_order_line_components_grid_inParams_warehouse_id"
              [line_number]="$tabs_kit_lines_sales_order_line_components_grid_inParams_line_number"
              ($refreshEvent)="refresh(false, false, '$tabs_kit_lines')">
              </FootPrintManager-sales_order_line_components_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>