import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Inventory_container_type_categories_dd_singleComponent } from './Inventory.container_type_categories_dd_single.component'
import { Inventory_measurement_units_dd_singleComponent } from './Inventory.measurement_units_dd_single.component'
import { Inventory_container_type_styles_dd_singleComponent } from './Inventory.container_type_styles_dd_single.component'


interface IFootPrintManager_container_types_gridComponentEntity {
Id?: number, Active?: boolean, CatchAll?: boolean, ContainerTypeCategories?: number, Description?: string, DimensionUomId?: number, Height?: number, InnerHeight?: number, InnerLength?: number, InnerWidth?: number, Length?: number, Name?: string, VolumeCapacity?: number, VolumeUomId?: number, Weight?: number, WeightCapacity?: number, WeightUomId?: number, Width?: number, DimensionUom?: { Short_name?: string }, VolumeUom?: { Short_name?: string }, WeightUom?: { Short_name?: string }, Style?: { Id?: number, Name?: string }}

interface IFootPrintManager_container_types_gridComponentInParams {
  ownerId?: number, projectId?: number, materialId?: number}


class FootPrintManager_container_types_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_container_types_gridComponent;
  entity: IFootPrintManager_container_types_gridComponentEntity;


  options: { on_instructions_clicked: ButtonModel } = {
    on_instructions_clicked: new ButtonModel('on_instructions_clicked', new ButtonStyles(null, null), false, 'Instructions', '', null)

  }

 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    type_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    active_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    active_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    catch_all_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    catch_all_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    length_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    inner_length_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    width_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    inner_width_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    height_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    inner_height_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    dimension_uom_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    weight_capacity_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    weight_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    weight_uom_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    volume_capacity_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    volume_uom_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    description_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    style_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['name_edit'] as DatexFormControl, null, false, '', null)
      ),
    type: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['type_edit'] as DatexFormControl, 
        null, null,
        false, 
        '', null)
      ),
    active: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['active_display'] as DatexFormControl, null, false, '', null)
,
      new CheckBoxModel(this.formGroup.controls['active_edit'] as DatexFormControl, null, false, '', null)
      ),
    catch_all: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['catch_all_display'] as DatexFormControl, null, false, '', null)
,
      new CheckBoxModel(this.formGroup.controls['catch_all_edit'] as DatexFormControl, null, false, '', null)
      ),
    length: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['length_edit'] as DatexFormControl, null, false, '', '', null)
      ),
    inner_length: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['inner_length_edit'] as DatexFormControl, null, false, '', '', null)
      ),
    width: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['width_edit'] as DatexFormControl, null, false, '', '', null)
      ),
    inner_width: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['inner_width_edit'] as DatexFormControl, null, false, '', '', null)
      ),
    height: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['height_edit'] as DatexFormControl, null, false, '', '', null)
      ),
    inner_height: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['inner_height_edit'] as DatexFormControl, null, false, '', '', null)
      ),
    dimension_uom: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['dimension_uom_edit'] as DatexFormControl, 
        null, null,
        false, 
        '', null)
      ),
    weight_capacity: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['weight_capacity_edit'] as DatexFormControl, null, false, '', '', null)
      ),
    weight: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['weight_edit'] as DatexFormControl, null, false, '', '', null)
      ),
    weight_uom: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['weight_uom_edit'] as DatexFormControl, 
        null, null,
        false, 
        '', null)
      ),
    volume_capacity: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new NumberBoxModel(this.formGroup.controls['volume_capacity_edit'] as DatexFormControl, null, false, '', '', null)
      ),
    volume_uom: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['volume_uom_edit'] as DatexFormControl, 
        null, null,
        false, 
        '', null)
      ),
    description: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
      new TextBoxModel(this.formGroup.controls['description_edit'] as DatexFormControl, null, false, '', null)
      ),
    style: new GridCellModel(
      new CellStyles(['left'], null),
      new TextModel(null, null, null, null, null)
,
      new SelectBoxModel(
        this.formGroup.controls['style_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '', null)
      ),
  }

  get $fields_dimension_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = 1;
    
    return expr;
  }
  get $fields_weight_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = 3;
    
    return expr;
  }
  get $fields_volume_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = 2;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_container_types_gridComponent, entity: IFootPrintManager_container_types_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_container_types_gridComponent, entity?: IFootPrintManager_container_types_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      fullTextSearch:  $grid.fullTextSearch ,
      typeIds:  null ,
      active:  true ,
      containerTypesCategoryIds:  null ,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      materialId:  $grid.inParams.materialId ,
    };
    const data = await this.datasources.Inventory.ds_container_types_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.name.displayControl as TextModel).text = $row.entity.Name;
    (this.cells.name.editControl as TextBoxModel).reset($row.entity.Name);
    (this.cells.type.editControl as SelectBoxModel).reset($row.entity.ContainerTypeCategories);
    (this.cells.active.displayControl as CheckBoxModel).reset($row.entity.Active);
    (this.cells.active.editControl as CheckBoxModel).reset($row.entity.Active);
    (this.cells.catch_all.displayControl as CheckBoxModel).reset($row.entity.CatchAll);
    (this.cells.catch_all.editControl as CheckBoxModel).reset($row.entity.CatchAll);
    (this.cells.length.displayControl as TextModel).text = $row.entity.Length?.toString();
    (this.cells.length.editControl as NumberBoxModel).reset($row.entity.Length);
    (this.cells.inner_length.displayControl as TextModel).text = $row.entity.InnerLength?.toString();
    (this.cells.inner_length.editControl as NumberBoxModel).reset($row.entity.InnerLength);
    (this.cells.width.displayControl as TextModel).text = $row.entity.Width?.toString();
    (this.cells.width.editControl as NumberBoxModel).reset($row.entity.Width);
    (this.cells.inner_width.displayControl as TextModel).text = $row.entity.InnerWidth?.toString();
    (this.cells.inner_width.editControl as NumberBoxModel).reset($row.entity.InnerWidth);
    (this.cells.height.displayControl as TextModel).text = $row.entity.Height?.toString();
    (this.cells.height.editControl as NumberBoxModel).reset($row.entity.Height);
    (this.cells.inner_height.displayControl as TextModel).text = $row.entity.InnerHeight?.toString();
    (this.cells.inner_height.editControl as NumberBoxModel).reset($row.entity.InnerHeight);
    (this.cells.dimension_uom.displayControl as TextModel).text = $row.entity.DimensionUom?.Short_name?.toString();
    (this.cells.dimension_uom.editControl as SelectBoxModel).reset($row.entity.DimensionUomId);
    (this.cells.weight_capacity.displayControl as TextModel).text = $row.entity.WeightCapacity?.toString();
    (this.cells.weight_capacity.editControl as NumberBoxModel).reset($row.entity.WeightCapacity);
    (this.cells.weight.displayControl as TextModel).text = $row.entity.Weight?.toString();
    (this.cells.weight.editControl as NumberBoxModel).reset($row.entity.Weight);
    (this.cells.weight_uom.displayControl as TextModel).text = $row.entity.WeightUom?.Short_name?.toString();
    (this.cells.weight_uom.editControl as SelectBoxModel).reset($row.entity.WeightUomId);
    (this.cells.volume_capacity.displayControl as TextModel).text = $row.entity.VolumeCapacity?.toString();
    (this.cells.volume_capacity.editControl as NumberBoxModel).reset($row.entity.VolumeCapacity);
    (this.cells.volume_uom.displayControl as TextModel).text = $row.entity.VolumeUom?.Short_name?.toString();
    (this.cells.volume_uom.editControl as SelectBoxModel).reset($row.entity.VolumeUomId);
    (this.cells.description.displayControl as TextModel).text = $row.entity.Description;
    (this.cells.description.editControl as TextBoxModel).reset($row.entity.Description);
    (this.cells.style.displayControl as TextModel).text = $row.entity.Style?.Name;
    (this.cells.style.editControl as SelectBoxModel).reset($row.entity.Style?.Id);

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootPrintManager_container_types_gridComponentRowModel,
  $grid: FootPrintManager_container_types_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const nextId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'ContainerType' })).nextId;
  
  $row.entity = {
      Id: nextId,
      Active: true
  };
  
  
  
  
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootPrintManager_container_types_gridComponentRowModel,
  $grid: FootPrintManager_container_types_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $row.cells.name.editControl.value,
      $row.cells.type.editControl.value
  );
  
  if (allRequiredFieldHaveValue === false) {
      $shell.Inventory.openErrorDialog('Save', 'Missing required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  }
  
  // Check for container type duplicates
  const containerTypes = (await $datasources.Inventory.ds_get_containertype_by_name.get({ name: $row.cells.name.editControl.value })).result;
  if ($utils.isDefined(containerTypes)) {
      $shell.Inventory.openErrorDialog('Container type edit error', 'Container type name already exists.');
      return;
  
  }
  
  else {
  
      try {
          const payload = {
  
              "Id": $row.entity.Id,
              "Name": $row.cells.name.editControl.value,
              "ContainerTypeCategories": $row.cells.type.editControl.value,
              "Length": $row.cells.length.editControl.value,
              "InnerLength": $row.cells.inner_length.editControl.value,
              "Width": $row.cells.width.editControl.value,
              "InnerWidth": $row.cells.inner_width.editControl.value,
              "Height": $row.cells.height.editControl.value,
              "InnerHeight": $row.cells.inner_height.editControl.value,
              "DimensionUomId": $row.cells.dimension_uom.editControl.value,
              "WeightCapacity": $row.cells.weight_capacity.editControl.value,
              "Weight": $row.cells.weight.editControl.value,
              "WeightUomId": $row.cells.weight_uom.editControl.value,
              "VolumeCapacity": $row.cells.volume_capacity.editControl.value,
              "VolumeUomId": $row.cells.volume_uom.editControl.value,
              "Active": true,
              "CatchAll": $utils.isDefined($row.cells.catch_all.editControl.value) ? $row.cells.catch_all.editControl.value : false,
              "Description":$row.cells.description.editControl.value,
              "StyleId": $row.cells.style.editControl.value
  
  
          };
          if ($row.cells.name.editControl.isChanged) {
              payload.Name = $row.cells.name.editControl.value;
          }
  
  
          await $flows.Utilities.crud_create_flow({ entitySet: 'ContainerTypes', entity: payload });
  
          $grid.refresh();
  
      }
      catch (error) {
          $shell.Inventory.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  
  }
  
  
  
  
  
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_container_types_gridComponentRowModel,
  $grid: FootPrintManager_container_types_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  // Update container type
  let payload: any = {};
  
  if ($row.cells.name.editControl.isChanged) {
      // Check for container type duplicates
      const containerTypes = (await $datasources.Inventory.ds_get_containertype_by_name.get({ name: $row.cells.name.editControl.value })).result;
      if ($utils.isDefined(containerTypes)) {
          $shell.Inventory.openErrorDialog('Container type edit drror', 'Container type name already exists.');
          return;
  
      }
      else {
          payload.Name = $row.cells.name.editControl.value;
      }
  }
  
  
  if ($row.cells.type.editControl.isChanged) {
      payload.ContainerTypeCategories = $row.cells.type.editControl.value;
  }
  if ($row.cells.length.editControl.isChanged) {
      payload.Length = $row.cells.length.editControl.value;
  }
  if ($row.cells.width.editControl.isChanged) {
      payload.Width = $row.cells.width.editControl.value;
  }
  if ($row.cells.height.editControl.isChanged) {
      payload.Height = $row.cells.height.editControl.value;
  }
  if ($row.cells.inner_length.editControl.isChanged) {
      payload.InnerLength = $row.cells.inner_length.editControl.value;
  }
  if ($row.cells.inner_width.editControl.isChanged) {
      payload.InnerWidth = $row.cells.inner_width.editControl.value;
  }
  if ($row.cells.inner_height.editControl.isChanged) {
      payload.InnerHeight = $row.cells.inner_height.editControl.value;
  }
  if ($row.cells.dimension_uom.editControl.isChanged) {
      payload.DimensionUomId = $row.cells.dimension_uom.editControl.value;
  }
  if ($row.cells.weight_capacity.editControl.isChanged) {
      payload.WeightCapacity = $row.cells.weight_capacity.editControl.value;
  }
  if ($row.cells.weight.editControl.isChanged) {
      payload.Weight = $row.cells.weight.editControl.value;
  }
  if ($row.cells.weight_uom.editControl.isChanged) {
      payload.WeightUomId = $row.cells.weight_uom.editControl.value;
  }
  if ($row.cells.volume_capacity.editControl.isChanged) {
      payload.VolumeCapacity = $row.cells.volume_capacity.editControl.value;
  }
  if ($row.cells.volume_uom.editControl.isChanged) {
      payload.VolumeUomId = $row.cells.volume_uom.editControl.value;
  }
  if ($row.cells.description.editControl.isChanged) {
      payload.Description = $row.cells.description.editControl.value;
  }
  if ($row.cells.active.editControl.isChanged) {
      payload.Active = $row.cells.active.editControl.value;
  }
  if ($row.cells.catch_all.editControl.isChanged) {
      payload.CatchAll = $row.cells.catch_all.editControl.value;
  }
  if ($row.cells.style.editControl.isChanged) {
      payload.StyleId = $row.cells.style.editControl.value;
  }
  
  
  try {
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'ContainerTypes', id: $row.entity.Id, entity: payload });
      await $row.refresh();
  
  }
  
  catch (error) {
      $shell.Carriers.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  
  }
  
  }
  on_row_instructions_clicked(event = null) {
    return this.on_row_instructions_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_instructions_clickedInternal(
    $row: FootPrintManager_container_types_gridComponentRowModel,
  $grid: FootPrintManager_container_types_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootPrintManager.openinstructions_gridDialog({
      entityName: 'ContainerType',
      entityKey1: $row.entity.Id,
      enabled: true
  }, 'modal', EModalSize.Xlarge);
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_container_types_gridComponentRowModel,
  $grid: FootPrintManager_container_types_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const containerTypeCategory = $row.entity.ContainerTypeCategories
  
  switch (containerTypeCategory) {
    case 1:
      $row.cells.type.displayControl.text = 'Shipping';
      break;
    case 2:
      $row.cells.type.displayControl.text = 'Licenseplate';
      break;
    case 3:
      $row.cells.type.displayControl.text = 'Licenseplate or Shipping';
      break;
     case 4:
      $row.cells.type.displayControl.text = 'Tote';
      break;
    case 5:
      $row.cells.type.displayControl.text = 'Shipping or Tote';
      break;
    case 6:
      $row.cells.type.displayControl.text = 'Licenseplate or Tote';
      break;
    case 7:
      $row.cells.type.displayControl.text = 'Shipping or Licenseplate or Tote';
      break;
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Inventory_container_type_categories_dd_singleComponent),
    forwardRef(() => Inventory_measurement_units_dd_singleComponent),
    forwardRef(() => Inventory_container_type_styles_dd_singleComponent),
  ],
  selector: 'FootPrintManager-container_types_grid',
  templateUrl: './FootPrintManager.container_types_grid.component.html'
})
export class FootPrintManager_container_types_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_container_types_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_container_types_gridComponentInParams = { ownerId: null, projectId: null, materialId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     name: new GridHeaderModel(new HeaderStyles(null, null), 'Name', true, false, null),       type: new GridHeaderModel(new HeaderStyles(null, null), 'Type', true, false, null),       active: new GridHeaderModel(new HeaderStyles(null, null), 'Active', false, false, null),       catch_all: new GridHeaderModel(new HeaderStyles(null, null), 'Catch all', false, false, null),       length: new GridHeaderModel(new HeaderStyles(null, null), 'Length', false, false, null),       inner_length: new GridHeaderModel(new HeaderStyles(null, null), 'Inner length', false, false, null),       width: new GridHeaderModel(new HeaderStyles(null, null), 'Width', false, false, null),       inner_width: new GridHeaderModel(new HeaderStyles(null, null), 'Inner width', false, false, null),       height: new GridHeaderModel(new HeaderStyles(null, null), 'Height', false, false, null),       inner_height: new GridHeaderModel(new HeaderStyles(null, null), 'Inner height', false, false, null),       dimension_uom: new GridHeaderModel(new HeaderStyles(null, null), 'UOM', false, false, null),       weight_capacity: new GridHeaderModel(new HeaderStyles(null, null), 'Weight capacity', false, false, null),       weight: new GridHeaderModel(new HeaderStyles(null, null), 'Container weight', false, false, null),       weight_uom: new GridHeaderModel(new HeaderStyles(null, null), 'UOM', false, false, null),       volume_capacity: new GridHeaderModel(new HeaderStyles(null, null), 'Volume capacity', false, false, null),       volume_uom: new GridHeaderModel(new HeaderStyles(null, null), 'UOM', false, false, null),       description: new GridHeaderModel(new HeaderStyles(null, null), 'Description', false, false, null),       style: new GridHeaderModel(new HeaderStyles(['left'], null), 'Style', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_container_types_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('materialId') set $inParams_materialId(value: any) {
    this.inParams['materialId'] = value;
  }
  get $inParams_materialId(): any {
    return this.inParams['materialId'] ;
  }

  topToolbar = {
      assign_containers_button: new ToolModel(new ButtonModel('assign_containers_button', new ButtonStyles(null, null), false, 'Assign', 'icon-ic_fluent_box_20_regular', null)
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(null, null), false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Container types';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 50;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      fullTextSearch:  $grid.fullTextSearch ,
      typeIds:  null ,
      active:  true ,
      containerTypesCategoryIds:  null ,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      materialId:  $grid.inParams.materialId ,
    };
    try {
    const data = await this.datasources.Inventory.ds_container_types_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_container_types_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  async addRow(entity?: IFootPrintManager_container_types_gridComponentEntity) {
    const row = new FootPrintManager_container_types_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_container_types_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  
  if (selectedRowsCount > 0) {
      $grid.topToolbar.on_delete.control.readOnly = false;
  }
  else{
      $grid.topToolbar.on_delete.control.readOnly = true;
  }
  }
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $grid: FootPrintManager_container_types_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.Carriers.openErrorDialog('Container Type Delete Error', 'No container type selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              containerTypeId: row.entity.Id
          };
          const result = await $flows.Inventory.is_container_type_deletable_flow(flowParams);
          const reason = result.reasons;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Container Type ${row.entity.Name} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Delete Container Type errors';
          const errorMessage = `Container Type(s) ${failures.map(c => c.entity.Name).join(',')} cannot be deleted`;
          const errorList = errors;
          await $shell.Inventory.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Container Type(s) ${candidates.map(c => c.entity.Name).join(',')} - Once deleted, the Container Type(s) cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some Container Type(s) cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.Inventory.openConfirmationDialog(title, message);
          } else {
              const title = 'Delete the following Container Type(s)';
              const message = confirmCandidates;
              confirm = await $shell.Inventory.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams =  {
                      containerTypeId: candidate.entity.Id
                      }
                      const result = await $flows.Inventory.delete_containertype_flow(flowParams);
                    
                    processSuccess.push(candidate);
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Container Type ${candidate.entity.Name} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All Container Type(s) deleted';
                  const message = `Container Type(s) ${processSuccess.map(c => c.entity.Name).join(',')} deleted`;
                  await $shell.Inventory.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All Container Type(s) failed to delete';
                      const message = `Container Type(s) ${processFailures.map(c => c.entity.Name).join(',')} could not be deleted`;
                      await $shell.Inventory.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some Container Type could not be deleted';
                      const deleted = `Container Type(s) ${processSuccess.map(c => c.entity.Name).join(',')} were deleted.`;
                      const errors = `The following Container Type(s) could not be deleted: ${processFailures.map(c => c.entity.Name).join(',')}`;
                      const message = `${deleted} \r\n\r\n${errors}`;
                      await $shell.Inventory.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_assign_clicked(event = null) {
    return this.on_assign_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_assign_clickedInternal(
    $grid: FootPrintManager_container_types_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let results: string[] = [];
  
  if ($utils.isDefined($grid.inParams.materialId)) {
      if ($grid.selectedRows.length > 0) {
          for (let row of $grid.selectedRows) {
              results = (await $flows.ShippingContainers.container_assignment_flow({ containerTypeId: row.entity.Id, materialId: $grid.inParams.materialId })).reasons
          }
          if ($utils.isDefined(results)) {
              $shell.FootPrintManager.showErrorDetails('Save', 'Error on save.', results);
          }
      }
  }
  if ($utils.isDefined($grid.inParams.ownerId)) {
      if ($grid.selectedRows.length > 0) {
          for (let row of $grid.selectedRows) {
              results = (await $flows.ShippingContainers.container_assignment_flow({ containerTypeId: row.entity.Id, ownerId: $grid.inParams.ownerId, projectId: $grid.inParams.projectId })).reasons
          }
          if ($utils.isDefined(results)) {
              $shell.FootPrintManager.showErrorDetails('Save', 'Error on save.', results);
          }
      }
  }
  
  
  await $grid.refresh();
  $grid.close();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_container_types_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if(!$utils.isDefined($grid.inParams.ownerId) && !$utils.isDefined($grid.inParams.projectId) && !$utils.isDefined($grid.inParams.materialId)){
      $grid.topToolbar.assign_containers_button.hidden = true;
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
