<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-filters"
                     *ngIf="!filtersets.filters.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.filters.collapsible }">
                    <div *ngIf="!filtersets.filters.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.filters.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.filters.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.filters.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.filters.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.filters.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.filters.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-warehouses" *ngIf="!filters.warehouses.hidden" 
                                  class="field-container standard {{filters.warehouses.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.warehouses.styles.style"
                                  [ngClass]="filters.warehouses.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.warehouses.label + (filters.warehouses.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.warehouses.label}}<span *ngIf="filters.warehouses.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Locations-warehouses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.warehouses.control.type"
                                  formControlName="warehouses"
                                  (displayTextChange)="filters.warehouses.control.displayText=$event"
                                  [placeholder]="filters.warehouses.control.placeholder"
                                  [styles]="filters.warehouses.control.styles"
                                  [tooltip]="filters.warehouses.control.tooltip"
                              >
                              </Locations-warehouses_dd_multi>
                              <ng-container *ngIf="filters.warehouses.invalid">
                                <ng-container *ngFor="let error of filters.warehouses.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-contexts" *ngIf="!filters.contexts.hidden" 
                                  class="field-container standard {{filters.contexts.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.contexts.styles.style"
                                  [ngClass]="filters.contexts.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.contexts.label + (filters.contexts.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.contexts.label}}<span *ngIf="filters.contexts.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Surveys-survey_contexts_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.contexts.control.type"
                                  formControlName="contexts"
                                  (displayTextChange)="filters.contexts.control.displayText=$event"
                                  [placeholder]="filters.contexts.control.placeholder"
                                  [styles]="filters.contexts.control.styles"
                                  [tooltip]="filters.contexts.control.tooltip"
                              >
                              </Surveys-survey_contexts_dd_multi>
                              <ng-container *ngIf="filters.contexts.invalid">
                                <ng-container *ngFor="let error of filters.contexts.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-operation_types" *ngIf="!filters.operation_types.hidden" 
                                  class="field-container double {{filters.operation_types.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.operation_types.styles.style"
                                  [ngClass]="filters.operation_types.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.operation_types.label + (filters.operation_types.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.operation_types.label}}<span *ngIf="filters.operation_types.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Surveys-operation_context_types_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.operation_types.control.type"
                                  formControlName="operation_types"
                                  (displayTextChange)="filters.operation_types.control.displayText=$event"
                                  [placeholder]="filters.operation_types.control.placeholder"
                                  [styles]="filters.operation_types.control.styles"
                                  [tooltip]="filters.operation_types.control.tooltip"
                              >
                              </Surveys-operation_context_types_dd_multi>
                              <ng-container *ngIf="filters.operation_types.invalid">
                                <ng-container *ngFor="let error of filters.operation_types.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.submitted_surveys.hidden" class="tab" data-cy="tab-submitted_surveys">
              <h2 [className]="tabs.submitted_surveys.active? 'active': ''" (click)="tabs.submitted_surveys.activate()">{{tabs.submitted_surveys.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-submitted_surveys_grid *ngIf="tabs.submitted_surveys.active"
              #$tabs_submitted_surveys
              [entities]="$tabs_submitted_surveys_submitted_surveys_grid_inParams_entities"
              [operationContextTypes]="$tabs_submitted_surveys_submitted_surveys_grid_inParams_operationContextTypes"
              [warehouseIds]="$tabs_submitted_surveys_submitted_surveys_grid_inParams_warehouseIds"
              ($refreshEvent)="refresh(false, false, '$tabs_submitted_surveys')">
              </FootPrintManager-submitted_surveys_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>