<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template toolbarToolDef="create_purchase_order" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_create_purchase_order_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
              <ng-template toolbarToolDef="create_asn_order" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_create_asn_order_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
              <ng-template toolbarToolDef="import_orders" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_import_orders_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-owner" *ngIf="!filters.owner.hidden" 
                                  class="field-container standard {{filters.owner.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.owner.styles.style"
                                  [ngClass]="filters.owner.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.owner.label + (filters.owner.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.owner.label}}<span *ngIf="filters.owner.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Owners-owners_dd_single 
                                  data-cy="selector"
                                  [type]="filters.owner.control.type"
                                  formControlName="owner"
                                  (displayTextChange)="filters.owner.control.displayText=$event"
                                  [placeholder]="filters.owner.control.placeholder"
                                  [styles]="filters.owner.control.styles"
                                  [tooltip]="filters.owner.control.tooltip"
                                [statusId]="$fields_owner_selector_inParams_statusId"
                                [projectId]="$fields_owner_selector_inParams_projectId"
                              >
                              </Owners-owners_dd_single>
                              <ng-container *ngIf="filters.owner.invalid">
                                <ng-container *ngFor="let error of filters.owner.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-project" *ngIf="!filters.project.hidden" 
                                  class="field-container standard {{filters.project.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.project.styles.style"
                                  [ngClass]="filters.project.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.project.label + (filters.project.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.project.label}}<span *ngIf="filters.project.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Owners-projects_dd_single 
                                  data-cy="selector"
                                  [type]="filters.project.control.type"
                                  formControlName="project"
                                  (displayTextChange)="filters.project.control.displayText=$event"
                                  [placeholder]="filters.project.control.placeholder"
                                  [styles]="filters.project.control.styles"
                                  [tooltip]="filters.project.control.tooltip"
                                [statusId]="$fields_project_selector_inParams_statusId"
                                [ownerId]="$fields_project_selector_inParams_ownerId"
                              >
                              </Owners-projects_dd_single>
                              <ng-container *ngIf="filters.project.invalid">
                                <ng-container *ngFor="let error of filters.project.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-materials" *ngIf="!filters.materials.hidden" 
                                  class="field-container standard {{filters.materials.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.materials.styles.style"
                                  [ngClass]="filters.materials.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.materials.label + (filters.materials.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.materials.label}}<span *ngIf="filters.materials.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Materials-materials_by_project_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.materials.control.type"
                                  formControlName="materials"
                                  (displayTextChange)="filters.materials.control.displayText=$event"
                                  [placeholder]="filters.materials.control.placeholder"
                                  [styles]="filters.materials.control.styles"
                                  [tooltip]="filters.materials.control.tooltip"
                                [projectId]="$fields_materials_selector_inParams_projectId"
                              >
                              </Materials-materials_by_project_dd_multi>
                              <ng-container *ngIf="filters.materials.invalid">
                                <ng-container *ngFor="let error of filters.materials.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-warehouse" *ngIf="!filters.warehouse.hidden" 
                                  class="field-container standard {{filters.warehouse.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.warehouse.styles.style"
                                  [ngClass]="filters.warehouse.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.warehouse.label + (filters.warehouse.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.warehouse.label}}<span *ngIf="filters.warehouse.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Locations-warehouses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.warehouse.control.type"
                                  formControlName="warehouse"
                                  (displayTextChange)="filters.warehouse.control.displayText=$event"
                                  [placeholder]="filters.warehouse.control.placeholder"
                                  [styles]="filters.warehouse.control.styles"
                                  [tooltip]="filters.warehouse.control.tooltip"
                              >
                              </Locations-warehouses_dd_multi>
                              <ng-container *ngIf="filters.warehouse.invalid">
                                <ng-container *ngFor="let error of filters.warehouse.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-status" *ngIf="!filters.status.hidden" 
                                  class="field-container standard {{filters.status.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.status.styles.style"
                                  [ngClass]="filters.status.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.status.label + (filters.status.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.status.label}}<span *ngIf="filters.status.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <PurchaseOrders-orderstatuses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.status.control.type"
                                  formControlName="status"
                                  (displayTextChange)="filters.status.control.displayText=$event"
                                  [placeholder]="filters.status.control.placeholder"
                                  [styles]="filters.status.control.styles"
                                  [tooltip]="filters.status.control.tooltip"
                                [statusIds]="$fields_status_selector_inParams_statusIds"
                              >
                              </PurchaseOrders-orderstatuses_dd_multi>
                              <ng-container *ngIf="filters.status.invalid">
                                <ng-container *ngFor="let error of filters.status.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-order_class" *ngIf="!filters.order_class.hidden" 
                                  class="field-container standard {{filters.order_class.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.order_class.styles.style"
                                  [ngClass]="filters.order_class.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.order_class.label + (filters.order_class.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.order_class.label}}<span *ngIf="filters.order_class.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <FootPrintManager-orderclasses_inbound_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.order_class.control.type"
                                  formControlName="order_class"
                                  (displayTextChange)="filters.order_class.control.displayText=$event"
                                  [placeholder]="filters.order_class.control.placeholder"
                                  [styles]="filters.order_class.control.styles"
                                  [tooltip]="filters.order_class.control.tooltip"
                              >
                              </FootPrintManager-orderclasses_inbound_dd_multi>
                              <ng-container *ngIf="filters.order_class.invalid">
                                <ng-container *ngFor="let error of filters.order_class.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-reference" *ngIf="!filters.reference.hidden" 
                                  class="field-container standard {{filters.reference.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.reference.styles.style"
                                  [ngClass]="filters.reference.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.reference.label + (filters.reference.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.reference.label}}<span *ngIf="filters.reference.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <input data-cy="textBox" formControlName="reference"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.reference.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.reference.control.placeholder}}"
                                      [ngStyle]="filters.reference.control.styles.style"
                                      [ngClass]="filters.reference.control.styles.classes"
                                      [matTooltip]="filters.reference.control.tooltip"
                                      matTooltipClass="datex-control-tooltip"> 
                              <ng-container *ngIf="filters.reference.invalid">
                                <ng-container *ngFor="let error of filters.reference.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.order_total_received_order_count_by_date_widget.hidden" class="widget-container">
            <PurchaseOrders-order_total_received_order_count_by_date_widget
            #$widgets_order_total_received_order_count_by_date_widget 
              [date]="$widgets_order_total_received_order_count_by_date_widget_inParams_date"
              [ownerId]="$widgets_order_total_received_order_count_by_date_widget_inParams_ownerId"
              [projectId]="$widgets_order_total_received_order_count_by_date_widget_inParams_projectId"
              [warehouseId]="$widgets_order_total_received_order_count_by_date_widget_inParams_warehouseId"
            ($refreshEvent)="refresh(false, false, '$widgets_order_total_received_order_count_by_date_widget')"
            >
            </PurchaseOrders-order_total_received_order_count_by_date_widget>
          </div>
          <div *ngIf="!widgets.order_total_received_units_by_date_widget.hidden" class="widget-container">
            <PurchaseOrders-order_total_received_units_by_date_widget
            #$widgets_order_total_received_units_by_date_widget 
              [date]="$widgets_order_total_received_units_by_date_widget_inParams_date"
              [ownerId]="$widgets_order_total_received_units_by_date_widget_inParams_ownerId"
              [projectId]="$widgets_order_total_received_units_by_date_widget_inParams_projectId"
              [warehouseId]="$widgets_order_total_received_units_by_date_widget_inParams_warehouseId"
            ($refreshEvent)="refresh(false, false, '$widgets_order_total_received_units_by_date_widget')"
            >
            </PurchaseOrders-order_total_received_units_by_date_widget>
          </div>
          <div *ngIf="!widgets.order_total_received_gross_by_date_widget.hidden" class="widget-container">
            <PurchaseOrders-order_total_received_gross_by_date_widget
            #$widgets_order_total_received_gross_by_date_widget 
              [date]="$widgets_order_total_received_gross_by_date_widget_inParams_date"
              [ownerId]="$widgets_order_total_received_gross_by_date_widget_inParams_ownerId"
              [projectId]="$widgets_order_total_received_gross_by_date_widget_inParams_projectId"
              [warehouseId]="$widgets_order_total_received_gross_by_date_widget_inParams_warehouseId"
            ($refreshEvent)="refresh(false, false, '$widgets_order_total_received_gross_by_date_widget')"
            >
            </PurchaseOrders-order_total_received_gross_by_date_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.inbound_orders.hidden" class="tab" data-cy="tab-inbound_orders">
              <h2 [className]="tabs.inbound_orders.active? 'active': ''" (click)="tabs.inbound_orders.activate()">{{tabs.inbound_orders.title}}</h2>
            </div>
            <div *ngIf="!tabs.calendar.hidden" class="tab" data-cy="tab-calendar">
              <h2 [className]="tabs.calendar.active? 'active': ''" (click)="tabs.calendar.activate()">{{tabs.calendar.title}}</h2>
            </div>
            <div *ngIf="!tabs.dock_appointment_dashboard.hidden" class="tab" data-cy="tab-dock_appointment_dashboard">
              <h2 [className]="tabs.dock_appointment_dashboard.active? 'active': ''" (click)="tabs.dock_appointment_dashboard.activate()">{{tabs.dock_appointment_dashboard.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-inbound_orders_grid *ngIf="tabs.inbound_orders.active"
              #$tabs_inbound_orders
              [ownerId]="$tabs_inbound_orders_inbound_orders_grid_inParams_ownerId"
              [projectId]="$tabs_inbound_orders_inbound_orders_grid_inParams_projectId"
              [warehouseId]="$tabs_inbound_orders_inbound_orders_grid_inParams_warehouseId"
              [statusIds]="$tabs_inbound_orders_inbound_orders_grid_inParams_statusIds"
              [orderClassIds]="$tabs_inbound_orders_inbound_orders_grid_inParams_orderClassIds"
              [reference]="$tabs_inbound_orders_inbound_orders_grid_inParams_reference"
              [materialIds]="$tabs_inbound_orders_inbound_orders_grid_inParams_materialIds"
              ($refreshEvent)="refresh(false, false, '$tabs_inbound_orders')">
              </FootPrintManager-inbound_orders_grid>
              <FootPrintManager-dock_appointments_calendar *ngIf="tabs.calendar.active"
              #$tabs_calendar
              [warehouseIds]="$tabs_calendar_dock_appointments_calendar_inParams_warehouseIds"
              [typeIds]="$tabs_calendar_dock_appointments_calendar_inParams_typeIds"
              [ownerId]="$tabs_calendar_dock_appointments_calendar_inParams_ownerId"
              [projectId]="$tabs_calendar_dock_appointments_calendar_inParams_projectId"
              [viewDate]="$tabs_calendar_dock_appointments_calendar_inParams_viewDate"
              (outParamsChange)="on_calendar_filters_updated($event)"
              ($refreshEvent)="refresh(false, false, '$tabs_calendar')">
              </FootPrintManager-dock_appointments_calendar>
              <FootPrintManager-dock_appointments_dashboard_grid *ngIf="tabs.dock_appointment_dashboard.active"
              #$tabs_dock_appointment_dashboard
              [ownerId]="$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_ownerId"
              [projectId]="$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_projectId"
              [warehouseIds]="$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_warehouseIds"
              [typeIds]="$tabs_dock_appointment_dashboard_dock_appointments_dashboard_grid_inParams_typeIds"
              ($refreshEvent)="refresh(false, false, '$tabs_dock_appointment_dashboard')">
              </FootPrintManager-dock_appointments_dashboard_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>