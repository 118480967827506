import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_ds_get_inventory_by_ownerService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { projectId?: number }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, LicensePlate?: { Archived?: boolean, LookupCode?: string, StatusId?: number, Location?: { Id?: number, Name?: string } }, Lot?: { Id?: number, Material?: { Id?: number, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } } } }, Packaged?: { Id?: number, Name?: string }, ds_inventory_by_licenseplate_total?: { TotalPackageAmount?: number, LicensePlateId?: number, PackagedId?: number, Lot?: { MaterialId?: number } } }[] }> 
  {
    let url = `${environment.backendUrl}api/app/datasources/ds_get_inventory_by_owner/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { projectId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, LicensePlate?: { Archived?: boolean, LookupCode?: string, StatusId?: number, Location?: { Id?: number, Name?: string } }, Lot?: { Id?: number, Material?: { Id?: number, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } } } }, Packaged?: { Id?: number, Name?: string }, ds_inventory_by_licenseplate_total?: { TotalPackageAmount?: number, LicensePlateId?: number, PackagedId?: number, Lot?: { MaterialId?: number } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/app/datasources/ds_get_inventory_by_owner/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { projectId?: number, $keys: { LicensePlateId?: number, LotId?: number, PackagedId?: number }[] }): 
  Promise<{ result: { LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, LicensePlate?: { Archived?: boolean, LookupCode?: string, StatusId?: number, Location?: { Id?: number, Name?: string } }, Lot?: { Id?: number, Material?: { Id?: number, LookupCode?: string, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string, Name?: string } } } }, Packaged?: { Id?: number, Name?: string }, ds_inventory_by_licenseplate_total?: { TotalPackageAmount?: number, LicensePlateId?: number, PackagedId?: number, Lot?: { MaterialId?: number } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/ds_get_inventory_by_owner/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      projectId?: number    }) {
      return false;
    }
}
