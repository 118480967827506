import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { EntityImport_ShellService } from './EntityImport.shell.service';
import { EntityImport_OperationService } from './EntityImport.operation.service';
import { EntityImport_DatasourceService } from './EntityImport.datasource.index';
import { EntityImport_FlowService } from './EntityImport.flow.index';
import { EntityImport_ReportService } from './EntityImport.report.index';
import { EntityImport_LocalizationService } from './EntityImport.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './EntityImport.frontend.types'
import { $frontendTypes as $types} from './EntityImport.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'EntityImport-entity_import_view',
  templateUrl: './EntityImport.entity_import_view.component.html'
})
export class EntityImport_entity_import_viewComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    request_id: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    view1: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      view: new ToolModel(new ButtonModel('view', new ButtonStyles(null, null), false, 'View', '', null)
    )
  };

  fields = {
    request_id: new FieldModel(new TextBoxModel(this.formGroup.controls['request_id'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Request id', true),
    view1: new FieldModel( new CodeBoxModel(this.formGroup.controls['view1'] as DatexFormControl, null, null,'json', null)
, new ControlContainerStyles(null, null), 'View1', false),
  };

  fieldsets = {
  view1: new FieldsetModel('View1', true, false, true),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    request_id: this.fields.request_id.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: EntityImport_ShellService,
private datasources: EntityImport_DatasourceService,
private flows: EntityImport_FlowService,
private reports: EntityImport_ReportService,
private localization: EntityImport_LocalizationService,
private operations: EntityImport_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'entity_import_view';
  
    const $form = this;
    const $utils = this.utils;

    


    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .request_id
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_view(event = null) {
    return this.on_viewInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_viewInternal(
    $form: EntityImport_entity_import_viewComponent,
  
    $shell: EntityImport_ShellService,
    $datasources: EntityImport_DatasourceService,
    $flows: EntityImport_FlowService,
    $reports: EntityImport_ReportService,
    $settings: SettingsValuesService,
    $operations: EntityImport_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: EntityImport_LocalizationService,
    $event: any
  ) {
    this.logger.log('EntityImport', 'entity_import_view.on_view');
  //O.Arias - 05/20/2024
  
  try {
  
      let parsed_error = (await $flows.EntityImport.entity_import_parse_errors({ request_id: $form.fields.request_id.control.value }));
  
      if ($utils.isDefined(parsed_error)) {
          $form.fields.view1.control.value = parsed_error
      }
  
  } catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError?.error)) { targetError = targetError.error; };
      if (typeof targetError === "string") { targetError = { "message": targetError }; };
      if (!$utils.isDefined(targetError?.message)) { targetError = { "message": `Uncaught exception! ${JSON.stringify(targetError)}` }; };
      $shell.EntityImport.openErrorDialog("Error", targetError.message)
  }
  }
  //#endregion private flows
}
