import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './UccLabels.frontend.types'

@Injectable({ providedIn: 'root' })
export class UccLabels_get_user_defined_field_namesService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { EntityTypes?: string[] }): Promise<{ UserDefinedFields?: { EntityType?: string, UserDefinedFields?: { Name?: string, Type?: string }[] }[], messages?: any[] }> 
  {
    let url = `${environment.backendUrl}api/UccLabels/functions/get_user_defined_field_names`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

