import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Locations_create_location_flowService } from './Locations.flow.index';
import { Locations_delete_location_project_flowService } from './Locations.flow.index';

import { $frontendTypes } from './Locations.frontend.types'

@Injectable({ providedIn: 'root' })
export class Locations_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Locations: Locations_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _create_location_flow: Locations_create_location_flowService;
  public async create_location_flow(inParams: { warehouseId: number, parentId?: number, statusId: number, typeId: number, name: string, shortName?: string, isLoose?: boolean, pickSequence?: number, allocationSequence?: number, putAwaySequence?: number, globalPickSequence?: number, globalAllocationSequence?: number, globalPutAwaySequence?: number, eligibleForAllocation?: boolean, weightCapacity?: number, weightUomId?: number, length?: number, width?: number, height?: number, dimensionUomId?: number, volumeUomId?: number, enabled?: boolean, volumeCapacity?: number, childFootprint?: number, childStackHeight?: number, manufacturingLineFlag?: boolean, mixingRuleId?: number, restrictToReplenishmentMaterials?: boolean, purgeAddedInventory?: boolean, isPrimaryPick?: boolean, allowInventoryIfNotActive?: boolean, skipCycleCount?: boolean, eligibleForManufacturingFeedback?: boolean, isManufacturingStagingLocation?: boolean, storageCategoryId?: number, allowMoveTaskCreation?: boolean, disableNonPreferredLocationAlertCreation?: boolean, scannableLookup?: string, skipValidationRules?: boolean, breakInventoryToBasePackaging?: boolean, confirmInventoryDeletion?: boolean, correlationId?: string, eligibleForScaleTicket?: boolean, skipAfterPickingCountBack?: boolean, countBackThreshold?: number, mobilePickingMaterialUPCIncrementScanningBypassTreshold?: number, mobileReplenishmentMaterialUPCIncrementScanningBypassTreshold?: number, autoSelectCountOnCountBack?: boolean, mobileForceUserCountBack?: boolean, isDynamicPrimaryPick?: boolean, isPackageVerification?: boolean, attachedPrinter?: string, isUsedForReturns?: boolean, allowOnlyCLPs?: boolean }): Promise< { reasons?: string[], newLocationId?: number, newLooseLicensePlateId?: number }> {
    if(!this._create_location_flow) {
      this._create_location_flow = this.injector.get(Locations_create_location_flowService);
    }
    return this._create_location_flow.run(inParams);
  }
   
   

   
 
  private _delete_location_project_flow: Locations_delete_location_project_flowService;
  public async delete_location_project_flow(inParams: { locationId: number, projectId: number }): Promise< { reason?: string }> {
    if(!this._delete_location_project_flow) {
      this._delete_location_project_flow = this.injector.get(Locations_delete_location_project_flowService);
    }
    return this._delete_location_project_flow.run(inParams);
  }
   
   

   
}
