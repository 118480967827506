<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
          <app-toolbar [toolbar]="toolbar">
              <ng-template toolbarToolDef="manage_work_classes" let-tool>
                <button mat-button
                        data-cy="button"
                        class="datex-button"
                        [disabled]="tool.control.readOnly"
                        [ngStyle]="tool.control.styles.style"
                        [ngClass]="tool.control.styles.classes"
                        [matTooltip]="tool.control.tooltip"
                        matTooltipClass="datex-control-tooltip"
                        (click)="on_manage_work_classes_clicked($event)"
                        >
                  <div class="button-label">
                    <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                      <div class="button-icon">
                        <i data-cy="button-icon" class="datex-default-spinner"></i>
                      </div>
                    </ng-container>
                    <ng-template #showIcon>
                      <div class="button-icon">
                        <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                      </div>
                    </ng-template>
                    <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                  </div>
                
                </button>
              </ng-template>
          </app-toolbar>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-filters"
                     *ngIf="!filtersets.filters.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.filters.collapsible }">
                    <div *ngIf="!filtersets.filters.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.filters.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{filtersets.filters.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.filters.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.filters.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.filters.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.filters.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-owner" *ngIf="!filters.owner.hidden" 
                                  class="field-container standard {{filters.owner.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.owner.styles.style"
                                  [ngClass]="filters.owner.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.owner.label + (filters.owner.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.owner.label}}<span *ngIf="filters.owner.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Owners-owners_dd_single 
                                  data-cy="selector"
                                  [type]="filters.owner.control.type"
                                  formControlName="owner"
                                  (displayTextChange)="filters.owner.control.displayText=$event"
                                  [placeholder]="filters.owner.control.placeholder"
                                  [styles]="filters.owner.control.styles"
                                  [tooltip]="filters.owner.control.tooltip"
                                [statusId]="$fields_owner_selector_inParams_statusId"
                                [projectId]="$fields_owner_selector_inParams_projectId"
                              >
                              </Owners-owners_dd_single>
                              <ng-container *ngIf="filters.owner.invalid">
                                <ng-container *ngFor="let error of filters.owner.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-project" *ngIf="!filters.project.hidden" 
                                  class="field-container standard {{filters.project.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.project.styles.style"
                                  [ngClass]="filters.project.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.project.label + (filters.project.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.project.label}}<span *ngIf="filters.project.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <Owners-projects_dd_single 
                                  data-cy="selector"
                                  [type]="filters.project.control.type"
                                  formControlName="project"
                                  (displayTextChange)="filters.project.control.displayText=$event"
                                  [placeholder]="filters.project.control.placeholder"
                                  [styles]="filters.project.control.styles"
                                  [tooltip]="filters.project.control.tooltip"
                                [statusId]="$fields_project_selector_inParams_statusId"
                                [ownerId]="$fields_project_selector_inParams_ownerId"
                              >
                              </Owners-projects_dd_single>
                              <ng-container *ngIf="filters.project.invalid">
                                <ng-container *ngFor="let error of filters.project.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-warehouses" *ngIf="!filters.warehouses.hidden" 
                                  class="field-container standard {{filters.warehouses.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.warehouses.styles.style"
                                  [ngClass]="filters.warehouses.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.warehouses.label + (filters.warehouses.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.warehouses.label}}<span *ngIf="filters.warehouses.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <LaborManagement-warehouses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.warehouses.control.type"
                                  formControlName="warehouses"
                                  (displayTextChange)="filters.warehouses.control.displayText=$event"
                                  [placeholder]="filters.warehouses.control.placeholder"
                                  [styles]="filters.warehouses.control.styles"
                                  [tooltip]="filters.warehouses.control.tooltip"
                              >
                              </LaborManagement-warehouses_dd_multi>
                              <ng-container *ngIf="filters.warehouses.invalid">
                                <ng-container *ngFor="let error of filters.warehouses.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-work_classes" *ngIf="!filters.work_classes.hidden" 
                                  class="field-container standard {{filters.work_classes.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.work_classes.styles.style"
                                  [ngClass]="filters.work_classes.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.work_classes.label + (filters.work_classes.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.work_classes.label}}<span *ngIf="filters.work_classes.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <LaborManagement-work_classes_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.work_classes.control.type"
                                  formControlName="work_classes"
                                  (displayTextChange)="filters.work_classes.control.displayText=$event"
                                  [placeholder]="filters.work_classes.control.placeholder"
                                  [styles]="filters.work_classes.control.styles"
                                  [tooltip]="filters.work_classes.control.tooltip"
                              >
                              </LaborManagement-work_classes_dd_multi>
                              <ng-container *ngIf="filters.work_classes.invalid">
                                <ng-container *ngFor="let error of filters.work_classes.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-from_date" *ngIf="!filters.from_date.hidden" 
                                  class="field-container standard {{filters.from_date.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.from_date.styles.style"
                                  [ngClass]="filters.from_date.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.from_date.label + (filters.from_date.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.from_date.label}}<span *ngIf="filters.from_date.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <app-datebox 
                                  data-cy="dateBox"
                                  formControlName="from_date"
                                  [format]="filters.from_date.control.format"
                                  [mode]="filters.from_date.control.mode"
                                  [ngStyle]="filters.from_date.control.styles.style"
                                  [ngClass]="filters.from_date.control.styles.classes"
                                  [tooltip]="filters.from_date.control.tooltip">
                              </app-datebox>
                              <ng-container *ngIf="filters.from_date.invalid">
                                <ng-container *ngFor="let error of filters.from_date.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-to_date" *ngIf="!filters.to_date.hidden" 
                                  class="field-container standard {{filters.to_date.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.to_date.styles.style"
                                  [ngClass]="filters.to_date.styles.classes">
                              <div class="label-container"
                                   [matTooltip]="filters.to_date.label + (filters.to_date.required ? ' (required)' : '')"
                                   matTooltipClass="datex-control-tooltip">
                                <label data-cy="field-label" class="datex-label">{{filters.to_date.label}}<span *ngIf="filters.to_date.required" class="required-asterisk">*</span>
                                </label>
                              </div>
                              <app-datebox 
                                  data-cy="dateBox"
                                  formControlName="to_date"
                                  [format]="filters.to_date.control.format"
                                  [mode]="filters.to_date.control.mode"
                                  [ngStyle]="filters.to_date.control.styles.style"
                                  [ngClass]="filters.to_date.control.styles.classes"
                                  [tooltip]="filters.to_date.control.tooltip">
                              </app-datebox>
                              <ng-container *ngIf="filters.to_date.invalid">
                                <ng-container *ngFor="let error of filters.to_date.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>
            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.task_assignment.hidden" class="tab" data-cy="tab-task_assignment">
              <h2 [className]="tabs.task_assignment.active? 'active': ''" (click)="tabs.task_assignment.activate()">{{tabs.task_assignment.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-task_assignment_grid *ngIf="tabs.task_assignment.active"
              #$tabs_task_assignment
              [warehouseIds]="$tabs_task_assignment_task_assignment_grid_inParams_warehouseIds"
              [workClassIds]="$tabs_task_assignment_task_assignment_grid_inParams_workClassIds"
              [fromDate]="$tabs_task_assignment_task_assignment_grid_inParams_fromDate"
              [toDate]="$tabs_task_assignment_task_assignment_grid_inParams_toDate"
              [projectId]="$tabs_task_assignment_task_assignment_grid_inParams_projectId"
              [ownerId]="$tabs_task_assignment_task_assignment_grid_inParams_ownerId"
              ($refreshEvent)="refresh(false, false, '$tabs_task_assignment')">
              </FootPrintManager-task_assignment_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>