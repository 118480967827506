import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class SalesOrders_ds_get_orderlines_by_orderId_linestatusidsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number, line_status_ids: number[], order_status_ids: number[] }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, Amount?: number, MaterialId?: number, PackagedAmount?: number, StatusId?: number }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.line_status_ids)) {
      missingRequiredInParams.push('\'line_status_ids\'');
    }
    if (isNil(inParams.order_status_ids)) {
      missingRequiredInParams.push('\'order_status_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_get_orderlines_by_orderId_linestatusids/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, line_status_ids: number[], order_status_ids: number[], $top?: number, $skip?: number }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, Amount?: number, MaterialId?: number, PackagedAmount?: number, StatusId?: number }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.line_status_ids)) {
      missingRequiredInParams.push('\'line_status_ids\'');
    }
    if (isNil(inParams.order_status_ids)) {
      missingRequiredInParams.push('\'order_status_ids\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_get_orderlines_by_orderId_linestatusids/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, line_status_ids: number[], order_status_ids: number[], $keys: { OrderId?: number, LineNumber?: number }[] }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, Amount?: number, MaterialId?: number, PackagedAmount?: number, StatusId?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.line_status_ids)) {
      missingRequiredInParams.push('\'line_status_ids\'');
    }
    if (isNil(inParams.order_status_ids)) {
      missingRequiredInParams.push('\'order_status_ids\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SalesOrders/datasources/ds_get_orderlines_by_orderId_linestatusids/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      orderId: number, line_status_ids: number[], order_status_ids: number[]    }) {
        if(isNil(inParams.orderId)) {
          return true; 
        }
        if(isNil(inParams.line_status_ids)) {
          return true; 
        }
        if(isNil(inParams.order_status_ids)) {
          return true; 
        }
      return false;
    }
}
