import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_reason_code_parent_entities_dd_singleComponent } from './FootPrintManager.reason_code_parent_entities_dd_single.component'
import { FootPrintManager_reason_code_parent_entity_ids_dd_singleComponent } from './FootPrintManager.reason_code_parent_entity_ids_dd_single.component'

type EntityType = { 
    Id?: number, EnumName?: string, Label?: string, LookupCode?: string, Name?: string, NumberOfMinutesBeforeExpiration?: number, ParentEntity?: string, ParentId?: number}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_reason_code_parent_entities_dd_singleComponent),
    forwardRef(() => FootPrintManager_reason_code_parent_entity_ids_dd_singleComponent),
  ],
  selector: 'FootPrintManager-reason_code_editor',
  templateUrl: './FootPrintManager.reason_code_editor.component.html'
})
export class FootPrintManager_reason_code_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { reasonCodeId: number } = { reasonCodeId: null };
  //#region Inputs
  @Input('reasonCodeId') set $inParams_reasonCodeId(v: number) {
    this.inParams.reasonCodeId = v;
  }
  get $inParams_reasonCodeId(): number {
    return this.inParams.reasonCodeId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    reason_entity: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    reason_type: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    reason_code: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    displayed_label: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    inventory_status_in_minutes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    ),
      cancel_button: new ToolModel(new ButtonModel('cancel_button', new ButtonStyles(null, null), false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    )
  };

  fields = {
    reason_entity: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['reason_entity'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Reason entity', true),
    reason_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['reason_type'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Reason type', true),
    reason_code: new FieldModel(new TextBoxModel(this.formGroup.controls['reason_code'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Reason code', true),
    displayed_label: new FieldModel(new TextBoxModel(this.formGroup.controls['displayed_label'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Displayed label', false),
    inventory_status_in_minutes: new FieldModel(new NumberBoxModel(this.formGroup.controls['inventory_status_in_minutes'] as DatexFormControl, null, false, '', '', null)
, new ControlContainerStyles(null, null), 'Reset inventory status in minutes', false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Label of newGroup1', true, false, true),
};


  //#region fields inParams
  get $fields_reason_type_selector_inParams_parent(): string {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.fields.reason_entity.control.value;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    reason_entity: this.fields.reason_entity.control.valueChanges
    ,
    reason_type: this.fields.reason_type.control.valueChanges
    ,
    reason_code: this.fields.reason_code.control.valueChanges
    ,
    displayed_label: this.fields.displayed_label.control.valueChanges
    ,
    inventory_status_in_minutes: this.fields.inventory_status_in_minutes.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.reasonCodeId)) {
        this.$missingRequiredInParams.push('reasonCodeId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'reason_code_editor';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      reasonCodeId:  $editor.inParams.reasonCodeId 
    };

    const data = await this.datasources.FootPrintManager.ds_get_reason_code_by_id.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    private async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.reason_entity.control as SelectBoxModel).reset($editor.entity.ParentEntity);
    (this.fields.reason_code.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.displayed_label.control as TextBoxModel).reset($editor.entity.Label);
    (this.fields.inventory_status_in_minutes.control as NumberBoxModel).reset($editor.entity.NumberOfMinutesBeforeExpiration);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .reason_entity
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .reason_type
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .reason_code
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .displayed_label
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .inventory_status_in_minutes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_reason_code_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.title = 'Edit reason code';
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $editor: FootPrintManager_reason_code_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.outParams.confirm = false;
  let reasonCodeId = $editor.entity.Id;
  // Check for  duplicates
  const reasoncodes = (await $datasources.Inventory.ds_get_reasoncodes_by_lookupcode_parentId_parentEntityId.get({
      lookupcode: $editor.fields.reason_code.control.value,
      parentId: $editor.fields.reason_type.control.value,
      parentEntity: $editor.fields.reason_entity.control.value
  })).result;
  if ($utils.isDefined(reasoncodes)) {
  
      throw new Error('Reason code already exists.')
  }
  else {
      if ($editor.fields.displayed_label.control.isChanged ||
          $editor.fields.inventory_status_in_minutes.control.isChanged ||
          $editor.fields.reason_code.control.isChanged ||
          $editor.fields.reason_entity.control.isChanged ||
          $editor.fields.reason_type.control.isChanged
      ) {
          try {
  
              let payload = {
                  LookupCode: $editor.fields.reason_code.control.value,
                  Name: $editor.fields.reason_code.control.value,
                  ParentEntity: $editor.fields.reason_entity.control.value,
                  ParentId: $editor.fields.reason_type.control.value,
                  Label: $editor.fields.displayed_label.control.value,
                  EnumName: $editor.fields.reason_code.control.value,
                  NumberOfMinutesBeforeExpiration: $editor.fields.inventory_status_in_minutes.control.value,
                  SystemDefined: false
              };
  
              let results: {
                  success?: boolean;
                  message?: string;
                  id?: number;
              };
  
              let action = "";
  
              {
                  //Update
                  if ($utils.isDefined(payload)) {
                      results = (await $flows.FootPrintManager.reason_codes_create_update_delete_flow({ payload: payload, id: reasonCodeId, action: "Update" }));
                      action = "updating";
                  };
              };
  
              if (action !== "") {
                  if (!$utils.isDefined(results)) {
                      throw new Error("No results were returned when firing the [reason_codes_create_update_delete_flow] flow!");
                  } else {
                      if (!results.success) {
                          throw new Error(`${results.message} - ${JSON.stringify(payload)}`
                          );
                      } else {
                          $editor.outParams.confirm = true;
                          $editor.close();
                      };
                  };
              };
          } catch (error) {
  
              $shell.FootPrintManager.showErrorDetails('Failure to save', `Error saving reason code ${$editor.fields.reason_code.control.value}.`, error);
              throw error; // to prevent displayMode 
          }
      }
      else {
          $editor.close();
      }
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_reason_code_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.close();
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_reason_code_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let options: { parent: string, options: { Id: number, Name: string }[] }[];
  options = [];
  
  const invoice = (await $datasources.FootPrintManager.ds_reason_code_parent_entities_dd.getList({ parent: "InvoiceType" })).result;
  
  options = options.concat({
      parent: "InvoiceType",
      options: invoice.map(i => {
          return {
              Id: i.Id,
              Name: i.Name
          }
      })
  });
  
  const load = (await $datasources.FootPrintManager.ds_reason_code_parent_entities_dd.getList({ parent: "LoadContainer" })).result;
  
  options = options.concat({
      parent: "LoadContainer",
      options: load.map(i => {
          return {
              Id: i.Id,
              Name: i.Name
          }
      })
  });
  
  const operation = (await $datasources.FootPrintManager.ds_reason_code_parent_entities_dd.getList({ parent: "OperationType" })).result;
  
  options = options.concat({
      parent: "OperationType",
      options: operation.map(i => {
          return {
              Id: i.Id,
              Name: i.Name
          }
      })
  });
  
  let parent_id = $editor.entity.ParentId;
  let parent_type = $editor.entity.ParentEntity;
  
  let filtered_options = options.filter(o => o.parent === parent_type);
  
  if ($utils.isDefined(parent_id) && $utils.isDefined(parent_type)) {
  
      if ($utils.isDefined(filtered_options)) {
          if (filtered_options.length > 0) {
  
              let options_for_type = filtered_options[0].options;
  
              if ($utils.isDefined(options_for_type)) {
                  if (options_for_type.length > 0) {
  
                      options_for_type = options_for_type.filter(o => o.Id === parent_id);
  
                      if (options_for_type.length > 0) {
                          $editor.fields.reason_type.control.value = parent_id;
                          $editor.fields.reason_type.control.displayText = options_for_type[0].Name;
                      };
                  };
              };
          };
      };
  };
  }
  //#endregion private flows
  
}
