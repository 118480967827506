import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IInventory_inventory_by_project_reportServiceInParams {
  projectId: number}

interface IInventory_inventory_by_project_reportServiceData {
  Inventory?: { result?: { LicensePlateId?: number, LotId?: number, PackagedId?: number, MaterialId?: number, TotalPackagedAmount?: number, LicensePlate?: { GrossWeight?: number, LookupCode?: string, StatusId?: number, WeightUomId?: number }, LicensePlateStatus?: { Name?: string }, Location?: { Name?: string }, Warehouse?: { Name?: string }, Packaging?: { ShortName?: string, WeightUomId?: number, WeightUom?: { Short_name?: string } }, Material?: { Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } }, Lot?: { LookupCode?: string } }[] }
}

@Injectable({ providedIn: 'root' })
export class Inventory_inventory_by_project_reportService extends ReportBaseService<IInventory_inventory_by_project_reportServiceInParams, IInventory_inventory_by_project_reportServiceData> {

  protected reportReferenceName = 'custom_inventory_by_project_by_lot_lp_report';
  protected appReferenceName = 'Reports';

  constructor(
    utils: UtilsService,
    private datasources: Reports_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IInventory_inventory_by_project_reportServiceInParams) {
  }

  protected async getData(inParams: IInventory_inventory_by_project_reportServiceInParams): Promise<IInventory_inventory_by_project_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IInventory_inventory_by_project_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IInventory_inventory_by_project_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        projectId:  $report.inParams.projectId 
      };
      
      const dsData = await this.datasources.Reports.custom_ds_inventory_by_project_report.get(dsParams);
      
      data.Inventory = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
