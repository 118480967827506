import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Invoices_ShellService } from './Invoices.shell.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Invoices.frontend.types'
import { $frontendTypes as $types} from './Invoices.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Invoices-invoicing_rules_configuration_form',
  templateUrl: './Invoices.invoicing_rules_configuration_form.component.html'
})
export class Invoices_invoicing_rules_configuration_formComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { is_confirmed?: boolean } = { is_confirmed: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { entity?: { allow_duplicate_strategies?: { value?: string, id?: string, name?: string }, max_task_age_in_days?: { value?: string, id?: string, name?: string }, max_invoice_age_in_days?: { value?: string, id?: string, name?: string }, log_level?: { value?: string, id?: string, name?: string }, projects_inherit_template_rules?: { value?: string, id?: string, name?: string } } } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ is_confirmed?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    allow_duplicate_strategies: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    projects_inherit_template_rules: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    max_billing_task_age_in_days: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    max_invoice_age_in_days: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    log_level: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Update', '', null)
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, 'Cancel', '', null)
    )
  };

  fields = {
    allow_duplicate_strategies: new FieldModel(new CheckBoxModel(this.formGroup.controls['allow_duplicate_strategies'] as DatexFormControl, null, false, 'Allow duplicate strategies', null)
, new ControlContainerStyles(null, null), '', false),
    projects_inherit_template_rules: new FieldModel(new CheckBoxModel(this.formGroup.controls['projects_inherit_template_rules'] as DatexFormControl, null, false, 'Projects inherit template rules', null)
, new ControlContainerStyles(null, null), '', false),
    max_billing_task_age_in_days: new FieldModel(new NumberBoxModel(this.formGroup.controls['max_billing_task_age_in_days'] as DatexFormControl, null, false, '', '', null)
, new ControlContainerStyles(null, null), 'Do not invoice billing records older than (days)', false),
    max_invoice_age_in_days: new FieldModel(new NumberBoxModel(this.formGroup.controls['max_invoice_age_in_days'] as DatexFormControl, null, false, '', '', null)
, new ControlContainerStyles(null, null), 'Do not add records to existing invoices older than (days)', false),
    log_level: new FieldModel(new NumberBoxModel(this.formGroup.controls['log_level'] as DatexFormControl, null, false, '', '', null)
, new ControlContainerStyles(null, null), 'Logging level', false),
  };

  fieldsets = {
  header: new FieldsetModel('Header', true, false, true),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    allow_duplicate_strategies: this.fields.allow_duplicate_strategies.control.valueChanges
    ,
    projects_inherit_template_rules: this.fields.projects_inherit_template_rules.control.valueChanges
    ,
    max_billing_task_age_in_days: this.fields.max_billing_task_age_in_days.control.valueChanges
    ,
    max_invoice_age_in_days: this.fields.max_invoice_age_in_days.control.valueChanges
    ,
    log_level: this.fields.log_level.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Invoices_ShellService,
private datasources: Invoices_DatasourceService,
private flows: Invoices_FlowService,
private reports: Invoices_ReportService,
private localization: Invoices_LocalizationService,
private operations: Invoices_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Auto-invoicing configuration';
  
    const $form = this;
    const $utils = this.utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .allow_duplicate_strategies
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .projects_inherit_template_rules
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .max_billing_task_age_in_days
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .max_invoice_age_in_days
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .log_level
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: Invoices_invoicing_rules_configuration_formComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  $form.outParams.is_confirmed = false;
  
  await get_entity();
  
  $form.fields.allow_duplicate_strategies.control.value = parse_boolean($form.vars.entity.allow_duplicate_strategies.value);
  $form.fields.log_level.control.value = parse_numeric($form.vars.entity.log_level.value);
  $form.fields.max_billing_task_age_in_days.control.value = parse_numeric($form.vars.entity.max_task_age_in_days.value);
  $form.fields.max_invoice_age_in_days.control.value = parse_numeric($form.vars.entity.max_invoice_age_in_days.value);
  $form.fields.projects_inherit_template_rules.control.value = parse_boolean($form.vars.entity.projects_inherit_template_rules.value);
  
  
  
  /**********************************
   FUNCTIONS
  ***********************************/
  async function get_entity() {
      const options = (await $datasources.Invoices.ds_invoicing_options.getList({})).result;
      $form.vars.entity = {
          allow_duplicate_strategies: {},
          log_level: {},
          max_invoice_age_in_days: {},
          max_task_age_in_days: {},
          projects_inherit_template_rules: {},
      };
  
      // Allow duplicate strategies
      const allowDuplicateStrategies = options.find(o => o.dimension?.trim().toUpperCase() === 'ALLOWDUPLICATESTRATEGIES');
      
      if ($utils.isDefined(allowDuplicateStrategies)) {
          $form.vars.entity.allow_duplicate_strategies.id = allowDuplicateStrategies.id;
          $form.vars.entity.allow_duplicate_strategies.value = allowDuplicateStrategies.value;
          $form.vars.entity.allow_duplicate_strategies.name = allowDuplicateStrategies.dimension;
      }
  
      // Log level
      const logLevel = options.find(o => o.dimension?.trim().toUpperCase() === 'LOGLEVEL');
      if ($utils.isDefined(logLevel)) {
          $form.vars.entity.log_level.id = logLevel.id;
          $form.vars.entity.log_level.value = logLevel.value;
          $form.vars.entity.log_level.name = logLevel.dimension;
      }
  
      // maxBillingTaskAgeInDays
      const maxBillingTaskAgeInDays = options.find(o => o.dimension?.trim().toUpperCase() === 'MAXBILLINGTASKAGEINDAYS');
      if ($utils.isDefined(maxBillingTaskAgeInDays)) {
          $form.vars.entity.max_task_age_in_days.id = maxBillingTaskAgeInDays.id;
          $form.vars.entity.max_task_age_in_days.value = maxBillingTaskAgeInDays.value;
          $form.vars.entity.max_task_age_in_days.name = maxBillingTaskAgeInDays.dimension;
      }
  
      // maxInvoiceAgeInDays
      const maxInvoiceAgeInDays = options.find(o => o.dimension?.trim().toUpperCase() === 'MAXINVOICEAGEINDAYS');
      if ($utils.isDefined(maxInvoiceAgeInDays)) {
          $form.vars.entity.max_invoice_age_in_days.id = maxInvoiceAgeInDays.id;
          $form.vars.entity.max_invoice_age_in_days.value = maxInvoiceAgeInDays.value;
          $form.vars.entity.max_invoice_age_in_days.name = maxInvoiceAgeInDays.dimension;
      }
  
      // projectsInheritTemplateRules
      const projectsInheritTemplateRules = options.find(o => o.dimension?.trim().toUpperCase() === 'PROJECTSINHERITTEMPLATERULES');
      if ($utils.isDefined(projectsInheritTemplateRules)) {
          $form.vars.entity.projects_inherit_template_rules.id = projectsInheritTemplateRules.id;
          $form.vars.entity.projects_inherit_template_rules.value = projectsInheritTemplateRules.value;
          $form.vars.entity.projects_inherit_template_rules.name = projectsInheritTemplateRules.dimension;
      }
  }
  function parse_numeric(value: string) {
      if (!$utils.isDefinedTrimmed(value)) { return null; }
  
      if (isNaN(Number(value))) { return null; }
  
      return Number(value);
  }
  
  function parse_boolean(value: string) {
      if (!$utils.isDefinedTrimmed(value)) { return null; }
  
      const truthy = ['true', '1'];
      const falsey = ['false', '0'];
  
      const formattedValue = value.trim().toLowerCase();
  
      if (truthy.includes(formattedValue)) { return true; }
  
      if (falsey.includes(formattedValue)) { return false; }
  
      return null;
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: Invoices_invoicing_rules_configuration_formComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  $form.close();
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: Invoices_invoicing_rules_configuration_formComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  if ($form.fields.allow_duplicate_strategies.control.isChanged) {
      update_config($form.vars.entity.allow_duplicate_strategies.id, $form.vars.entity.allow_duplicate_strategies.name, $form.fields.allow_duplicate_strategies.control.value);
  }
  
  if ($form.fields.log_level.control.isChanged) {
      update_config($form.vars.entity.log_level.id, $form.vars.entity.log_level.name, $form.fields.log_level.control.value);
  }
  
  if ($form.fields.max_billing_task_age_in_days.control.isChanged) {
      update_config($form.vars.entity.max_task_age_in_days.id, $form.vars.entity.max_task_age_in_days.name, $form.fields.max_billing_task_age_in_days.control.value);
  }
  
  if ($form.fields.max_invoice_age_in_days.control.isChanged) {
      update_config($form.vars.entity.max_invoice_age_in_days.id, $form.vars.entity.max_invoice_age_in_days.name, $form.fields.max_invoice_age_in_days.control.value);
  }
  
  if ($form.fields.projects_inherit_template_rules.control.isChanged) {
      update_config($form.vars.entity.projects_inherit_template_rules.id, $form.vars.entity.projects_inherit_template_rules.name, $form.fields.projects_inherit_template_rules.control.value);
  }
  
  $form.close();
  
  
  
  /******************************
   * FUNCTIONS
  *******************************/
  async function update_config(config_id: string, config_name: string, config_value: string) {
      $form.outParams.is_confirmed = true;
      
      const result = await $flows.Invoices.invoicing_option({
          action: "Update",
          payload: {
              id: config_id,
              dimension: config_name,
              value: config_value.toString()
          }
      });
  
      if (!result.success) { throw new Error(result.error.map(e => typeof e === 'string' ? e : JSON.stringify(e)).join(', ')); }
  }
  }
  //#endregion private flows
}
