import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { CleanupLoggerService } from './cleanup.logging.service';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { UccLabels_DatasourceService } from './UccLabels.datasource.index';

interface IUccLabels_ucc_label_walmartServiceInParams {
  shipment_id?: number, order_id?: number, shipping_container_id?: number, copies?: number}

interface IUccLabels_ucc_label_walmartServiceData {
  UCC?: { result?: { Labels?: { ShipmentId?: number, ShippingContainerId?: number, ShippingContainerLookupCode?: string, ShippingContainerLookupCodeFormatted?: string, OrderLookupCode?: string, OrderOwnerReference?: string, OrderVendorReference?: string, OrderNotes?: string, RequestedDeliverDate?: string, FulfillmentDate?: string, CancelThresholdDate?: string, ShipmentLookupcode?: string, BillOfLading?: string, WaveId?: string, CarrierName?: string, CarrierScacCode?: string, OwnerLookupCode?: string, OwnerName?: string, ProjectLookupCode?: string, ProjectName?: string, LicensePlateLookupCode?: string, PackagedAmount?: number, GrossWeight?: number, NetWeight?: number, WeightUom?: string, MaterialLookupCode?: string, MaterialDescription?: string, MaterialUpcCode?: string, ContainerDetails?: string, ContainerType?: string, SerialCounter?: string, ShipFromContactName?: string, ShipFromContactLookup?: string, ShipFromAddressLine1?: string, ShipFromAddressLine2?: string, ShipFromAddressCity?: string, ShipFromAddressState?: string, ShipFromAddressZip?: string, ShipToAccountName?: string, ShipToAccountLookup?: string, ShipToContactName?: string, ShipToContactLookup?: string, ShipToAddressLine1?: string, ShipToAddressLine2?: string, ShipToAddressCity?: string, ShipToAddressState?: string, ShipToAddressZip?: string, BillToAccountName?: string, BillToAccountLookup?: string, BillToContactName?: string, BillToContactLookup?: string, BillToAddressLine1?: string, BillToAddressLine2?: string, BillToAddressCity?: string, BillToAddressState?: string, BillToAddressZip?: string, LotManufactureDate?: string, LotExpirationDate?: string, LotLookupCode?: string, CountryOfOrigin?: string, NetVolume?: number, GrossVolume?: number, VolumeUom?: string, WarehouseName?: string, DepartmentNumber?: string, TypeCode?: string, MaterialAlias?: string }[] } }
}

@Injectable({ providedIn: 'root' })
export class UccLabels_ucc_label_walmartService extends ReportBaseService<IUccLabels_ucc_label_walmartServiceInParams, IUccLabels_ucc_label_walmartServiceData> {

  protected reportReferenceName = 'ucc_label_walmart';
  protected appReferenceName = 'UccLabels';

  constructor(
    utils: UtilsService,
    private datasources: UccLabels_DatasourceService,
    private logger: CleanupLoggerService
    ) {
      super(utils);
      logger.log('UccLabels', 'ucc_label_walmart');
  }

  override throwIfMissingRequiredInParams (inParams: IUccLabels_ucc_label_walmartServiceInParams) {
  }

  protected async getData(inParams: IUccLabels_ucc_label_walmartServiceInParams): Promise<IUccLabels_ucc_label_walmartServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IUccLabels_ucc_label_walmartServiceInParams } = {
      inParams: inParams
    };

    const data: IUccLabels_ucc_label_walmartServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        shipment_id:  $report.inParams.shipment_id ,
        order_id:  $report.inParams.order_id ,
        shipping_container_id:  $report.inParams.shipping_container_id ,
        copies:  $report.inParams.copies 
      };
      
      const dsData = await this.datasources.UccLabels.ds_ucc_labels_report.get(dsParams);
      
      data.UCC = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
