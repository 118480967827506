import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { SalesOrders_outbound_bill_of_lading_reportComponent } from './SalesOrders.outbound_bill_of_lading_report.component';
import { PurchaseOrders_receiving_reportComponent } from './PurchaseOrders.receiving_report.component';
import { DockAppointments_wait_time_widgetComponent } from './DockAppointments.wait_time_widget.component';
import { SalesOrders_order_picked_percentage_widgetComponent } from './SalesOrders.order_picked_percentage_widget.component';
import { SalesOrders_order_loaded_percentage_widgetComponent } from './SalesOrders.order_loaded_percentage_widget.component';
import { SalesOrders_order_total_pallet_count_widgetComponent } from './SalesOrders.order_total_pallet_count_widget.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => SalesOrders_outbound_bill_of_lading_reportComponent),
    forwardRef(() => PurchaseOrders_receiving_reportComponent),
    forwardRef(() => DockAppointments_wait_time_widgetComponent),
    forwardRef(() => SalesOrders_order_picked_percentage_widgetComponent),
    forwardRef(() => SalesOrders_order_loaded_percentage_widgetComponent),
    forwardRef(() => SalesOrders_order_total_pallet_count_widgetComponent),
  ],
  selector: 'FootPrintManager-driver_check_out_hub',
  templateUrl: './FootPrintManager.driver_check_out_hub.component.html'
})
export class FootPrintManager_driver_check_out_hubComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { orderId?: number } = { orderId: null };
  //#region Inputs
  @Input('orderId') set $inParams_orderId(v: number) {
    this.inParams.orderId = v;
  }
  get $inParams_orderId(): number {
    return this.inParams.orderId;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { title?: string, description?: string, order?: string, waitTime?: number, dockAppointmentId?: number, signatureExists?: boolean, statusId?: number } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    search: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    signature_capture: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      check_out: new ToolModel(new ButtonModel('check_out', new ButtonStyles(['primary'], null), false, 'Check out', 'icon-ic_fluent_sign_out_20_regular', null)
    ),
      save_signature: new ToolModel(new ButtonModel('save_signature', new ButtonStyles(['primary'], null), false, 'Save signature', 'icon-ic_fluent_save_20_regular', null)
    ),
      refresh: new ToolModel(new ButtonModel('refresh', new ButtonStyles(null, null), false, 'Refresh', 'icon-ic_fluent_arrow_clockwise_20_regular', null)
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      appointment: new ToolModel(new ButtonModel('appointment', new ButtonStyles(null, null), false, 'Appointment', 'icon-ic_fluent_calendar_ltr_20_regular', null)
    )
  };

  actionbar = {
  };

 filters = {
    search: new FieldModel(new TextBoxModel(this.formGroup.controls['search'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Search', false),
    appointment_details: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Appointment details', false),
    driver_details: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Driver details', false),
    signature_capture: new FieldModel( new DrawModel(this.formGroup.controls['signature_capture'] as DatexFormControl, null, null,
  {
  canvasWidth: 540,
  canvasHeight: 100,
  backgroundColor: '#ffff'
  },
  null)
, new ControlContainerStyles(null, null), 'Signature', false),
  };


  filtersets = {
  newGroup1: new FieldsetModel('', false, false, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      bill_of_lading: new TabItemModel(
        this.rootTabGroup, 
        'Bill of lading', 
        ),
      receiving_report: new TabItemModel(
        this.rootTabGroup, 
        'Receiving report ', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_bill_of_lading_outbound_bill_of_lading_report_inParams_orderId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.orderId;
      
      return expr;
    }
  
    get $tabs_receiving_report_receiving_report_inParams_orderId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.orderId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_bill_of_lading', { read: SalesOrders_outbound_bill_of_lading_reportComponent }) $tabs_bill_of_lading: SalesOrders_outbound_bill_of_lading_reportComponent;
      @ViewChild('$tabs_receiving_report', { read: PurchaseOrders_receiving_reportComponent }) $tabs_receiving_report: PurchaseOrders_receiving_reportComponent;
    //#endregion tabs children
    widgets = {
      wait_time: new WidgetModel(),
      order_picked_percentage_widget: new WidgetModel(),
      order_loaded_percentage_widget: new WidgetModel(),
      order_total_pallet_count_widget: new WidgetModel(),
    };
  
    //#region widgets inParams
    get $widgets_wait_time_inParams_waitTime(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.vars.waitTime;
      
      return expr;
    }
  
    get $widgets_order_picked_percentage_widget_inParams_orderId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.orderId;
      
      return expr;
    }
  
    get $widgets_order_loaded_percentage_widget_inParams_orderId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.orderId;
      
      return expr;
    }
  
    get $widgets_order_total_pallet_count_widget_inParams_orderId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.orderId;
      
      return expr;
    }
  
    //#endregion widgets inParams
  
    //#region widgets children
      @ViewChild('$widgets_wait_time', { read:  DockAppointments_wait_time_widgetComponent }) $widgets_wait_time: DockAppointments_wait_time_widgetComponent;
      @ViewChild('$widgets_order_picked_percentage_widget', { read:  SalesOrders_order_picked_percentage_widgetComponent }) $widgets_order_picked_percentage_widget: SalesOrders_order_picked_percentage_widgetComponent;
      @ViewChild('$widgets_order_loaded_percentage_widget', { read:  SalesOrders_order_loaded_percentage_widgetComponent }) $widgets_order_loaded_percentage_widget: SalesOrders_order_loaded_percentage_widgetComponent;
      @ViewChild('$widgets_order_total_pallet_count_widget', { read:  SalesOrders_order_total_pallet_count_widgetComponent }) $widgets_order_total_pallet_count_widget: SalesOrders_order_total_pallet_count_widgetComponent;
    //#endregion widgets children

  //#region filters inParams
  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.title;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.description;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.bill_of_lading,
      this.tabs.receiving_report,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Driver check out hub';
    
    const $hub = this;
    const $utils = this.utils;

    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['search']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_search();
      });
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region widgets children
    if (childToSkip !== '$widgets_wait_time') {
      if (!isNil(this.$widgets_wait_time) && !this.widgets.wait_time.hidden) {
        this.$widgets_wait_time.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_order_picked_percentage_widget') {
      if (!isNil(this.$widgets_order_picked_percentage_widget) && !this.widgets.order_picked_percentage_widget.hidden) {
        this.$widgets_order_picked_percentage_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_order_loaded_percentage_widget') {
      if (!isNil(this.$widgets_order_loaded_percentage_widget) && !this.widgets.order_loaded_percentage_widget.hidden) {
        this.$widgets_order_loaded_percentage_widget.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$widgets_order_total_pallet_count_widget') {
      if (!isNil(this.$widgets_order_total_pallet_count_widget) && !this.widgets.order_total_pallet_count_widget.hidden) {
        this.$widgets_order_total_pallet_count_widget.refresh(true, false, null);
      }
    }
    //#endregion widgets children
    //#region tabs children
    if (childToSkip !== '$tabs_bill_of_lading') {
      if (!isNil(this.$tabs_bill_of_lading) && !this.tabs.bill_of_lading.hidden) {
        this.$tabs_bill_of_lading.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_receiving_report') {
      if (!isNil(this.$tabs_receiving_report) && !this.tabs.receiving_report.hidden) {
        this.$tabs_receiving_report.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_driver_check_out_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Initialize hub
  const orderId = $hub.inParams.orderId;
  $hub.filters.appointment_details.hidden = true;
  $hub.filters.driver_details.hidden = true;
  $hub.toolbar.appointment.hidden = true;
  $hub.filters.search.hidden = true;
  $hub.toolbar.save_signature.hidden = true;
  $hub.vars.signatureExists = false;
  $hub.vars.statusId = 1;
  
  if (!$utils.isDefined(orderId)) {
  
      $hub.toolbar.appointment.hidden = true;
      $hub.toolbar.check_out.hidden = true;
      $hub.toolbar.refresh.hidden = true;
      $hub.toolbar.separator1.hidden = true;
  
      $hub.widgets.order_loaded_percentage_widget.hidden = true;
      $hub.widgets.order_picked_percentage_widget.hidden = true;
      $hub.widgets.order_total_pallet_count_widget.hidden = true;
      $hub.widgets.wait_time.hidden = true;
  
      $hub.filters.signature_capture.hidden = true;
      $hub.filters.appointment_details.hidden = true;
      $hub.filters.driver_details.hidden = true;
  
      $hub.tabs.receiving_report.hidden = true;
      $hub.tabs.bill_of_lading.hidden = true;
  
      $hub.filters.search.hidden = false;
      $hub.vars.description = 'Enter in your appointment number.'
      $hub.filters.search.control.focus();
  
  } else {
      const order = (await $datasources.FootPrintManager.ds_get_order_by_order_id.get({ order_id: orderId })).result[0];
  
      if (order.OrderClass.OrderTypeId === 1) {
          $hub.tabs.receiving_report.hidden = false;
          $hub.tabs.bill_of_lading.hidden = true;
  
          $hub.widgets.order_loaded_percentage_widget.hidden = true;
          $hub.widgets.order_picked_percentage_widget.hidden = true;
          $hub.widgets.order_total_pallet_count_widget.hidden = false;
          $hub.widgets.wait_time.hidden = false;
  
      } else if (order.OrderClass.OrderTypeId == 2) {
          $hub.tabs.bill_of_lading.hidden = false;
          $hub.tabs.receiving_report.hidden = true;
          $hub.widgets.order_loaded_percentage_widget.hidden = false;
          $hub.widgets.order_picked_percentage_widget.hidden = false;
          $hub.widgets.order_total_pallet_count_widget.hidden = false;
          $hub.widgets.wait_time.hidden = false;
      }
  
      if ($utils.isDefined(order)) {
  
          const carrierName = order.PreferredCarrier?.Name;
  
          $hub.vars.order = order.LookupCode
          $hub.vars.title = `Order ${$hub.vars.order}`
  
          // Add carrier
          if ($utils.isDefined(carrierName)) {
              const carrier = `  Carrier ${carrierName}`;
              $hub.vars.title += carrier;
          }
  
          // Set tab title
          $hub.title = `Check out order ${$hub.vars.order}`
      }
  
      // Get appointment information
      const dockAppointments = (await $datasources.DockAppointments.ds_get_dock_appointment_by_orderId.get({
          orderId: orderId
      })).result;
  
      if (!$utils.isDefined(dockAppointments)) return;
  
      // Set Date/Time Formats
      const format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.trim().toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
      const dockappointmentId = dockAppointments[0]?.DockAppointmentId;
      $hub.vars.dockAppointmentId = dockappointmentId;
  
      if (!$utils.isDefined(dockappointmentId)) return;
      $hub.toolbar.appointment.hidden = false;
  
      // Apply appointment text logic
      $hub.toolbar.appointment.control.label = (await $flows.DockAppointments.get_formatted_dock_appointment({
          dockAppointmentId: dockappointmentId
      })).formattedDockAppointment;
  
      const dockappointment = (await $datasources.DockAppointments.ds_dock_appointment_editor.get({
          dockAppointmentId: dockappointmentId
      })).result;
  
      if (!$utils.isDefined(dockappointment)) return;
  
      // Gather information and set title, description and various fields
      $hub.vars.statusId = dockappointment.Status.Id;
      const lookupcode = `  Appt ${dockappointment.LookupCode}`;
      $hub.vars.title += lookupcode;
  
      const scheduledArrival = $utils.isDefined(dockappointment.convertedScheduledArrival?.convertedDate)
          ? `Scheduled arrival ${$utils.date.format(dockappointment.convertedScheduledArrival?.convertedDate, format)}`
          : '';
      const checkedInOn = $utils.isDefined(dockappointment.convertedCheckInOn?.convertedDate)
          ? `Checked in on ${$utils.date.format(dockappointment.convertedCheckInOn?.convertedDate, format)}`
          : '';
  
      // Set description
      $hub.vars.description = `${scheduledArrival} ${checkedInOn}`
  
      const checkedOutOn = $utils.isDefined(dockappointment.convertedCompletedOn?.convertedDate)
          ? `Checked out on ${$utils.date.format(dockappointment.convertedCompletedOn?.convertedDate, format)}`
          : '';
  
      var driverName = $utils.isDefined(dockappointment.DriverName) ? `${dockappointment.DriverName} ` : '';
      var driverLicense = $utils.isDefined(dockappointment.DriverLicense) ? `Lic. ${dockappointment.DriverLicense} ` : '';
      var vehicleLicense = $utils.isDefined(dockappointment.VehicleLicense) ? `Vehicle Lic. ${dockappointment.VehicleLicense} ` : '';
  
      var driver_details = `${driverName}${driverLicense}${vehicleLicense}`;
  
      if ($utils.isDefined(driver_details)) {
          $hub.filters.driver_details.hidden = false;
          $hub.filters.driver_details.control.text = driver_details;
      }
  
      // If appointment is completed
      if ($hub.vars.statusId === 4) {
          $hub.toolbar.check_out.hidden = true;
          $hub.filters.appointment_details.hidden = false;
          $hub.filters.appointment_details.control.text = checkedOutOn;
  
          let get_attachments = (await $datasources.Attachments.ds_get_attachments_by_entity.getList({
              entityType: 'Order',
              entityKeys: [{ name: 'Id', value: $hub.inParams.orderId }]
          }))?.result
  
          if (get_attachments?.length > 0) {
  
              get_attachments = get_attachments.filter(a =>
                  a.FileName === 'Signature.png'
                  && a.Description === 'Driver check out hub'
              )
  
              if (get_attachments?.length > 0) {
                  $hub.vars.signatureExists = true;
              }
          }
  
          if (!$hub.vars.signatureExists) {
              $hub.toolbar.save_signature.hidden = false;
          } else {
              $hub.filters.signature_capture.hidden = true;
          }
      }
  
      // Calculate wait time
      let warehouseId = dockappointment.WarehouseId;
  
      $hub.vars.waitTime = 0;
      if ($utils.isAllDefined(dockappointment.ScheduledArrival, dockappointment.CheckedInOn, warehouseId)) {
  
          // If appointment is completed use this as the end time to calculate wait time
          var currentWarehouseDate;
          if ($utils.isDefined(dockappointment.CompletedOn)) {
  
              currentWarehouseDate = dockappointment.convertedCompletedOn.convertedDate;
  
          } else {
              let warehouseDate = (await $flows.DockAppointments.date_get_warehouse_flow({
                  warehouseId: warehouseId
              }));
  
              currentWarehouseDate = warehouseDate.date;
          }
  
          var inputDateString: string;
          if (new Date(dockappointment.ScheduledArrival) > new Date(dockappointment.CheckedInOn)) {
              inputDateString = dockappointment.convertedScheduledArrival.convertedDate;
          }
          else {
              inputDateString = dockappointment.convertedCheckInOn.convertedDate;
          }
  
          const timeDifference = subtractDates(currentWarehouseDate, inputDateString);
  
          if (timeDifference !== null) {
              $hub.vars.waitTime = (timeDifference ? (timeDifference < 0 ? 0 : timeDifference) : 0) + 0;
          }
      }
  }
  
  
  
  /*********************************************
   * FUNCTIONS
  **********************************************/
  // Function to subtract the current date and time from a date in string format
  function subtractDates(startDateTimeString: string, endDateString: string): number | null {
      // Parse the date strings into Date objects
      const startDate = new Date(startDateTimeString);
      const endDate = new Date(endDateString);
  
      // Check if the parsing was successful
      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
          return null;
      }
  
      // Calculate the time difference in minutes
      const timeDifference = Math.floor((startDate.getTime() - endDate.getTime()) / 60000);
  
      return timeDifference;
  }
  }
  on_check_out(event = null) {
    return this.on_check_outInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_check_outInternal(
    $hub: FootPrintManager_driver_check_out_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let flowSection = 'check out'; // use lower-case: to be concatenated into a message in the event of an error
  
  try {
      // Protect against a double click
      $hub.toolbar.check_out.control.readOnly = true;
  
      if (!$utils.isDefined($hub.vars.order)) {
          throw new Error('Unable to check out, please first provide an order.')
      }
  
      var userConfirmed = await $shell.FootPrintManager.openConfirmationDialog(
          'Check out',
          `Are you sure you want to check out order ${$hub.vars.order}?`,
          'Yes', 'No'
      );
  
      if (userConfirmed) {
  
          if (!$hub.vars.signatureExists) {
  
              // Save signature to the database against the order
              flowSection = 'signature capture';
              var signature = (await $utils.blob.fromBase64($hub.filters.signature_capture.control.value));
  
              await $flows.Attachments.add_attachment_to_entity_flow({
                  entityType: 'Order',
                  entityKeys: [{ name: 'Id', value: $hub.inParams.orderId }],
                  attachmentDetails: { fileName: 'Signature.png', description: 'Driver check out hub' },
                  fileContent: signature
              });
          }
  
          if ($hub.vars.statusId !== 4) {
  
              // Complete the dock appointment if one is found
              flowSection = 'completion of the dock appointment';
              if ($utils.isDefined($hub.vars.dockAppointmentId)) {
                  const user = (await $flows.Utilities.get_username_flow({})).userName;
                  let payload: any = {};
  
                  var resultUtc = $utils.date.add(new Date().getTimezoneOffset(), "minute", $utils.date.now());
  
                  payload.CompletedBy = user;
                  payload.StatusId = 4;
                  payload.CompletedOn = resultUtc;
  
                  await $flows.Utilities.crud_update_flow({ entitySet: 'DockAppointments', id: $hub.vars.dockAppointmentId, entity: payload });
              }
          }
  
          // Refresh the hub
          flowSection = 'refresh';
          await $hub.on_init();
          await $hub.refresh();
      }
  }
  catch (error) {
      while ($utils.isDefined(error.error)) {
          error = error.error;
      }
  
      await $shell.DockAppointments.openErrorDialog('Check out', `An error occurred during ${flowSection}`, [error.message], null, [{ message: error.message, detail: error }]);
  }
  
  $hub.toolbar.check_out.control.readOnly = false;
  }
  on_refresh(event = null) {
    return this.on_refreshInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refreshInternal(
    $hub: FootPrintManager_driver_check_out_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  // Call on init to refresh variables
  await $hub.on_init();
  
  // Reload screen
  await $hub.refresh();
  }
  on_appointment_clicked(event = null) {
    return this.on_appointment_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_appointment_clickedInternal(
    $hub: FootPrintManager_driver_check_out_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if (!$utils.isDefined($hub.vars.dockAppointmentId)) { return; }
  
  const dialogResult = await $shell.FootPrintManager.opendock_appointment_editorDialog({
      dockAppointmentId: $hub.vars.dockAppointmentId
  });
  
  if (!$utils.isDefined(dialogResult)) { return; }
  
  if (dialogResult.confirm) {
      await $hub.on_refresh();
  }
  
  
  }
  on_search(event = null) {
    return this.on_searchInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_searchInternal(
    $hub: FootPrintManager_driver_check_out_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const searchValue = $hub.filters.search.control.value;
  
  if ($utils.isDefined(searchValue)) {
  
      const appointments = (await $datasources.DockAppointments.ds_get_current_dock_appointments_by_lookupcode.get({ lookupcode: searchValue })).result;
  
      if ($utils.isDefined(appointments)) {
  
          const orderId = appointments.DockAppointmentsOrdersLookup[0]?.OrderId;
          if ($utils.isDefined(orderId)) {
              await $shell.FootPrintManager.opendriver_check_out_hub({ orderId: orderId }, true)
          }
          else {
  
              await $shell.FootPrintManager.openInfoDialog('Appointment Search', `Appointment ${searchValue} not found.`)
              $hub.filters.search.control.value = null;
              $hub.filters.search.control.focus();
          }
      }
  
      else {
  
          await $shell.FootPrintManager.openInfoDialog('Appointment Search', `Appointment ${searchValue} not found.`)
          $hub.filters.search.control.value = null;
          $hub.filters.search.control.focus();
      }
  
  
  
  }
  }
  //#endregion private flows
}
