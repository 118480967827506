import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { Locations_licenseplate_statuses_dd_singleComponent } from './Locations.licenseplate_statuses_dd_single.component'
import { ShippingContainers_container_type_dd_singleComponent } from './ShippingContainers.container_type_dd_single.component'
import { Locations_measurement_units_dd_singleComponent } from './Locations.measurement_units_dd_single.component'

type EntityType = { 
    Id?: number, Archived?: boolean, DimensionsUomId?: number, GrossWeight?: number, Height?: number, Length?: number, LicensePlateContainerTypeId?: number, LocationId?: number, LookupCode?: string, NetWeight?: number, ShipmentId?: number, StatusId?: number, TypeId?: number, WarehouseId?: number, WeightUomId?: number, Width?: number, Status?: { Name?: string }, Type?: { Name?: string }, Warehouse?: { Name?: string }, Location?: { Name?: string }, ContainerType?: { Name?: string }}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Locations_licenseplate_statuses_dd_singleComponent),
    forwardRef(() => ShippingContainers_container_type_dd_singleComponent),
    forwardRef(() => Locations_measurement_units_dd_singleComponent),
  ],
  selector: 'FootPrintManager-licenseplate_editor',
  templateUrl: './FootPrintManager.licenseplate_editor.component.html'
})
export class FootPrintManager_licenseplate_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { licenseplateId: number } = { licenseplateId: null };
  //#region Inputs
  @Input('licenseplateId') set $inParams_licenseplateId(v: number) {
    this.inParams.licenseplateId = v;
  }
  get $inParams_licenseplateId(): number {
    return this.inParams.licenseplateId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { owner_id?: number } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    archived: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    container_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    dimension_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    weight_uom: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    )
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'LP code', false),
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Status', false),
    archived: new FieldModel(new CheckBoxModel(this.formGroup.controls['archived'] as DatexFormControl, null, true, '', null)
, new ControlContainerStyles(null, null), 'Archived', false),
    container_type: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['container_type'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Container type', false),
    length: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Length', false),
    width: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Width', false),
    height: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Height', false),
    dimension_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['dimension_uom'] as DatexFormControl, 
  null, null,
  true, 
  '', null)
, new ControlContainerStyles(null, null), 'Dimension UOM', false),
    net_weight: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Net weight', false),
    gross_weight: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Gross weight', false),
    weight_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['weight_uom'] as DatexFormControl, 
  null, null,
  true, 
  '', null)
, new ControlContainerStyles(null, null), 'Weight UOM', false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true),
  newGroup2: new FieldsetModel('Details', false, true, false),
};


  //#region fields inParams
  get $fields_container_type_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.vars.owner_id;
    
    return expr;
  }

  get $fields_dimension_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_weight_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = 3;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    status: this.fields.status.control.valueChanges
    ,
    archived: this.fields.archived.control.valueChanges
    ,
    container_type: this.fields.container_type.control.valueChanges
    ,
    dimension_uom: this.fields.dimension_uom.control.valueChanges
    ,
    weight_uom: this.fields.weight_uom.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.licenseplateId)) {
        this.$missingRequiredInParams.push('licenseplateId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Licenseplate';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      licenseplateId:  $editor.inParams.licenseplateId 
    };

    const data = await this.datasources.Locations.ds_licenseplate_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    private async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.status.control as SelectBoxModel).reset($editor.entity.StatusId);
    (this.fields.archived.control as CheckBoxModel).reset($editor.entity.Archived);
    (this.fields.container_type.control as SelectBoxModel).reset($editor.entity.LicensePlateContainerTypeId);
    (this.fields.length.control as TextModel).text = $editor.entity.Length?.toString();
    (this.fields.width.control as TextModel).text = $editor.entity.Width?.toString();
    (this.fields.height.control as TextModel).text = $editor.entity.Height?.toString();
    (this.fields.dimension_uom.control as SelectBoxModel).reset($editor.entity.DimensionsUomId);
    (this.fields.net_weight.control as TextModel).text = $editor.entity.NetWeight?.toString();
    (this.fields.gross_weight.control as TextModel).text = $editor.entity.GrossWeight?.toString();
    (this.fields.weight_uom.control as SelectBoxModel).reset($editor.entity.WeightUomId);

  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .status
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .archived
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .container_type
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .dimension_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .weight_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $editor: FootPrintManager_licenseplate_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  if ($editor.fields.lookupcode.control.isChanged) {
      const licenseplate = (await $datasources.Locations.ds_get_licenseplates_by_lookupcode_and_warehouseId.get({
          warehouseId: $editor.entity.WarehouseId,
          lookupcode: $editor.fields.lookupcode.control.value
      })).result;
      if ($utils.isDefined(licenseplate)) {
          throw new Error('Duplicate licenseplate found');
      }
  }
  
  if (!$utils.isDefined($editor.fields.status.control.value)) {
      await $shell.Locations.openErrorDialog('Error saving license plate', 'Please select a status.');
      return;
  }
  
  // Construct payload with any values that have changed
  const payload: any = {};
  
  // General Properties
  if ($editor.fields.lookupcode.control.isChanged) {
      payload.LookupCode = $editor.fields.lookupcode.control.value;
  }
  if ($editor.fields.status.control.isChanged) {
      payload.StatusId = $editor.fields.status.control.value;
  
      const currentStatus = (await $datasources.Locations.ds_get_status_by_statusId.get({ statusId: $editor.entity.StatusId })).result;
      var currentStatusName = currentStatus.Name;
  
      const updatedStatus = (await $datasources.Locations.ds_get_status_by_statusId.get({ statusId: $editor.fields.status.control.value })).result;
      var updatedStatusName = updatedStatus.Name;
  }
  if ($editor.fields.container_type.control.isChanged) {
      payload.LicensePlateContainerTypeId = $editor.fields.container_type.control.value;
  }
  // Make API call
  try {
      // Create licenseplate status change task
      const format = `MM/DD/YYYY, LT`;
      const statusChange = `Status changed from [${currentStatusName}] to [${updatedStatusName}] on [${$utils.date.format($utils.date.now(), format)}]`
  
      if ($editor.fields.status.control.isChanged) {
  
          const nextTaskId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Task' })).nextId;
          const employee = (await $flows.Utilities.get_username_flow({})).userName;
  
          let taskPayload: any = {
              "Id": nextTaskId,
              "ChainHead": 0,
              "StatusId": 2,
              "WarehouseId": $editor.entity.WarehouseId,
              "OperationCodeId": 33,
              "Employee": employee,
              "ActualTargetLicensePlateId": $editor.entity.Id,
              "ActualSourceLicensePlateId": $editor.entity.Id,
              "CreatedSysDateTime": $utils.date.now(),
              "CompletedDateTime": $utils.date.now(),
              "ModifiedSysDateTime": $utils.date.now(),
              "Notes": statusChange
          };
  
          await $flows.Utilities.crud_create_flow({ entitySet: 'Tasks', entity: taskPayload });
      }
  
      // Update license plate
      (await $flows.Utilities.crud_update_flow({
          entitySet: 'LicensePlates',
          id: $editor.entity.Id,
          entity: payload
      }));
  
  
  
      $editor.outParams.confirm = true;
      $editor.close();
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
      let errorMessage: string[] = targetError.message.toString().trim().split('  ');
      errorMessage = [...new Set(errorMessage)]
      $shell.FootPrintManager.openErrorDialog('Error on save', errorMessage[errorMessage.length - 1]);
      //throw error; // To prevent display mode
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_licenseplate_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.outParams.confirm = false;
  $editor.close();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_licenseplate_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.outParams.confirm = false;
  
  //Get the owner for the LP is exists.
  
  //Get the contents
  let content = (await $datasources.Inventory.ds_get_licenseplatecontents_by_licenseplateId.get({ licenseplateId: $editor.inParams.licenseplateId })).result;
  
  if ($utils.isDefined(content)) {
  
      let lotId = content[0].LotId;
  
      //Get the info for the first lot
      let lot = (await $datasources.Inventory.ds_get_lot_by_lotId.get({ lotId: lotId })).result;
  
      if ($utils.isDefined(lot)) {
  
          let ownerId = lot[0].Material.Project.OwnerId;
          $editor.vars.owner_id = ownerId;
      }
  }
  }
  //#endregion private flows
  
}
