import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { UccLabels_ucc_label_costcoComponent } from './UccLabels.ucc_label_costco.component';
import { UccLabels_ucc_label_walmartComponent } from './UccLabels.ucc_label_walmart.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class UccLabels_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public UccLabels: UccLabels_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openucc_label_costco(inParams:{ shipment_id?: number, order_id?: number, shipping_container_id?: number, copies?: number }, replaceCurrentView?: boolean) {
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'ucc_label_costco',
        referenceName: 'UccLabels_ucc_label_costco',
        component: UccLabels_ucc_label_costcoComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openucc_label_costcoDialog(
    inParams:{ shipment_id?: number, order_id?: number, shipping_container_id?: number, copies?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      UccLabels_ucc_label_costcoComponent,
      'ucc_label_costco',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openucc_label_walmart(inParams:{ shipment_id?: number, order_id?: number, shipping_container_id?: number, copies?: number }, replaceCurrentView?: boolean) {
    this.logger.log('UccLabels', 'ucc_label_walmart');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'ucc_label_walmart',
        referenceName: 'UccLabels_ucc_label_walmart',
        component: UccLabels_ucc_label_walmartComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openucc_label_walmartDialog(
    inParams:{ shipment_id?: number, order_id?: number, shipping_container_id?: number, copies?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('UccLabels', 'ucc_label_walmart');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      UccLabels_ucc_label_walmartComponent,
      'ucc_label_walmart',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'UccLabels_ucc_label_costco') {
      const title = 'ucc_label_costco';
      const component = UccLabels_ucc_label_costcoComponent;
      const inParams:{ shipment_id?: number, order_id?: number, shipping_container_id?: number, copies?: number } = { shipment_id: null, order_id: null, shipping_container_id: null, copies: null };
      if (!isNil(params.get('shipment_id'))) {
        const paramValueString = params.get('shipment_id');
        inParams.shipment_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipping_container_id'))) {
        const paramValueString = params.get('shipping_container_id');
        inParams.shipping_container_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('copies'))) {
        const paramValueString = params.get('copies');
        inParams.copies = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'UccLabels_ucc_label_walmart') {
      this.logger.log('UccLabels', 'ucc_label_walmart');
      const title = 'ucc_label_walmart';
      const component = UccLabels_ucc_label_walmartComponent;
      const inParams:{ shipment_id?: number, order_id?: number, shipping_container_id?: number, copies?: number } = { shipment_id: null, order_id: null, shipping_container_id: null, copies: null };
      if (!isNil(params.get('shipment_id'))) {
        const paramValueString = params.get('shipment_id');
        inParams.shipment_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('order_id'))) {
        const paramValueString = params.get('order_id');
        inParams.order_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipping_container_id'))) {
        const paramValueString = params.get('shipping_container_id');
        inParams.shipping_container_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('copies'))) {
        const paramValueString = params.get('copies');
        inParams.copies = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
