import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Invoices_ShellService } from './Invoices.shell.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Invoices.frontend.types'
import { $frontendTypes as $types} from './Invoices.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { Invoices_invoice_terms_dd_singleComponent } from './Invoices.invoice_terms_dd_single.component'
import { Invoices_tax_schedules_dd_singleComponent } from './Invoices.tax_schedules_dd_single.component'
import { Invoices_billing_aggregation_strategies_dd_multiComponent } from './Invoices.billing_aggregation_strategies_dd_multi.component'
import { Invoices_auto_invoicing_rule_group_by_options_singleComponent } from './Invoices.auto_invoicing_rule_group_by_options_single.component'

type EntityType = { 
    instructionId?: string, billingContractId?: number, projectId?: number, active?: boolean, instructionType?: string, instructionName?: string, billingAggregationStrategyIds?: number[], oneInvoicePerShipment?: boolean, oneInvoicePerWarehouse?: boolean, termId?: number, taxScheduleId?: number, createdOn?: string, createdBy?: string, modifiedOn?: string, modifiedBy?: string, useExistingInvoice?: boolean, isNew?: boolean}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Invoices_invoice_terms_dd_singleComponent),
    forwardRef(() => Invoices_tax_schedules_dd_singleComponent),
    forwardRef(() => Invoices_billing_aggregation_strategies_dd_multiComponent),
    forwardRef(() => Invoices_auto_invoicing_rule_group_by_options_singleComponent),
  ],
  selector: 'Invoices-auto_invoicing_rule_editor',
  templateUrl: './Invoices.auto_invoicing_rule_editor.component.html'
})
export class Invoices_auto_invoicing_rule_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { ruleId: string, projectId: number } = { ruleId: null, projectId: null };
  //#region Inputs
  @Input('ruleId') set $inParams_ruleId(v: string) {
    this.inParams.ruleId = v;
  }
  get $inParams_ruleId(): string {
    return this.inParams.ruleId;
  }
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { billingStrategyIds?: number[], hasNonShippingStrategy?: boolean } = { };
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    active: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    create_new_invoice: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    name: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    terms: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    tax_schedule: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    billing_aggregation_strategies: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    group_by: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      edit: new ToolModel(new ButtonModel('edit', new ButtonStyles(['secondary'], null), false, 'Edit', 'icon-ic_fluent_edit_20_regular', null)
    ),
      save: new ToolModel(new ButtonModel('save', new ButtonStyles(['primary'], null), false, 'Save', 'icon-ic_fluent_save_20_regular', null)
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(null, null), false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      delete_rule: new ToolModel(new ButtonModel('delete_rule', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      options: new ToolModel(new SplitButtonModel<{ transactions: ButtonModel, logs: ButtonModel }>(
        'options',
        new ButtonStyles(null, null),
        false,
        ' ',
        'icon-ic_fluent_more_horizontal_20_regular',
        null,
        [
          new ButtonModel('transactions', new ButtonStyles(null, null), false, 'Transactions', ''),
          new ButtonModel('logs', new ButtonStyles(null, null), false, 'Logs', '')
        ])
    )
  };

  fields = {
    active: new FieldModel(new CheckBoxModel(this.formGroup.controls['active'] as DatexFormControl, null, false, 'Active', null)
, new ControlContainerStyles(null, null), '', false),
    create_new_invoice: new FieldModel(new CheckBoxModel(this.formGroup.controls['create_new_invoice'] as DatexFormControl, null, false, 'Add billing records to existing invoices', null)
, new ControlContainerStyles(null, null), '', false),
    name: new FieldModel(new TextBoxModel(this.formGroup.controls['name'] as DatexFormControl, null, false, 'Auto generated from the billing aggregation strategies', null)
, new ControlContainerStyles(null, null), 'Name', false),
    terms: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['terms'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Invoice terms', true),
    tax_schedule: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['tax_schedule'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Tax schedule', true),
    billing_aggregation_strategies: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['billing_aggregation_strategies'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Billing aggregation strategies', false),
    group_by: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['group_by'] as DatexFormControl, 
  ESelectBoxType.radioButtonsCheckboxes, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Group billing records by', false),
  };

  fieldsets = {
  header: new FieldsetModel('Header', true, false, true),
};


  //#region fields inParams
  cacheValueFor_$fields_billing_aggregation_strategies_selector_inParams_id: number[];
  get $fields_billing_aggregation_strategies_selector_inParams_id(): number[] {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.vars.billingStrategyIds;
    
    if(!isEqual(this.cacheValueFor_$fields_billing_aggregation_strategies_selector_inParams_id, expr)) {
      this.cacheValueFor_$fields_billing_aggregation_strategies_selector_inParams_id = expr;
    }
    return this.cacheValueFor_$fields_billing_aggregation_strategies_selector_inParams_id;
  }

  cacheValueFor_$fields_group_by_selector_inParams_exclude: string[];
  get $fields_group_by_selector_inParams_exclude(): string[] {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.vars.hasNonShippingStrategy ? ['SHIPMENT'] : [];
    
    if(!isEqual(this.cacheValueFor_$fields_group_by_selector_inParams_exclude, expr)) {
      this.cacheValueFor_$fields_group_by_selector_inParams_exclude = expr;
    }
    return this.cacheValueFor_$fields_group_by_selector_inParams_exclude;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    active: this.fields.active.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    create_new_invoice: this.fields.create_new_invoice.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    name: this.fields.name.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    terms: this.fields.terms.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    tax_schedule: this.fields.tax_schedule.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    billing_aggregation_strategies: this.fields.billing_aggregation_strategies.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    group_by: this.fields.group_by.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Invoices_ShellService,
    private datasources: Invoices_DatasourceService,
    private flows: Invoices_FlowService,
    private reports: Invoices_ReportService,
    private localization: Invoices_LocalizationService,
    private operations: Invoices_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.ruleId)) {
        this.$missingRequiredInParams.push('ruleId');
      }
      if(isNil(this.inParams.projectId)) {
        this.$missingRequiredInParams.push('projectId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Auto-invoicing rule';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      ruleId:  $editor.inParams.ruleId ,
      projectId:  $editor.inParams.projectId 
    };

    const data = await this.datasources.Invoices.ds_auto_invoicing_rules_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    private async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.active.control as CheckBoxModel).reset($editor.entity?.active ?? true);
    (this.fields.create_new_invoice.control as CheckBoxModel).reset($editor.entity?.useExistingInvoice ?? true);
    (this.fields.name.control as TextBoxModel).reset($editor.entity.instructionName);
    (this.fields.terms.control as SelectBoxModel).reset($editor.entity.termId);
    (this.fields.tax_schedule.control as SelectBoxModel).reset($editor.entity.taxScheduleId);
    (this.fields.billing_aggregation_strategies.control as SelectBoxModel).reset($editor.entity.billingAggregationStrategyIds);
    (this.fields.group_by.control as SelectBoxModel).reset($editor.entity.oneInvoicePerShipment ? 'SHIPMENT' : $editor.entity.oneInvoicePerWarehouse ? 'WAREHOUSE' : 'RULE');

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .active
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .create_new_invoice
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .terms
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .tax_schedule
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .billing_aggregation_strategies
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_billing_aggregation_strategies_change();
      });
    this.$formGroupFieldValidationObservables
      .group_by
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_save(event = null) {
    return this.on_saveInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_saveInternal(
    $editor: Invoices_auto_invoicing_rule_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  let rule = $editor.entity;
  
  let ruleName = $editor.fields.name.control.value ?? "";
  
  if (ruleName.trim() === "") {
      if ($utils.isDefined($editor.fields.billing_aggregation_strategies)) {
  
          const allStrategiesCount = (await $datasources.Invoices.ds_billing_aggregation_strategies_dd.getList({})).totalCount;
          let bs = (await $datasources.Invoices.ds_billing_aggregation_strategies_dd.get({ id: $editor.fields.billing_aggregation_strategies.control.value })).result;
  
          let main: typeof bs;
  
          main = bs.filter(bs => bs.Name.toUpperCase().includes("INBOUND"));
  
          if (!$utils.isDefined(main)) {
              main = bs.filter(bs => bs.Name.toUpperCase().includes("OUTBOUND"));
          }
  
          if (!$utils.isDefined(main)) {
              main = bs.filter(bs => bs.Name.toUpperCase().includes("RECURRING"));
          }
  
          if (!$utils.isDefined(main)) {
              main = bs.filter(bs => bs.Name.toUpperCase().includes("INITIAL"));
          }
  
          if (!$utils.isDefined(main)) {
              main = bs.filter(bs => bs.Name.toUpperCase().includes("ACCESSORIAL"));
          }
  
          if (!$utils.isDefined(main)) {
              main = bs;
          }
  
          main = main.sort((a, b) => a.Id - b.Id);
  
          ruleName = main[0].EnumName;
  
          if (bs.length > 1) {
              if (bs.length === allStrategiesCount) {
                  ruleName = `All`;
              }
              else {
                  ruleName = `${ruleName}+${bs.length - 1}`;
              }
          }
      }
  }
  
  let billingAggregationStrategyIds: number[] = $editor.fields.billing_aggregation_strategies.control.value;
  
  const oneInvoicePerShipment = $editor.fields.group_by.control.value === 'SHIPMENT';
  const oneInvoicePerWarehouse = oneInvoicePerShipment || $editor.fields.group_by.control.value === 'WAREHOUSE';
  
  rule = {
      instructionId: rule.instructionId,
      instructionType: "Auto Invoicing",
      instructionName: ruleName,
      active: $editor.fields.active.control.value ?? true,
      billingAggregationStrategyIds: billingAggregationStrategyIds,
      oneInvoicePerShipment: oneInvoicePerShipment,
      oneInvoicePerWarehouse: oneInvoicePerWarehouse,
      useExistingInvoice: $editor.fields.create_new_invoice.control.value,
      termId: $editor.fields.terms.control.value,
      taxScheduleId: $editor.fields.tax_schedule.control.value,
      projectId: $editor.entity.projectId
  };
  
  if ($editor.entity.isNew) {
      await $flows.Invoices.invoicing_instruction({ action: "Write", payload: rule });
  } else {
      await $flows.Invoices.invoicing_instruction({ action: "Update", payload: rule });
  }
  
  $editor.close();
  }
  on_activate(event = null) {
    return this.on_activateInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_activateInternal(
    $editor: Invoices_auto_invoicing_rule_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
    this.logger.log('Invoices', 'auto_invoicing_rule_editor.on_activate');
  if ($utils.isDefined($editor.fields.active)) {
  
      if ($editor.fields.active.control.value && $editor.entity.active !== $editor.fields.active.control.value) {
  
          if ($utils.isAllDefined(
               $editor.fields.billing_aggregation_strategies.control.value,
              $editor.fields.terms.control.value,
              $editor.fields.tax_schedule.control.value)) 
          {
              $editor.entity.active = $editor.fields.active.control.value;        
          } else {
              $shell.Invoices.openToaster("Cannot activate rule", "The instruction cannot be activated because a necessary field is not defined", EToasterType.Error, { closeButton: true, timeOut: 7000, positionClass: EToasterPosition.bottomRight });
              $editor.fields.active.control.value = false;
          }
      }
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: Invoices_auto_invoicing_rule_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  let allowDuplicates: boolean = false;
  let option = (await $flows.Invoices.invoicing_option({ action: "Read", payload: { dimension: "AllowDuplicateStrategies" } })).payload;
  if (option.length > 0) {
      let truth: any[] = ["TRUE", "1"]
      if (truth.includes(option[0].value.toUpperCase())) {
          allowDuplicates = true
      }
  } else {
      await $flows.Invoices.invoicing_option({ action: "Write", payload: { dimension: "AllowDuplicateStrategies", value: `${allowDuplicates}` } })
  }
  
  if (!allowDuplicates) {
      let instruction = (await $flows.Invoices.invoicing_instruction({
          action: "Read",
          payload: {
              instructionId: $editor.inParams.ruleId,
          }
      })).payload[0];
  
      if ($utils.isDefined(instruction)) {
          let instructions = (await $flows.Invoices.invoicing_instruction({
              action: "Read",
              payload: {
                  projectId: instruction.projectId,
              }
          })).payload;
  
  
          instructions = instructions.filter(i => i.instructionId.toUpperCase() !== $editor.inParams.ruleId.toUpperCase())
  
          if (instructions.length > 0) {
  
              const bs = (await $datasources.Invoices.ds_billing_aggregation_strategies_dd.get({})).result;
              const accessorial_ids = bs.filter(bs => bs.Name.toUpperCase().includes("ACCESSORIAL")).map(bs => bs.Id);
              const bs_already_used = instructions.filter(i => $utils.isDefined(i.billingAggregationStrategyIds)).map(i => i.billingAggregationStrategyIds).flat();
  
              let bs_available = bs.filter(bs => bs_already_used.indexOf(bs.Id) === -1)?.map(bs => bs.Id)
  
              bs_available.push(...accessorial_ids);
  
              if (bs_available.length === 0) {
                  bs_available.push(0);
              }
  
              $editor.vars.billingStrategyIds = bs_available;
          }
      }
  }
  }
  on_billing_aggregation_strategies_change(event = null) {
    return this.on_billing_aggregation_strategies_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_billing_aggregation_strategies_changeInternal(
    $editor: Invoices_auto_invoicing_rule_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  let bs_selected = $editor.fields.billing_aggregation_strategies.control.value;
  
  if ($utils.isDefined(bs_selected)) {
  
      let bs = (await $datasources.Invoices.ds_billing_aggregation_strategies_dd.get({ id: bs_selected })).result;
  
      //Check if they are of not shipment types
      bs = bs.filter(bs =>
          !bs.Name.toUpperCase().includes("INITIAL") &&
          !bs.Name.toUpperCase().includes("INBOUND") &&
          !bs.Name.toUpperCase().includes("OUTBOUND") &&
          !bs.Name.toUpperCase().includes("ACCESSORIAL")
      )
  
      //If we have strategies of not shipment type we cannot have a one invoice per shipment
      $editor.vars.hasNonShippingStrategy = bs.length > 0;
  
      if ($editor.vars.hasNonShippingStrategy && $editor.fields.group_by.control.value === $types.Invoices.InvoiceRuleGroupByOptionsEnum.Shipment) {
          $editor.fields.group_by.control.value = $types.Invoices.InvoiceRuleGroupByOptionsEnum.Warehouse;
      }
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: Invoices_auto_invoicing_rule_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  if ($editor.entity.isNew) {
      $editor.set_edit_mode();
  } else {
      $editor.set_view_mode();
  }
  
  $editor.on_billing_aggregation_strategies_change();
  }
  on_transactions_clicked(event = null) {
    return this.on_transactions_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_transactions_clickedInternal(
    $editor: Invoices_auto_invoicing_rule_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  await $shell.Invoices.openinvoicing_transactions_gridDialog({ instructionId: $editor.entity.instructionId }, 'flyout', EModalSize.Xlarge);
  }
  on_logs_clicked(event = null) {
    return this.on_logs_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_logs_clickedInternal(
    $editor: Invoices_auto_invoicing_rule_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  await $shell.Invoices.openinvoicing_logs_gridDialog({ instructionId: $editor.entity.instructionId }, 'flyout', EModalSize.Xlarge);
  }
  set_view_mode(event = null) {
    return this.set_view_modeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async set_view_modeInternal(
    $editor: Invoices_auto_invoicing_rule_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  $editor.toolbar.edit.hidden = false;
  $editor.toolbar.save.hidden = true;
  $editor.toolbar.cancel.hidden = true;
  
  $editor.toolbar.delete_rule.hidden = false;
  $editor.toolbar.separator1.hidden = false;
  
  $editor.toolbar.options.hidden = false;
  $editor.toolbar.separator2.hidden = false;
  
  for (let field in $editor.fields) {
      if ($utils.isDefined($editor.fields[field].control?.readOnly)) {
          $editor.fields[field].control.readOnly = true;
      }
  }
  }
  set_edit_mode(event = null) {
    return this.set_edit_modeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async set_edit_modeInternal(
    $editor: Invoices_auto_invoicing_rule_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  $editor.toolbar.edit.hidden = true;
  $editor.toolbar.save.hidden = false;
  $editor.toolbar.save.control.readOnly = true;
  $editor.toolbar.cancel.hidden = false;
  
  if ($editor.entity.isNew) {
      $editor.toolbar.delete_rule.hidden = true;
      $editor.toolbar.separator1.hidden = true;
      $editor.toolbar.options.hidden = true;
      $editor.toolbar.separator2.hidden = true;
  }
  
  for (let field in $editor.fields) {
      if ($utils.isDefined($editor.fields[field].control?.readOnly)) {
          $editor.fields[field].control.readOnly = false;
      }
  }
  
  $editor.on_billing_aggregation_strategies_change();
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $editor: Invoices_auto_invoicing_rule_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  // Confirm delete
  const confirm_delete = (await $shell.Invoices.openConfirmationDialog(`Confirm delete`, `Do you want to delete this auto-invoicing rule?`, `Delete`, `Cancel`));
  if (!confirm_delete) { return; }
  
  
  
  try {
  
      await $editor.delete_rule();
  
      $shell.Invoices.openToaster('Rule deleted', null, EToasterType.Success, { closeButton: true, timeOut: 5000, positionClass: EToasterPosition.bottomRight });
  
  } catch (error) {
  
      let target_error = error;
  
      if (typeof target_error === "string") {
          target_error = { message: target_error };
      } else {
          while ($utils.isDefined(target_error.error)) {
              target_error = target_error.error;
          };
      };
  
      if (!$utils.isDefined(target_error.message)) {
          target_error = { message: JSON.stringify(target_error) };
      };
  
      $shell.Invoices.openToaster('Error deleting rule', target_error.message, EToasterType.Error, { closeButton: true, timeOut: 7000, positionClass: EToasterPosition.bottomRight });
  };
  
  
  
  
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: Invoices_auto_invoicing_rule_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  if ($editor.entity.isNew) {
      $editor.close();
  }
  else {
      $editor.set_view_mode();
      $editor.refresh();
  }
  }
  delete_rule(event = null) {
    return this.delete_ruleInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async delete_ruleInternal(
    $editor: Invoices_auto_invoicing_rule_editorComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  const ruleId = $editor.entity.instructionId;
  
  // Delete priorities
  await $flows.Invoices.delete_invoicing_project_rule_priority({ ruleId: ruleId });
  
  // Delete exceptions
  await $flows.Invoices.delete_invoicing_rule_template_exception({ rule_id: ruleId });
  
  // Delete rule
  await $flows.Invoices.invoicing_instruction({ action: "Delete", payload: { instructionId: ruleId }});
  
  $editor.close();
  }
  //#endregion private flows
  //#region validation flows
  
  validate_form(fieldErrors: { active: Pick<string[], 'push'>,create_new_invoice: Pick<string[], 'push'>,name: Pick<string[], 'push'>,terms: Pick<string[], 'push'>,tax_schedule: Pick<string[], 'push'>,billing_aggregation_strategies: Pick<string[], 'push'>,group_by: Pick<string[], 'push'>, } = null) {
    const emptyResult = { active:[],create_new_invoice:[],name:[],terms:[],tax_schedule:[],billing_aggregation_strategies:[],group_by:[], };
    if (!this.initialized){
      return Promise.resolve(emptyResult);
    }
    return this.validate_formInternal(
      this,
      { fieldErrors: fieldErrors ?? emptyResult },
      this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      );
  }
  async validate_formInternal(
    $editor: Invoices_auto_invoicing_rule_editorComponent,
    $validation:{
      fieldErrors: { active: Pick<string[], 'push'>,create_new_invoice: Pick<string[], 'push'>,name: Pick<string[], 'push'>,terms: Pick<string[], 'push'>,tax_schedule: Pick<string[], 'push'>,billing_aggregation_strategies: Pick<string[], 'push'>,group_by: Pick<string[], 'push'>, }
    },
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
  ) {
    let isValid = true;
  
  for (let fieldName in $editor.fields) {
      if ($editor.fields[fieldName].required && !$utils.isDefined($editor.fields[fieldName].control.value)) {
          isValid = false;
      }
  }
  
  $editor.toolbar.save.control.readOnly = !isValid;
  
    return $validation.fieldErrors as { active:[],create_new_invoice:[],name:[],terms:[],tax_schedule:[],billing_aggregation_strategies:[],group_by:[], };
  }
  //#endregion validation flows
  
}
