<div class="card-container">
  <mat-card class="datex-card card"
            data-cy="card" 
            [ngStyle]="styles.style" 
            [ngClass]="styles.classes">
    <ng-container
                  *ngIf="initialized && !$hasMissingRequiredInParams">



      <mat-card-title-group class="card-header">
        <div class="card-title-container">
          <ng-content></ng-content>
          <mat-card-title data-cy="card-title" class="card-title card-title-link" (click)="on_open_editor_clicked()">{{headerTitle}}</mat-card-title>
        </div>

        <div class="card-icon-container">
            <i data-cy="card-icon" [class]="'card-icon icon ms-Icon ms-Icon--' + headerIcon + headerIconSize + headerIconColor + headerIconTransparency"></i>
              <div data-cy="card-icon-text" class="card-icon-text">{{headerIconText}}</div>
        </div>
      </mat-card-title-group>


      <mat-card-content data-cy="card-content" class="card-content" >
        <div [formGroup]="formGroupContent"
             class="formdata">
                <div data-cy="fieldset-formGroupContent-id-newGroup1"
                     *ngIf="!content.fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': content.fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!content.fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="content.fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{content.fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="content.fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !content.fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': content.fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="content.fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-scheduled_arrival" *ngIf="!content.fields.scheduled_arrival.hidden" 
                            class="field-container double {{content.fields.scheduled_arrival.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.scheduled_arrival.styles.style"
                            [ngClass]="content.fields.scheduled_arrival.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.scheduled_arrival.label + (content.fields.scheduled_arrival.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.scheduled_arrival.label}}<span *ngIf="content.fields.scheduled_arrival.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="scheduled_arrival"
                            [format]="content.fields.scheduled_arrival.control.format"
                            [mode]="content.fields.scheduled_arrival.control.mode"
                            [ngStyle]="content.fields.scheduled_arrival.control.styles.style"
                            [ngClass]="content.fields.scheduled_arrival.control.styles.classes"
                            [tooltip]="content.fields.scheduled_arrival.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="content.fields.scheduled_arrival.invalid">
                          <ng-container *ngFor="let error of content.fields.scheduled_arrival.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-checked_in_time" *ngIf="!content.fields.checked_in_time.hidden" 
                            class="field-container double {{content.fields.checked_in_time.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.checked_in_time.styles.style"
                            [ngClass]="content.fields.checked_in_time.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.checked_in_time.label + (content.fields.checked_in_time.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.checked_in_time.label}}<span *ngIf="content.fields.checked_in_time.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="checked_in_time"
                            [format]="content.fields.checked_in_time.control.format"
                            [mode]="content.fields.checked_in_time.control.mode"
                            [ngStyle]="content.fields.checked_in_time.control.styles.style"
                            [ngClass]="content.fields.checked_in_time.control.styles.classes"
                            [tooltip]="content.fields.checked_in_time.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="content.fields.checked_in_time.invalid">
                          <ng-container *ngFor="let error of content.fields.checked_in_time.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-completed_on" *ngIf="!content.fields.completed_on.hidden" 
                            class="field-container double {{content.fields.completed_on.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.completed_on.styles.style"
                            [ngClass]="content.fields.completed_on.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.completed_on.label + (content.fields.completed_on.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.completed_on.label}}<span *ngIf="content.fields.completed_on.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="completed_on"
                            [format]="content.fields.completed_on.control.format"
                            [mode]="content.fields.completed_on.control.mode"
                            [ngStyle]="content.fields.completed_on.control.styles.style"
                            [ngClass]="content.fields.completed_on.control.styles.classes"
                            [tooltip]="content.fields.completed_on.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="content.fields.completed_on.invalid">
                          <ng-container *ngFor="let error of content.fields.completed_on.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-cancelled_on" *ngIf="!content.fields.cancelled_on.hidden" 
                            class="field-container double {{content.fields.cancelled_on.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.cancelled_on.styles.style"
                            [ngClass]="content.fields.cancelled_on.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.cancelled_on.label + (content.fields.cancelled_on.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.cancelled_on.label}}<span *ngIf="content.fields.cancelled_on.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="cancelled_on"
                            [format]="content.fields.cancelled_on.control.format"
                            [mode]="content.fields.cancelled_on.control.mode"
                            [ngStyle]="content.fields.cancelled_on.control.styles.style"
                            [ngClass]="content.fields.cancelled_on.control.styles.classes"
                            [tooltip]="content.fields.cancelled_on.control.tooltip">
                        </app-datebox>
                        <ng-container *ngIf="content.fields.cancelled_on.invalid">
                          <ng-container *ngFor="let error of content.fields.cancelled_on.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!content.fields.project.hidden" 
                            class="field-container standard {{content.fields.project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.project.styles.style"
                            [ngClass]="content.fields.project.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.project.label + (content.fields.project.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.project.label}}<span *ngIf="content.fields.project.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="content.fields.project.control.styles.style"
                              [ngClass]="content.fields.project.control.styles.classes"
                              [matTooltip]="content.fields.project.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{content.fields.project.control.text }}</div>
                        <ng-container *ngIf="content.fields.project.invalid">
                          <ng-container *ngFor="let error of content.fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order" *ngIf="!content.fields.order.hidden" 
                            class="field-container standard {{content.fields.order.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.order.styles.style"
                            [ngClass]="content.fields.order.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.order.label + (content.fields.order.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.order.label}}<span *ngIf="content.fields.order.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="content.fields.order.control.styles.style"
                              [ngClass]="content.fields.order.control.styles.classes"
                              [matTooltip]="content.fields.order.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{content.fields.order.control.text }}</div>
                        <ng-container *ngIf="content.fields.order.invalid">
                          <ng-container *ngFor="let error of content.fields.order.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-progress" *ngIf="!content.fields.progress.hidden" 
                            class="field-container standard {{content.fields.progress.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.progress.styles.style"
                            [ngClass]="content.fields.progress.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.progress.label + (content.fields.progress.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.progress.label}}<span *ngIf="content.fields.progress.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="content.fields.progress.control.styles.style"
                              [ngClass]="content.fields.progress.control.styles.classes"
                              [matTooltip]="content.fields.progress.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{content.fields.progress.control.text }}</div>
                        <ng-container *ngIf="content.fields.progress.invalid">
                          <ng-container *ngFor="let error of content.fields.progress.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-carrier" *ngIf="!content.fields.carrier.hidden" 
                            class="field-container standard {{content.fields.carrier.invalid ? 'invalid' : ''}}"
                            [ngStyle]="content.fields.carrier.styles.style"
                            [ngClass]="content.fields.carrier.styles.classes">
                        <div class="label-container"
                             [matTooltip]="content.fields.carrier.label + (content.fields.carrier.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{content.fields.carrier.label}}<span *ngIf="content.fields.carrier.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="content.fields.carrier.control.styles.style"
                              [ngClass]="content.fields.carrier.control.styles.classes"
                              [matTooltip]="content.fields.carrier.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{content.fields.carrier.control.text }}</div>
                        <ng-container *ngIf="content.fields.carrier.invalid">
                          <ng-container *ngFor="let error of content.fields.carrier.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
      </mat-card-content>



    </ng-container>

    <div *ngIf="$hasMissingRequiredInParams"
        class="missing-params">
      <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
    </div>
  </mat-card>
  <div class="card-gripper"></div>
</div>