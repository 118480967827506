<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="fail_verification" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_fail_verification_clicked($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="separator1" let-tool>
              <div class="tool-separator"></div>
            </ng-template>
            <ng-template toolbarToolDef="print" let-tool>
              <button mat-button 
                      data-cy="splitbutton"
                      class="datex-button splitbutton" 
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matMenuTriggerFor]="dropdownMenuOne"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip">
                <div class="button-label">
                  <div *ngIf="tool.control.icon"
                        class="button-icon">
                    <i data-cy="splitbutton-icon" class="icon {{tool.control.icon}}"></i>
                  </div>
                  <div data-cy="splitbutton-label" class="button-text">{{tool.control.label}}</div>
                  <div class="button-icon splitbutton-drop-icon">
                    <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
                  </div>
                </div>
              </button>
              <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
                <div data-cy="splitbutton-options">
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.print_master_packing_list.readOnly"
                            [ngStyle]="tool.control.buttons.print_master_packing_list.styles.style"
                            [ngClass]="tool.control.buttons.print_master_packing_list.styles.classes"
                            [matTooltip]="tool.control.buttons.print_master_packing_list.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_print_master_packing_list_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.print_master_packing_list.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.print_master_packing_list.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.print_master_packing_list.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.print_packing_list.readOnly"
                            [ngStyle]="tool.control.buttons.print_packing_list.styles.style"
                            [ngClass]="tool.control.buttons.print_packing_list.styles.classes"
                            [matTooltip]="tool.control.buttons.print_packing_list.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_print_packing_list_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.print_packing_list.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.print_packing_list.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.print_packing_list.label}}</div>
                      </div>
                    </button>
                  </div>
                  <div mat-menu-item class="splitbutton-item-container">
                    <button mat-button
                            data-cy="splitbutton-sub-item"
                            class="datex-button splitbutton-sub-item" 
                            [disabled]="tool.control.buttons.print_shipping_label.readOnly"
                            [ngStyle]="tool.control.buttons.print_shipping_label.styles.style"
                            [ngClass]="tool.control.buttons.print_shipping_label.styles.classes"
                            [matTooltip]="tool.control.buttons.print_shipping_label.tooltip"
                            matTooltipClass="datex-control-tooltip"
                            (click)="on_print_label_clicked($event)">
                      <div class="button-label">
                        <div *ngIf="tool.control.buttons.print_shipping_label.icon"
                            class="button-icon">
                          <i data-cy="splitbutton-sub-item-icon" class="icon {{tool.control.buttons.print_shipping_label.icon}}"></i>
                        </div>
                        <div data-cy="splitbutton-sub-item-label"
                            class="button-text">{{tool.control.buttons.print_shipping_label.label}}</div>
                      </div>
                    </button>
                  </div>
                </div>
              </mat-menu>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-instructions" *ngIf="!fields.instructions.hidden" 
                            class="field-container full {{fields.instructions.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.instructions.styles.style"
                            [ngClass]="fields.instructions.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.instructions.label + (fields.instructions.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.instructions.label}}<span *ngIf="fields.instructions.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi" formControlName="instructions"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.instructions.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.instructions.control.placeholder}}"
                                [ngStyle]="fields.instructions.control.styles.style"
                                [ngClass]="fields.instructions.control.styles.classes"
                                [matTooltip]="fields.instructions.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> </textarea>
                        <ng-container *ngIf="fields.instructions.invalid">
                          <ng-container *ngFor="let error of fields.instructions.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup2.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-container_type" *ngIf="!fields.container_type.hidden" 
                            class="field-container standard {{fields.container_type.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.container_type.styles.style"
                            [ngClass]="fields.container_type.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.container_type.label + (fields.container_type.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.container_type.label}}<span *ngIf="fields.container_type.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.container_type.control.styles.style"
                              [ngClass]="fields.container_type.control.styles.classes"
                              [matTooltip]="fields.container_type.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.container_type.control.text }}</div>
                        <ng-container *ngIf="fields.container_type.invalid">
                          <ng-container *ngFor="let error of fields.container_type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-dimensions" *ngIf="!fields.dimensions.hidden" 
                            class="field-container standard {{fields.dimensions.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.dimensions.styles.style"
                            [ngClass]="fields.dimensions.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.dimensions.label + (fields.dimensions.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.dimensions.label}}<span *ngIf="fields.dimensions.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.dimensions.control.styles.style"
                              [ngClass]="fields.dimensions.control.styles.classes"
                              [matTooltip]="fields.dimensions.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.dimensions.control.text }}</div>
                        <ng-container *ngIf="fields.dimensions.invalid">
                          <ng-container *ngFor="let error of fields.dimensions.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-weight" *ngIf="!fields.weight.hidden" 
                            class="field-container standard {{fields.weight.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.weight.styles.style"
                            [ngClass]="fields.weight.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.weight.label + (fields.weight.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.weight.label}}<span *ngIf="fields.weight.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <div data-cy="text" class="datex-text" 
                              [ngStyle]="fields.weight.control.styles.style"
                              [ngClass]="fields.weight.control.styles.classes"
                              [matTooltip]="fields.weight.control.tooltip"
                              matTooltipClass="datex-control-tooltip">{{fields.weight.control.text }}</div>
                        <ng-container *ngIf="fields.weight.invalid">
                          <ng-container *ngFor="let error of fields.weight.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.container_contents.hidden" class="tab" data-cy="tab-container_contents">
              <h2 [className]="tabs.container_contents.active? 'active': ''" (click)="tabs.container_contents.activate()">{{tabs.container_contents.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-pack_verification_shipping_container_contents_grid *ngIf="tabs.container_contents.active"
              #$tabs_container_contents
              [shippingContainerId]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_shippingContainerId"
              [canAddManually]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_canAddManually"
              [startVerificationTaskId]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_startVerificationTaskId"
              [isReadOnly]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_isReadOnly"
              [scannedValues]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_scannedValues"
              [isBlind]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_isBlind"
              [locationId]="$tabs_container_contents_pack_verification_shipping_container_contents_grid_inParams_locationId"
              ($refreshEvent)="refresh(false, false, '$tabs_container_contents')">
              </FootPrintManager-pack_verification_shipping_container_contents_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>