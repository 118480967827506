<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
        <app-toolbar [toolbar]="toolbar">
            <ng-template toolbarToolDef="confirm_button" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_confirm_click($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
            <ng-template toolbarToolDef="cancel_button" let-tool>
              <button mat-button
                      data-cy="button"
                      class="datex-button"
                      [disabled]="tool.control.readOnly"
                      [ngStyle]="tool.control.styles.style"
                      [ngClass]="tool.control.styles.classes"
                      [matTooltip]="tool.control.tooltip"
                      matTooltipClass="datex-control-tooltip"
                      (click)="on_cancel_click($event)"
                      >
                <div class="button-label">
                  <ng-container *ngIf="tool.control.icon === 'datex-default-spinner'; else showIcon">
                    <div class="button-icon">
                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                    </div>
                  </ng-container>
                  <ng-template #showIcon>
                    <div class="button-icon">
                      <i data-cy="button-icon" class="icon {{tool.control.icon}}"></i>
                    </div>
                  </ng-template>
                  <div data-cy="button-label" class="button-text">{{tool.control.label}}</div>
                </div>
              
              </button>
            </ng-template>
        </app-toolbar>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-equipmentDetail"
                     *ngIf="!fieldsets.equipmentDetail.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.equipmentDetail.collapsible }">
                    <div *ngIf="!fieldsets.equipmentDetail.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.equipmentDetail.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.equipmentDetail.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.equipmentDetail.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.equipmentDetail.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.equipmentDetail.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.equipmentDetail.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-equipmentType" *ngIf="!fields.equipmentType.hidden" 
                            class="field-container standard {{fields.equipmentType.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.equipmentType.styles.style"
                            [ngClass]="fields.equipmentType.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.equipmentType.label + (fields.equipmentType.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.equipmentType.label}}<span *ngIf="fields.equipmentType.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <Equipment-equipment_type_dd_single 
                            data-cy="selector"
                            [type]="fields.equipmentType.control.type"
                            formControlName="equipmentType"
                            (displayTextChange)="fields.equipmentType.control.displayText=$event"
                            [placeholder]="fields.equipmentType.control.placeholder"
                            [styles]="fields.equipmentType.control.styles"
                            [tooltip]="fields.equipmentType.control.tooltip"
                        >
                        </Equipment-equipment_type_dd_single>
                        <ng-container *ngIf="fields.equipmentType.invalid">
                          <ng-container *ngFor="let error of fields.equipmentType.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-equipmentName" *ngIf="!fields.equipmentName.hidden" 
                            class="field-container standard {{fields.equipmentName.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.equipmentName.styles.style"
                            [ngClass]="fields.equipmentName.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.equipmentName.label + (fields.equipmentName.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.equipmentName.label}}<span *ngIf="fields.equipmentName.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="equipmentName"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.equipmentName.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.equipmentName.control.placeholder}}"
                                [ngStyle]="fields.equipmentName.control.styles.style"
                                [ngClass]="fields.equipmentName.control.styles.classes"
                                [matTooltip]="fields.equipmentName.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.equipmentName.invalid">
                          <ng-container *ngFor="let error of fields.equipmentName.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-macAddress" *ngIf="!fields.macAddress.hidden" 
                            class="field-container standard {{fields.macAddress.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.macAddress.styles.style"
                            [ngClass]="fields.macAddress.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.macAddress.label + (fields.macAddress.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.macAddress.label}}<span *ngIf="fields.macAddress.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="textBox" formControlName="macAddress"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.macAddress.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.macAddress.control.placeholder}}"
                                [ngStyle]="fields.macAddress.control.styles.style"
                                [ngClass]="fields.macAddress.control.styles.classes"
                                [matTooltip]="fields.macAddress.control.tooltip"
                                matTooltipClass="datex-control-tooltip"> 
                        <ng-container *ngIf="fields.macAddress.invalid">
                          <ng-container *ngFor="let error of fields.macAddress.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
      </ng-container>

    </div>
  </div>
</div>