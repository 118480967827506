import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { WavelengthShellService } from 'wavelength-ui';
import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { CleanupLoggerService } from './cleanup.logging.service';
import { app_footprint_wms_homeComponent } from './app.footprint_wms_home.component';
import { app_custom_load_container_editorComponent } from './app.custom_load_container_editor.component';
import { app_custom_sales_order_editorComponent } from './app.custom_sales_order_editor.component';
import { app_custom_materials_udf_gridComponent } from './app.custom_materials_udf_grid.component';

import { Utilities_ShellService } from './Utilities.shell.service';
import { Addresses_ShellService } from './Addresses.shell.service';
import { Carriers_ShellService } from './Carriers.shell.service';
import { LoadContainers_ShellService } from './LoadContainers.shell.service';
import { AsnOrders_ShellService } from './AsnOrders.shell.service';
import { Owners_ShellService } from './Owners.shell.service';
import { Discussions_ShellService } from './Discussions.shell.service';
import { Document360_ShellService } from './Document360.shell.service';
import { Usersnap_ShellService } from './Usersnap.shell.service';
import { Notifications_ShellService } from './Notifications.shell.service';
import { Inventory_ShellService } from './Inventory.shell.service';
import { UccLabels_ShellService } from './UccLabels.shell.service';
import { DockAppointments_ShellService } from './DockAppointments.shell.service';
import { Invoices_ShellService } from './Invoices.shell.service';
import { SalesOrders_ShellService } from './SalesOrders.shell.service';
import { Materials_ShellService } from './Materials.shell.service';
import { Locations_ShellService } from './Locations.shell.service';
import { PurchaseOrders_ShellService } from './PurchaseOrders.shell.service';
import { Reports_ShellService } from './Reports.shell.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';

@Injectable({ providedIn: 'root' })
export class app_ShellService extends WavelengthShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
    public Addresses: Addresses_ShellService,
    public Carriers: Carriers_ShellService,
    public LoadContainers: LoadContainers_ShellService,
    public AsnOrders: AsnOrders_ShellService,
    public Owners: Owners_ShellService,
    public Discussions: Discussions_ShellService,
    public Document360: Document360_ShellService,
    public Usersnap: Usersnap_ShellService,
    public Notifications: Notifications_ShellService,
    public Inventory: Inventory_ShellService,
    public UccLabels: UccLabels_ShellService,
    public DockAppointments: DockAppointments_ShellService,
    public Invoices: Invoices_ShellService,
    public SalesOrders: SalesOrders_ShellService,
    public Materials: Materials_ShellService,
    public Locations: Locations_ShellService,
    public PurchaseOrders: PurchaseOrders_ShellService,
    public Reports: Reports_ShellService,
    public FootPrintManager: FootPrintManager_ShellService,
  ) {
    super(dialog, toastr);
  }

  public app: app_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openfootprint_wms_home(replaceCurrentView?: boolean) {
    this.logger.log('app', 'footprint_wms_home');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'footprint_wms_home',
        component: app_footprint_wms_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openfootprint_wms_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'footprint_wms_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_footprint_wms_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public opencustom_load_container_editor(inParams:{ loadContainerId: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_load_container_editor');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Load Container Editor',
        referenceName: 'custom_load_container_editor',
        component: app_custom_load_container_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_load_container_editorDialog(
    inParams:{ loadContainerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_load_container_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_load_container_editorComponent,
      'Load Container Editor',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_sales_order_editor(inParams:{ orderId: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_sales_order_editor');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Edit Sales Order',
        referenceName: 'custom_sales_order_editor',
        component: app_custom_sales_order_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_sales_order_editorDialog(
    inParams:{ orderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_sales_order_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_sales_order_editorComponent,
      'Edit Sales Order',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_materials_udf_grid(inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_materials_udf_grid');
    WavelengthShellService.openViewRequest$.next(
      {
        title: 'Materials advanced',
        referenceName: 'custom_materials_udf_grid',
        component: app_custom_materials_udf_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_materials_udf_gridDialog(
    inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_materials_udf_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_materials_udf_gridComponent,
      'Materials advanced',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public override getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'footprint_wms_home') {
      this.logger.log('app', 'footprint_wms_home');
      const title = 'Home';
      const component = app_footprint_wms_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_load_container_editor') {
      this.logger.log('app', 'custom_load_container_editor');
      const title = 'Load Container Editor';
      const component = app_custom_load_container_editorComponent;
      const inParams:{ loadContainerId: number } = { loadContainerId: null };
      if (!isNil(params.get('loadContainerId'))) {
        const paramValueString = params.get('loadContainerId');
        inParams.loadContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_sales_order_editor') {
      this.logger.log('app', 'custom_sales_order_editor');
      const title = 'Edit Sales Order';
      const component = app_custom_sales_order_editorComponent;
      const inParams:{ orderId: number } = { orderId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_materials_udf_grid') {
      this.logger.log('app', 'custom_materials_udf_grid');
      const title = 'Materials advanced';
      const component = app_custom_materials_udf_gridComponent;
      const inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] } = { projectId: null, ownerId: null, materialIds: [] };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }


    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Addresses.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Carriers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.LoadContainers.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.AsnOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Owners.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Discussions.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Document360.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Usersnap.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Notifications.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Inventory.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.UccLabels.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.DockAppointments.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Invoices.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.SalesOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Materials.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Locations.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PurchaseOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Reports.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.FootPrintManager.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
