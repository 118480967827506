import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface ISalesOrders_pick_slip_by_orderId_reportServiceInParams {
  orderId: number}

interface ISalesOrders_pick_slip_by_orderId_reportServiceData {
  Owner?: { result?: { Id?: number, Project?: { Id?: number, LookupCode?: string, Owner?: { Id?: number, LookupCode?: string, OwnersContactsLookup?: { ContactId?: number, Contact?: { Id?: number, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } } }
  ShipTo?: { result?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number } }
  PickSlipHeader?: { result?: { Id?: number, CreatedSysDateTime?: string, LookupCode?: string, RequestedDeliveryDate?: string, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { ExpectedDate?: string, FobLocation?: string, GrossWeight?: number, LookupCode?: string, NetWeight?: number, Notes?: string, ShippedDate?: string, Wave?: { Id?: number, PickSlips?: { Id?: number, LookupCode?: string }[], Warehouse?: { Name?: string } }, Carrier?: { Name?: string } }, Order?: { LookupCode?: string, Project?: { LookupCode?: string, Owner?: { LookupCode?: string } }, PreferredCarrier?: { Name?: string } } }[], Project?: { LookupCode?: string, Owner?: { LookupCode?: string } } }[] }
  PickSlipDetail?: { result?: { Id?: number, ExpectedPackagedAmount?: number, ExpectedPackagedPackId?: number, MaterialId?: number, OrderId?: number, Shipment?: { Id?: number, ExpectedDate?: string, FobLocation?: string, LookupCode?: string, ShippedDate?: string, Wave?: { Id?: number, PickSlips?: { LookupCode?: string }[], Warehouse?: { Name?: string } } }, ExpectedSourceLicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, ExpectedSourceLocation?: { Name?: string }, Material?: { Id?: number, Description?: string, LookupCode?: string }, PickSlip?: { LookupCode?: string }, Lot?: { LookupCode?: string }, DimensionsUOM?: { Name?: string, Short_name?: string }, OrderLine?: { Marks?: string, Notes?: string, Material?: { Id?: number, Description?: string, LookupCode?: string } }, Order?: { Id?: number, CreatedSysDateTime?: string, RequestedDeliveryDate?: string }, ExpectedPackagedPack?: { Name?: string, ShortName?: string }, MaterialWeights?: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingWeight?: number, VolumeUomId?: number, Weight?: number, WeightUomId?: number } }[] }
}

@Injectable({ providedIn: 'root' })
export class SalesOrders_pick_slip_by_orderId_reportService extends ReportBaseService<ISalesOrders_pick_slip_by_orderId_reportServiceInParams, ISalesOrders_pick_slip_by_orderId_reportServiceData> {

  protected reportReferenceName = 'custom_pick_slip_report';
  protected appReferenceName = 'Reports';

  constructor(
    utils: UtilsService,
    private datasources: Reports_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: ISalesOrders_pick_slip_by_orderId_reportServiceInParams) {
  }

  protected async getData(inParams: ISalesOrders_pick_slip_by_orderId_reportServiceInParams): Promise<ISalesOrders_pick_slip_by_orderId_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: ISalesOrders_pick_slip_by_orderId_reportServiceInParams } = {
      inParams: inParams
    };

    const data: ISalesOrders_pick_slip_by_orderId_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.Reports.custom_salesorders_ds_get_order_owner_contact_top1.get(dsParams);
      
      data.Owner = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.Reports.custom_salesorders_ds_get_order_ship_to_account_vs_order_address.get(dsParams);
      
      data.ShipTo = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.Reports.custom_salesorders_ds_outbound_header_pick_slip_report.get(dsParams);
      
      data.PickSlipHeader = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      
      const dsData = await this.datasources.Reports.custom_salesorders_ds_outbound_detail_pick_slip_report.get(dsParams);
      
      data.PickSlipDetail = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
