import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { Materials_material_statuses_dd_singleComponent } from './Materials.material_statuses_dd_single.component'
import { Materials_material_groups_dd_singleComponent } from './Materials.material_groups_dd_single.component'
import { Materials_allocation_strategy_workflows_dd_singleComponent } from './Materials.allocation_strategy_workflows_dd_single.component'
import { Materials_storage_category_rules_dd_singleComponent } from './Materials.storage_category_rules_dd_single.component'

type EntityType = { 
    Id?: number, AllocationStrategyWorkflowId?: string, AllowReceiving?: boolean, ControllerTypeId?: number, Description?: string, IsFixedLength?: boolean, IsFixedWeight?: boolean, LookupCode?: string, MaterialCatalogId?: number, MaterialCatalogSourceMaterialId?: number, MaterialGroupId?: number, MinimumDatingSpan?: number, MobileSkipCountBack?: boolean, Name?: string, ProjectId?: number, ScanSerialOfAllPackageLevels?: boolean, ShelfLifeSpan?: number, SkipCycleCount?: boolean, StatusId?: number, StorageCategoryRuleId?: number, StorageCategoryRule?: { Id?: number, Name?: string }, MaterialGroup?: { Id?: number, Name?: string }}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Materials_material_statuses_dd_singleComponent),
    forwardRef(() => Materials_material_groups_dd_singleComponent),
    forwardRef(() => Materials_allocation_strategy_workflows_dd_singleComponent),
    forwardRef(() => Materials_storage_category_rules_dd_singleComponent),
  ],
  selector: 'FootPrintManager-material_editor',
  templateUrl: './FootPrintManager.material_editor.component.html'
})
export class FootPrintManager_material_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { materialId: number } = { materialId: null };
  //#region Inputs
  @Input('materialId') set $inParams_materialId(v: number) {
    this.inParams.materialId = v;
  }
  get $inParams_materialId(): number {
    return this.inParams.materialId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    lookupcode: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    name: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    description: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    material_group: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    lot_controlled: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    serial_controlled: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    fixed_dimension: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    fixed_weight: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    allocation_strategy_workflow: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    storage_category_rule: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    shelf_life_span: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    minimum_dating_span: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    skip_cycle_count: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    skip_count_back: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    scan_serial_of_all_package_levels: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    allow_receiving: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', 'icon-ic_fluent_checkmark_circle_20_regular', null)
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', 'icon-ic_fluent_dismiss_circle_20_regular', null)
    )
  };

  fields = {
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Material code', false),
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Status', false),
    name: new FieldModel(new TextBoxModel(this.formGroup.controls['name'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Name', false),
    description: new FieldModel(new TextBoxModel(this.formGroup.controls['description'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Description', false),
    material_group: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['material_group'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Material group', false),
    lot_controlled: new FieldModel(new CheckBoxModel(this.formGroup.controls['lot_controlled'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Lot controlled', false),
    serial_controlled: new FieldModel(new CheckBoxModel(this.formGroup.controls['serial_controlled'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Serial controlled', false),
    fixed_dimension: new FieldModel(new CheckBoxModel(this.formGroup.controls['fixed_dimension'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Fixed dimension', false),
    fixed_weight: new FieldModel(new CheckBoxModel(this.formGroup.controls['fixed_weight'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Fixed weight', false),
    allocation_strategy_workflow: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['allocation_strategy_workflow'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Allocation strategy', true),
    storage_category_rule: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['storage_category_rule'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Storage category rule', false),
    shelf_life_span: new FieldModel(new NumberBoxModel(this.formGroup.controls['shelf_life_span'] as DatexFormControl, null, false, '0', '', null)
, new ControlContainerStyles(null, null), 'Shelf life', false),
    minimum_dating_span: new FieldModel(new NumberBoxModel(this.formGroup.controls['minimum_dating_span'] as DatexFormControl, null, false, '', '', null)
, new ControlContainerStyles(null, null), 'Minimum dating span', false),
    skip_cycle_count: new FieldModel(new CheckBoxModel(this.formGroup.controls['skip_cycle_count'] as DatexFormControl, null, false, 'Skip inventory count', null)
, new ControlContainerStyles(null, null), '', false),
    skip_count_back: new FieldModel(new CheckBoxModel(this.formGroup.controls['skip_count_back'] as DatexFormControl, null, false, 'Skip count back', null)
, new ControlContainerStyles(null, null), '', false),
    scan_serial_of_all_package_levels: new FieldModel(new CheckBoxModel(this.formGroup.controls['scan_serial_of_all_package_levels'] as DatexFormControl, null, false, 'Scan all packagings', null)
, new ControlContainerStyles(null, null), '', false),
    allow_receiving: new FieldModel(new CheckBoxModel(this.formGroup.controls['allow_receiving'] as DatexFormControl, null, false, 'Allow receiving', null)
, new ControlContainerStyles(null, null), '', false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true),
  newGroup2: new FieldsetModel('Details', false, true, false),
};


  //#region fields inParams
  get $fields_allocation_strategy_workflow_selector_inParams_is_active(): boolean {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = true;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lookupcode: this.fields.lookupcode.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    status: this.fields.status.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    name: this.fields.name.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    description: this.fields.description.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    material_group: this.fields.material_group.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    lot_controlled: this.fields.lot_controlled.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    serial_controlled: this.fields.serial_controlled.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    fixed_dimension: this.fields.fixed_dimension.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    fixed_weight: this.fields.fixed_weight.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    allocation_strategy_workflow: this.fields.allocation_strategy_workflow.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    storage_category_rule: this.fields.storage_category_rule.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    shelf_life_span: this.fields.shelf_life_span.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    minimum_dating_span: this.fields.minimum_dating_span.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    skip_cycle_count: this.fields.skip_cycle_count.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    skip_count_back: this.fields.skip_count_back.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    scan_serial_of_all_package_levels: this.fields.scan_serial_of_all_package_levels.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    allow_receiving: this.fields.allow_receiving.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.materialId)) {
        this.$missingRequiredInParams.push('materialId');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'Edit Material';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      materialId:  $editor.inParams.materialId 
    };

    const data = await this.datasources.Materials.ds_material_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    private async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   
    (this.fields.lookupcode.control as TextBoxModel).reset($editor.entity.LookupCode);
    (this.fields.status.control as SelectBoxModel).reset($editor.entity.StatusId);
    (this.fields.name.control as TextBoxModel).reset($editor.entity.Name);
    (this.fields.description.control as TextBoxModel).reset($editor.entity.Description);
    (this.fields.material_group.control as SelectBoxModel).reset(($editor.entity?.MaterialGroup?.Id ?? 0));
    (this.fields.lot_controlled.control as CheckBoxModel).reset($editor.entity.ControllerTypeId == 2 || $editor.entity.ControllerTypeId == 4 ? true : false);
    (this.fields.serial_controlled.control as CheckBoxModel).reset($editor.entity.ControllerTypeId == 3 || $editor.entity.ControllerTypeId == 4 ? true : false);
    (this.fields.fixed_dimension.control as CheckBoxModel).reset($editor.entity.IsFixedLength);
    (this.fields.fixed_weight.control as CheckBoxModel).reset($editor.entity.IsFixedWeight);
    (this.fields.allocation_strategy_workflow.control as SelectBoxModel).reset($editor.entity.AllocationStrategyWorkflowId);
    (this.fields.storage_category_rule.control as SelectBoxModel).reset($editor.entity?.StorageCategoryRule?.Id);
    (this.fields.shelf_life_span.control as NumberBoxModel).reset($editor.entity.ShelfLifeSpan);
    (this.fields.minimum_dating_span.control as NumberBoxModel).reset($editor.entity.MinimumDatingSpan);
    (this.fields.skip_cycle_count.control as CheckBoxModel).reset($editor.entity.SkipCycleCount);
    (this.fields.skip_count_back.control as CheckBoxModel).reset($editor.entity.MobileSkipCountBack);
    (this.fields.scan_serial_of_all_package_levels.control as CheckBoxModel).reset($editor.entity.ScanSerialOfAllPackageLevels);
    (this.fields.allow_receiving.control as CheckBoxModel).reset($editor.entity.AllowReceiving);

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .status
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .description
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .material_group
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .lot_controlled
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .serial_controlled
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .fixed_dimension
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .fixed_weight
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .allocation_strategy_workflow
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .storage_category_rule
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .shelf_life_span
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .minimum_dating_span
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .skip_cycle_count
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .skip_count_back
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .scan_serial_of_all_package_levels
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .allow_receiving
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $editor: FootPrintManager_material_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.outParams.confirm = false;
  $editor.close();
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $editor: FootPrintManager_material_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.toolbar.confirm.control.readOnly = true;
  let payload: any = {};
  let controllerTypeId;
  const currentControllerTypeId = $editor.entity.ControllerTypeId;
  
  // Check required fields
  if (!$utils.isDefined($editor.fields.allocation_strategy_workflow.control.value)) {
      $editor.toolbar.confirm.control.readOnly = false;
      $shell.Materials.openErrorDialog('Material Edit Error', 'Material currently contains inventory, cannot change lot or serial controlled options.');
      return;
  }
  
  const has_inventory = $utils.isDefined((await $datasources.Materials.ds_get_licenseplatecontents_by_materialId_top1.get({
      materialId: $editor.entity.Id
  })).result);
  
  
  // Check if inventory exists for changing fixed weight, dimensions properties
  if ($editor.fields.fixed_weight.control.isChanged
      || $editor.fields.fixed_dimension.control.isChanged
  ) {
      if (has_inventory) {
          $editor.toolbar.confirm.control.readOnly = false;
          $shell.Materials.openErrorDialog('Material Edit Error', 'Material currently contains inventory, cannot change Fixed weight, fixed dimension, fixed volume and or serial controlled options.');
          return;
      }
  
      payload.IsFixedWeight = $editor.fields.fixed_weight.control.value;
      payload.IsFixedLength = $editor.fields.fixed_dimension.control.value;
  }
  
  // Check if inventory exists for changing lot controlled option
  if ($editor.fields.lot_controlled.control.isChanged) {
      if (has_inventory) {
          $editor.toolbar.confirm.control.readOnly = false;
          $shell.Materials.openErrorDialog('Material Edit Error', 'Material currently contains inventory, cannot change lot controlled option.');
          return;
      }
  
      // Lots or LotsSerial
      if (currentControllerTypeId === 2 || currentControllerTypeId === 4) {
  
          // Get a list of current lots
          const lots = (await $datasources.Lots.ds_get_lots_by_materialId.get({ materialId: $editor.inParams.materialId })).result;
          if ($utils.isDefined(lots)) {
  
              const lot_count = lots.length;
              // If there is more than one lot found then we cannot change the lot to a glot 
              if (lot_count > 1) {
                  $editor.toolbar.confirm.control.readOnly = false;
                  $shell.Materials.openErrorDialog('Material Edit Error', 'Material currently contains more than one lot, cannot change lot controlled properties.');
                  return;
  
              }
  
              const lot_id = lots[0].Id;
              const lot_type_id = lots[0].TypeId;
              // Normal lot
              if (lot_type_id === 2) {
                  // Change the existing single lot from Normal to Glot
                  let lot_payload: any = {};
                  lot_payload.TypeId = 1;
                  lot_payload.LookupCode = `${$editor.entity.LookupCode}-Glot`
  
                  await $flows.Utilities.crud_update_flow({ entitySet: 'Lots', id: lot_id, entity: lot_payload });
              }
          }
          else {
              // Create the missing Glot
              // Create vendor lot and lot
              const payloadVendorLot = {
                  "TypeId": 1,
                  "MaterialId": $editor.inParams.materialId,
                  "VendorLot": {
                      "MaterialId": $editor.inParams.materialId,
                      "LookupCode": `${$editor.entity.LookupCode}-Glot`,
                      "ManufactureDate": $utils.date.now(),
                      "ExpirationDate": $utils.date.now()
                  },
                  "StatusId": 1,
                  "LookupCode": `${$editor.entity.LookupCode}-Glot`
              };
  
              await $flows.Utilities.crud_create_flow({ entitySet: 'Lots', entity: payloadVendorLot });
          }
      }
      // MaterialLot or MaterialLotSerial
      else {
  
          // Get a list of current lots
          const lots = (await $datasources.Lots.ds_get_lots_by_materialId.get({ materialId: $editor.inParams.materialId })).result;
          if ($utils.isDefined(lots)) {
  
              const lot_count = lots.length;
              // If there is more than one lot found then we cannot change the glot to a lot 
              if (lot_count > 1) {
                  $editor.toolbar.confirm.control.readOnly = false;
                  $shell.Materials.openErrorDialog('Material Edit Error', 'Material currently contains more than one lot, cannot change lot controlled properties.');
                  return;
  
              }
  
              const lot_id = lots[0].Id;
              const lot_type_id = lots[0].TypeId;
              // Glot
              if (lot_type_id === 1) {
                  // Change the existing single lot from Glot to Normal
                  let lot_payload: any = {};
                  lot_payload.TypeId = 2;
                  lot_payload.LookupCode = lot_id.toString();
  
                  await $flows.Utilities.crud_update_flow({ entitySet: 'Lots', id: lot_id, entity: lot_payload });
              }
          }
      }
  }
  
  if ($editor.fields.serial_controlled.control.isChanged || $editor.fields.lot_controlled.control.isChanged) {
      if (has_inventory) {
          $editor.toolbar.confirm.control.readOnly = false;
          $shell.Materials.openErrorDialog('Material Edit Error', 'Material currently contains inventory, cannot change lot or serial controlled options.');
          return;
      }
  
      payload.ControllerTypeId = get_new_controller_type();
  }
  
  
  if ($editor.fields.lookupcode.control.isChanged) {
  
      // Check for material duplicates
      const material = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: $editor.inParams.materialId })).result;
      if ($utils.isDefined(material)) {
          const projectId = material[0].ProjectId;
          const materials = (await $datasources.Materials.ds_get_material_by_lookupcode_and_projectId.get({
              lookupcode: $editor.fields.lookupcode.control.value,
              projectId: projectId
          })).result;
          if ($utils.isDefined(materials)) {
              $editor.toolbar.confirm.control.readOnly = false;
              $shell.Materials.openErrorDialog('Material Edit Error', 'Material lookupcode already exists.');
              return;
          }
  
          else {
              payload.LookupCode = $editor.fields.lookupcode.control.value;
          }
      }
  }
  
  
  if ($editor.fields.description.control.isChanged) {
      payload.Description = $editor.fields.description.control.value;
  }
  
  if ($editor.fields.name.control.isChanged) {
      payload.Name = $editor.fields.name.control.value;
  }
  
  if ($editor.fields.status.control.isChanged) {
      payload.StatusId = $editor.fields.status.control.value;
  
      await createStatusChangeTask();
  }
  
  if ($editor.fields.shelf_life_span.control.isChanged) {
      payload.ShelfLifeSpan = $editor.fields.shelf_life_span.control.value ?? 0;
  }
  if ($editor.fields.material_group.control.isChanged) {
      payload.MaterialGroupId = $editor.fields.material_group.control.value;
  }
  if ($editor.fields.storage_category_rule.control.isChanged) {
      payload.StorageCategoryRuleId = $editor.fields.storage_category_rule.control.value;
  }
  if ($editor.fields.minimum_dating_span.control.isChanged) {
      payload.MinimumDatingSpan = $editor.fields.minimum_dating_span.control.value ?? 0;
  }
  if ($editor.fields.skip_count_back.control.isChanged) {
      payload.MobileSkipCountBack = $editor.fields.skip_count_back.control.value ?? false;
  }
  if ($editor.fields.skip_cycle_count.control.isChanged) {
      payload.SkipCycleCount = $editor.fields.skip_cycle_count.control.value ?? false;
  }
  if ($editor.fields.allocation_strategy_workflow.control.isChanged) {
      payload.AllocationStrategyWorkflowId = $editor.fields.allocation_strategy_workflow.control.value;
  }
  
  if ($editor.fields.scan_serial_of_all_package_levels.control.isChanged) {
      payload.ScanSerialOfAllPackageLevels = $editor.fields.scan_serial_of_all_package_levels.control.value ?? false;
  }
  if ($editor.fields.allow_receiving.control.isChanged) {
      payload.AllowReceiving = $editor.fields.allow_receiving.control.value ?? true;
  }
  
  try {
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Materials', id: $editor.entity.Id, entity: payload });
      $editor.outParams.confirm = true;
      $editor.close();
  
  }
  
  catch (error) {
      $editor.toolbar.confirm.control.readOnly = false;
      $shell.Materials.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  
  }
  
  
  /*********************************************
   * FUNCTIONS
  **********************************************/
  async function createStatusChangeTask() {
      const currentStatus = (await $datasources.Materials.ds_get_status_by_statusId.get({ statusId: $editor.entity.StatusId })).result;
      var currentStatusName = currentStatus.Name;
  
      const updatedStatus = (await $datasources.Materials.ds_get_status_by_statusId.get({ statusId: $editor.fields.status.control.value })).result;
      var updatedStatusName = updatedStatus.Name;
  
      let warehouse = (await $datasources.Locations.ds_get_warehouse_top1.get({})).result;
  
      const employee = (await $flows.Utilities.get_username_flow({})).userName;
  
      const taskPayload = {
          "ChainHead": 0,
          "StatusId": 2,
          "WarehouseId": warehouse.Id,
          "OperationCodeId": 36,
          "ProjectId": $editor.entity.ProjectId,
          "MaterialId": $editor.entity.Id,
          "LotId": null,
          "VendorLotId": null,
          "Employee": employee,
          "CreatedSysDateTime": $utils.date.now(),
          "CompletedDateTime": $utils.date.now(),
          "ModifiedSysDateTime": $utils.date.now(),
          "Notes": `Status changed from [${currentStatusName}] to [${updatedStatusName}] on [${$utils.date.format($utils.date.now(), $settings.FootPrintManager.DateFormat + ', LT')}]`,
          "StartDateTime": $utils.date.now()
      };
  
      await $flows.Utilities.crud_create_flow({ entitySet: 'Tasks', entity: taskPayload });
  }
  
  function get_new_controller_type(): number {
      if ($editor.fields.lot_controlled.control.value) {
          if ($editor.fields.serial_controlled.control.value) {
              return 4; // Lot and serial controlled
          } else {
              return 2; // Lot controlled
          }
      } else {
          if ($editor.fields.serial_controlled.control.value) {
              return 3; // Serial controlled
          } else {
              return 1; // No control
          }
      }
  
      return 0;
  }
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_material_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $editor.outParams.confirm = false;
  $editor.toolbar.confirm.control.readOnly = true;
  
  const minVersionCheck = (await $flows.Utilities.check_footprint_version({ minimumVersion: '24.10.1' }));
  if (!minVersionCheck.meetsMinimumVersion) {
      $editor.fields.allocation_strategy_workflow.hidden = true;
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_material_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const inventory = (await $datasources.Materials.ds_get_licenseplatecontents_by_materialId_top1.get({
      materialId: $editor.entity.Id
  })).result;
  if ($utils.isDefined(inventory)) {
  
      $editor.fields.fixed_dimension.control.readOnly = true;
      $editor.fields.fixed_weight.control.readOnly = true;
      $editor.fields.serial_controlled.control.readOnly = true;
      $editor.fields.lot_controlled.control.readOnly = true;
  
  }
  
  // Lock down material editor if material is part of a catalog
  let versionCheck = await $flows.Utilities.check_footprint_version({ minimumVersion: '24.10.25' });
  
  if (versionCheck.meetsMinimumVersion) {
  
      // @ts-ignore 
      let source_catalog = ($utils.isDefined($editor.entity.MaterialCatalogSourceMaterialId) ?? false);
      if (source_catalog) {
          $editor.toolbar.confirm.control.readOnly = true;
          $editor.toolbar.confirm.control.label = 'Catalog subscription'
      }
  
  }
  }
  //#endregion private flows
  //#region validation flows
  
  validate_form(fieldErrors: { lookupcode: Pick<string[], 'push'>,status: Pick<string[], 'push'>,name: Pick<string[], 'push'>,description: Pick<string[], 'push'>,material_group: Pick<string[], 'push'>,lot_controlled: Pick<string[], 'push'>,serial_controlled: Pick<string[], 'push'>,fixed_dimension: Pick<string[], 'push'>,fixed_weight: Pick<string[], 'push'>,allocation_strategy_workflow: Pick<string[], 'push'>,storage_category_rule: Pick<string[], 'push'>,shelf_life_span: Pick<string[], 'push'>,minimum_dating_span: Pick<string[], 'push'>,skip_cycle_count: Pick<string[], 'push'>,skip_count_back: Pick<string[], 'push'>,scan_serial_of_all_package_levels: Pick<string[], 'push'>,allow_receiving: Pick<string[], 'push'>, } = null) {
    const emptyResult = { lookupcode:[],status:[],name:[],description:[],material_group:[],lot_controlled:[],serial_controlled:[],fixed_dimension:[],fixed_weight:[],allocation_strategy_workflow:[],storage_category_rule:[],shelf_life_span:[],minimum_dating_span:[],skip_cycle_count:[],skip_count_back:[],scan_serial_of_all_package_levels:[],allow_receiving:[], };
    if (!this.initialized){
      return Promise.resolve(emptyResult);
    }
    return this.validate_formInternal(
      this,
      { fieldErrors: fieldErrors ?? emptyResult },
      this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      );
  }
  async validate_formInternal(
    $editor: FootPrintManager_material_editorComponent,
    $validation:{
      fieldErrors: { lookupcode: Pick<string[], 'push'>,status: Pick<string[], 'push'>,name: Pick<string[], 'push'>,description: Pick<string[], 'push'>,material_group: Pick<string[], 'push'>,lot_controlled: Pick<string[], 'push'>,serial_controlled: Pick<string[], 'push'>,fixed_dimension: Pick<string[], 'push'>,fixed_weight: Pick<string[], 'push'>,allocation_strategy_workflow: Pick<string[], 'push'>,storage_category_rule: Pick<string[], 'push'>,shelf_life_span: Pick<string[], 'push'>,minimum_dating_span: Pick<string[], 'push'>,skip_cycle_count: Pick<string[], 'push'>,skip_count_back: Pick<string[], 'push'>,scan_serial_of_all_package_levels: Pick<string[], 'push'>,allow_receiving: Pick<string[], 'push'>, }
    },
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
  ) {
    // Check required fields
  let isValid = true;
  
  for (let key in $editor.fields) {
      if ($editor.fields.allocation_strategy_workflow.required && !$utils.isDefined($editor.fields.allocation_strategy_workflow.control.value)) {
          isValid = false;
      }
  }
  
  $editor.toolbar.confirm.control.readOnly = !isValid;
    return $validation.fieldErrors as { lookupcode:[],status:[],name:[],description:[],material_group:[],lot_controlled:[],serial_controlled:[],fixed_dimension:[],fixed_weight:[],allocation_strategy_workflow:[],storage_category_rule:[],shelf_life_span:[],minimum_dating_span:[],skip_cycle_count:[],skip_count_back:[],scan_serial_of_all_package_levels:[],allow_receiving:[], };
  }
  //#endregion validation flows
  
}
