<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams" class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.newGroup1.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-pallet_count" *ngIf="!fields.pallet_count.hidden" 
                            class="field-container standard {{fields.pallet_count.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.pallet_count.styles.style"
                            [ngClass]="fields.pallet_count.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.pallet_count.label + (fields.pallet_count.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.pallet_count.label}}<span *ngIf="fields.pallet_count.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="pallet_count"
                                matInput
                                numberBox
                                [format]="fields.pallet_count.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.pallet_count.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.pallet_count.control.placeholder}}"
                                [ngStyle]="fields.pallet_count.control.styles.style"
                                [ngClass]="fields.pallet_count.control.styles.classes"
                                [matTooltip]="fields.pallet_count.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.pallet_count.invalid">
                          <ng-container *ngFor="let error of fields.pallet_count.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-full_pallet_default" *ngIf="!fields.full_pallet_default.hidden" 
                            class="field-container standard {{fields.full_pallet_default.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.full_pallet_default.styles.style"
                            [ngClass]="fields.full_pallet_default.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.full_pallet_default.label + (fields.full_pallet_default.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.full_pallet_default.label}}<span *ngIf="fields.full_pallet_default.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <input data-cy="numberBox" formControlName="full_pallet_default"
                                matInput
                                numberBox
                                [format]="fields.full_pallet_default.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.full_pallet_default.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.full_pallet_default.control.placeholder}}"
                                [ngStyle]="fields.full_pallet_default.control.styles.style"
                                [ngClass]="fields.full_pallet_default.control.styles.classes"
                                [matTooltip]="fields.full_pallet_default.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        <ng-container *ngIf="fields.full_pallet_default.invalid">
                          <ng-container *ngFor="let error of fields.full_pallet_default.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>