import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { Inventory_reasoncodes_dd_singleComponent } from './Inventory.reasoncodes_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Inventory_reasoncodes_dd_singleComponent),
  ],
  selector: 'FootPrintManager-inventory_adjustment_form',
  templateUrl: './FootPrintManager.inventory_adjustment_form.component.html'
})
export class FootPrintManager_inventory_adjustment_formComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { licenseplateId: number, lotId: number, packagingId: number, packagedAmount: number, availablePackagedAmount: number, serialnumberId?: number } = { licenseplateId: null, lotId: null, packagingId: null, packagedAmount: null, availablePackagedAmount: null, serialnumberId: null };
  //#region Inputs
  @Input('licenseplateId') set $inParams_licenseplateId(v: number) {
    this.inParams.licenseplateId = v;
  }
  get $inParams_licenseplateId(): number {
    return this.inParams.licenseplateId;
  }
  @Input('lotId') set $inParams_lotId(v: number) {
    this.inParams.lotId = v;
  }
  get $inParams_lotId(): number {
    return this.inParams.lotId;
  }
  @Input('packagingId') set $inParams_packagingId(v: number) {
    this.inParams.packagingId = v;
  }
  get $inParams_packagingId(): number {
    return this.inParams.packagingId;
  }
  @Input('packagedAmount') set $inParams_packagedAmount(v: number) {
    this.inParams.packagedAmount = v;
  }
  get $inParams_packagedAmount(): number {
    return this.inParams.packagedAmount;
  }
  @Input('availablePackagedAmount') set $inParams_availablePackagedAmount(v: number) {
    this.inParams.availablePackagedAmount = v;
  }
  get $inParams_availablePackagedAmount(): number {
    return this.inParams.availablePackagedAmount;
  }
  @Input('serialnumberId') set $inParams_serialnumberId(v: number) {
    this.inParams.serialnumberId = v;
  }
  get $inParams_serialnumberId(): number {
    return this.inParams.serialnumberId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirm?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    updated_packaged_amount: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    reasoncode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', '', null)
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '', null)
    )
  };

  fields = {
    title: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), '', false),
    packaged_amount: new FieldModel(new TextModel(null, null, null, null, null)
, new ControlContainerStyles(null, null), 'Packaged amount', false),
    updated_packaged_amount: new FieldModel(new NumberBoxModel(this.formGroup.controls['updated_packaged_amount'] as DatexFormControl, null, false, '0', '', null)
, new ControlContainerStyles(null, null), 'Updated packaged amount', true),
    reasoncode: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['reasoncode'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Reason code', true),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true),
};

  //#region fields inParams
  get $fields_reasoncode_selector_inParams_parentId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_reasoncode_selector_inParams_parentEntity(): string {
    const $form = this;
    const $utils = this.utils;
    const expr = 'OperationType';
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    updated_packaged_amount: this.fields.updated_packaged_amount.control.valueChanges
    ,
    reasoncode: this.fields.reasoncode.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.licenseplateId)) {
        this.$missingRequiredInParams.push('licenseplateId');
      }
      if(isNil(this.inParams.lotId)) {
        this.$missingRequiredInParams.push('lotId');
      }
      if(isNil(this.inParams.packagingId)) {
        this.$missingRequiredInParams.push('packagingId');
      }
      if(isNil(this.inParams.packagedAmount)) {
        this.$missingRequiredInParams.push('packagedAmount');
      }
      if(isNil(this.inParams.availablePackagedAmount)) {
        this.$missingRequiredInParams.push('availablePackagedAmount');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Adjust Inventory';
  
    const $form = this;
    const $utils = this.utils;

    (this.fields.packaged_amount.control as TextModel).text = $form.inParams.packagedAmount.toString();

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .updated_packaged_amount
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .reasoncode
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootPrintManager_inventory_adjustment_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  $form.outParams.confirm = false;
  $form.close();
  
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_inventory_adjustment_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Handle double click scenario
  $form.toolbar.confirm.control.readOnly = true;
  
  
  // Check required fields
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.updated_packaged_amount.control.value,
      $form.fields.reasoncode.control.value
  );
  
  if (!allRequiredFieldHaveValue) {
      $form.toolbar.confirm.control.readOnly = false;
      $shell.FootPrintManager.openErrorDialog('Inventory Adjustment Error', 'Please enter in data in all required fields.');
      return;
  }
  
  //ensure updated packaged amount is a positive number
  if ($form.fields.updated_packaged_amount.control.value < 0) {
      throw new Error('Updated packed amount cannot be a negative amount');
  }
  if (isNaN(Number($form.fields.updated_packaged_amount.control.value))) {
      throw new Error('Updated packed amount must be a numeric value');
  }
  
  // Check validate available inventory setting
  const validateInventoryAvailabilityOnAdjustments = (await $operations.FootPrintManager.Enable_Inventory_AdjustmentsValidateAvailability.isAuthorized())
  
  if (validateInventoryAvailabilityOnAdjustments) {
      if ($form.inParams.availablePackagedAmount <= 0) {
          $form.toolbar.confirm.control.readOnly = false;
          throw new Error('Updated packed amount cannot be less than the available amount of ' + $form.inParams.availablePackagedAmount.toString());
  
      }
  
      if ($form.fields.updated_packaged_amount.control.value < ($form.inParams.packagedAmount - $form.inParams.availablePackagedAmount)) {
          $form.toolbar.confirm.control.readOnly = false;
          throw new Error('Updated packed amount cannot be less than the on hand amount minus the available amount of ' + $form.inParams.availablePackagedAmount.toString());
  
      }
  }
  
  const lot = (await $datasources.Lots.ds_get_lot_by_lotId.get({ lotId: $form.inParams.lotId })).result
  if ($utils.isDefined(lot)) {
      var materialId = lot[0].MaterialId;
  
      const fetchMaterialResult = await $datasources.Materials.ds_get_material_by_materialId.get({
          materialId: materialId
      });
      const materials = fetchMaterialResult.result;
  
      if ($utils.isDefined(materials)) {
  
          if (materials[0].ControllerTypeId == 3 || materials[0].ControllerTypeId == 4
              || materials[0].IsFixedWeight == false || materials[0].IsFixedVolume == false
              || materials[0].IsFixedLength == false) {
  
              // Check updated packaged amount entered and compared to on hand
              // If greater than open serial creation for delta
              if ($form.fields.updated_packaged_amount.control.value > $form.inParams.packagedAmount) {
                  const delta = $form.fields.updated_packaged_amount.control.value - $form.inParams.packagedAmount;
  
                  const dialogResult = await $shell.FootPrintManager.openserialnumbers_creation_gridDialog({
                      materialId: materialId,
                      packagingId: $form.inParams.packagingId,
                      packagedAmount: delta,
                  })
  
                  var serialnumbersResult = dialogResult.serialNumbers;
                  var confirmResult = dialogResult.confirm;
  
                  if (confirmResult == true && $utils.isDefined(serialnumbersResult)
                      && serialnumbersResult.length == delta) {
  
                      await $flows.Inventory.add_serials_inventory_on_existing_licenseplate_flow({
  
                          licenseplateId: $form.inParams.licenseplateId,
                          lotId: $form.inParams.lotId,
                          packagingId: $form.inParams.packagingId,
                          serialNumbers: serialnumbersResult,
                          reasoncodeId: $form.fields.reasoncode.control.value,
                          operationcodeId: 1 // adjust operation code
                      })
  
                      $form.outParams.confirm = true;
                      $form.close();
  
                  } else {
                      $form.toolbar.confirm.control.readOnly = false;
                      throw new Error('Error creating new serial numbers')
                  }
  
              } else {
  
                  // Specific serial number not provided, require user to select before adjusting
                  if (!$utils.isDefined($form.inParams.serialnumberId)) {
                      const delta = $form.inParams.packagedAmount - $form.fields.updated_packaged_amount.control.value;
  
                      const dialogResult = await $shell.FootPrintManager.openserialnumbers_selection_gridDialog({
                          lotId: $form.inParams.lotId,
                          licenseplateId: $form.inParams.licenseplateId,
                          packagingId: $form.inParams.packagingId,
                          packagedAmountRequest: delta,
                      })
  
                      var serialnumbersSelected = dialogResult.SerialNumberIds;
                      var confirmResult = dialogResult.confirm;
  
                      if (confirmResult == true && $utils.isDefined(serialnumbersSelected)
                          && serialnumbersSelected.length == delta) {
  
                          await $flows.Inventory.remove_serials_inventory_flow({
  
                              licenseplateId: $form.inParams.licenseplateId,
                              lotId: $form.inParams.lotId,
                              packagingId: $form.inParams.packagingId,
                              serialNumberIds: serialnumbersSelected,
                              reasoncodeId: $form.fields.reasoncode.control.value,
                              operationcodeId: 1 // adjust operation code
                          })
  
                          $form.outParams.confirm = true;
                          $form.close();
  
                      } else {
                          $form.toolbar.confirm.control.readOnly = false;
                          throw new Error('Error adjusting out serial numbers')
                      }
                  }
                  else {
                      // Adjust out specific serial number
                      await $flows.Inventory.remove_serials_inventory_flow({
  
                              licenseplateId: $form.inParams.licenseplateId,
                              lotId: $form.inParams.lotId,
                              packagingId: $form.inParams.packagingId,
                              serialNumberIds: [$form.inParams.serialnumberId],
                              reasoncodeId: $form.fields.reasoncode.control.value,
                              operationcodeId: 1 // adjust operation code
                          })
  
                          $form.outParams.confirm = true;
                          $form.close();
                  }
              }
  
          }
          else {
  
  
              await $flows.Inventory.set_inventory_on_existing_licenseplate_flow({
  
                  licenseplateId: $form.inParams.licenseplateId,
                  lotId: $form.inParams.lotId,
                  packagingId: $form.inParams.packagingId,
                  packagedAmount: $form.fields.updated_packaged_amount.control.value,
                  reasoncodeId: $form.fields.reasoncode.control.value,
                  operationcodeId: 1
              });
  
              $form.outParams.confirm = true;
              $form.close();
  
          }
  
      }
      else {
          $form.toolbar.confirm.control.readOnly = false;
          throw new Error('Unable to determine material')
      }
  
  
  
  
  }
  else {
      $form.toolbar.confirm.control.readOnly = false;
      throw new Error('Unable to determine lot');
  
  }
  
  
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_inventory_adjustment_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  
  var title = '';
  
  const licenseplates = (await $datasources.Inventory.ds_get_licenseplates_by_licenseplateIds.get({ licenseplateIds: [$form.inParams.licenseplateId] })).result;
  if ($utils.isDefined(licenseplates)) {
      const licenseplate = licenseplates[0].LookupCode.toUpperCase();
      const locationId = licenseplates[0].LocationId;
  
      const shipmentId = licenseplates[0].ShipmentId;
      const typeId = licenseplates[0].TypeId;
  
      if ($utils.isDefined(shipmentId)) {
          $form.toolbar.confirm.control.readOnly = true;
          await $shell.FootPrintManager.openInfoDialog('Adjustment request', 'Selected licenseplate cannot be adjusted as it is associated to a outbound shipment.')
          $form.close();
      }
  
      // Only allow adjustments for inventory and loose licenseplates
      if ($utils.isDefined(typeId) && ![1, 3].includes(typeId)) {
          $form.toolbar.confirm.control.readOnly = true;
          await $shell.FootPrintManager.openInfoDialog('Adjustment request', 'Selected licenseplate is not of type inventory and cannot be adjusted.')
          $form.close();
      }
  
      const locations = (await $datasources.Locations.ds_get_location_by_locationId.get({ locationId: locationId })).result;
      if ($utils.isDefined(locations)) {
          const locationName = locations[0].Name.toUpperCase();
  
          const lots = (await $datasources.Lots.ds_get_lot_by_lotId.get({ lotId: $form.inParams.lotId })).result;
          if ($utils.isDefined(lots)) {
              const lotLookupcode = lots[0].LookupCode.toUpperCase();
              const materialId = lots[0].MaterialId;
  
              const materials = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: materialId })).result;
              if ($utils.isDefined(materials)) {
  
                  // Lock down creation of inventory if the material requires multi level scanning
                  const scanSerialsAllPackages = materials[0].ScanSerialOfAllPackageLevels;
                  if (scanSerialsAllPackages) {
  
                      $form.toolbar.confirm.control.readOnly = true;
                      await $shell.FootPrintManager.openInfoDialog('Adjustment request', 'Selected material cannot be adjusted as it requires serial scanning across all of the package levels.')
                      $form.close();
                  }
                  else {
                      const materialLookupcode = materials[0].LookupCode.toUpperCase();
  
                      const packagings = (await $datasources.Materials.ds_get_material_inventory_measurements_by_unitId.get({ unitId: $form.inParams.packagingId })).result;
                      if ($utils.isDefined(packagings)) {
                          const packagingShortName = packagings[0].ShortName.toUpperCase();
                          title = `Adjusting inventory in Location ${locationName} for Licenseplate ${licenseplate} Material ${materialLookupcode} Lot ${lotLookupcode} Packaging ${packagingShortName}`;
  
                      }
  
                  }
              }
          }
      }
  }
  
  // If a single serial number is provided lock down the updated amount and default it to 0
  if ($utils.isDefined($form.inParams.serialnumberId)) {
  
      $form.fields.updated_packaged_amount.control.readOnly = true;
      $form.fields.updated_packaged_amount.control.value = 0;
  }
  
  // Set title field
  $form.title = title;
  
  // Set focus on updated packaged amount field
  $form.fields.updated_packaged_amount.control.focus();
  }
  //#endregion private flows
}
