<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams" class="blade-tools">
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-header"
                     *ngIf="!fieldsets.header.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.header.collapsible }">
                    <div *ngIf="!fieldsets.header.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.header.toggle()">
                      <span class="fieldsetsTitle-text">
                      {{fieldsets.header.label}}
                      </span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.header.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.header.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.header.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.header.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-image" *ngIf="!fields.image.hidden" 
                            class="field-container standard {{fields.image.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.image.styles.style"
                            [ngClass]="fields.image.styles.classes">
                        <div class="label-container"
                             [matTooltip]="fields.image.label + (fields.image.required ? ' (required)' : '')"
                             matTooltipClass="datex-control-tooltip">
                          <label data-cy="field-label" class="datex-label">{{fields.image.label}}<span *ngIf="fields.image.required" class="required-asterisk">*</span>
                          </label>
                        </div>
                        <a (click)="openImageViewer(fields.image.control.src)">
                        <img [src]="fields.image.control.src"
                                data-cy="image" class="datex-image"
                                [ngStyle]="fields.image.control.styles.style"
                                [ngClass]="fields.image.control.styles.classes"
                                onerror="this.style.display='none'"
                                onload="this.style.display='inline'"
                                [matTooltip]="fields.image.control.tooltip"
                                matTooltipClass="datex-control-tooltip">
                        </a>
                        <ng-container *ngIf="fields.image.invalid">
                          <ng-container *ngFor="let error of fields.image.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>