import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { BarTender_ShellService } from './BarTender.shell.service';
import { BarTender_OperationService } from './BarTender.operation.service';
import { BarTender_DatasourceService } from './BarTender.datasource.index';
import { BarTender_FlowService } from './BarTender.flow.index';
import { BarTender_ReportService } from './BarTender.report.index';
import { BarTender_LocalizationService } from './BarTender.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './BarTender.frontend.types'
import { $frontendTypes as $types} from './BarTender.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';

import { BarTender_reflect_strings_dd_multiComponent } from './BarTender.reflect_strings_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => BarTender_reflect_strings_dd_multiComponent),
  ],
  selector: 'BarTender-configurations_form',
  templateUrl: './BarTender.configurations_form.component.html'
})
export class BarTender_configurations_formComponent extends BaseComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { is_confirmed?: boolean } = { is_confirmed: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { printers?: string[] } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ is_confirmed?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    azure_blob_connection_string: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    azure_blob_sas_token: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    azure_blob_root_path: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    printers: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), true, 'Update', '', null)
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '', null)
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      delete_all_configs: new ToolModel(new ButtonModel('delete_all_configs', new ButtonStyles(['destructive'], null), false, 'Reset configs', 'icon-ic_fluent_arrow_reset_20_regular', null)
    )
  };

  fields = {
    azure_blob_connection_string: new FieldModel(new TextBoxModel(this.formGroup.controls['azure_blob_connection_string'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Azure blob connection string', true),
    azure_blob_sas_token: new FieldModel(new TextBoxModel(this.formGroup.controls['azure_blob_sas_token'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Azure blob SAS token', false),
    azure_blob_root_path: new FieldModel(new TextBoxModel(this.formGroup.controls['azure_blob_root_path'] as DatexFormControl, null, false, '', null)
, new ControlContainerStyles(null, null), 'Azure blob root path', false),
    delete_selected_printers: new FieldModel(new ButtonModel('', new ButtonStyles(['destructive'], null), true, 'Delete selected', 'icon-ic_fluent_delete_20_regular', null)
, new ControlContainerStyles(null, null), '', false),
    printers: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['printers'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Printers', false),
  };

  fieldsets = {
  azure_blob_storage: new FieldsetModel('Azure blob storage', true, false, true),
  printers: new FieldsetModel('Printers', false, false, true),
};

  //#region fields inParams
  get $fields_printers_selector_inParams_values(): string {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.vars.printers.join(',');
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    azure_blob_connection_string: this.fields.azure_blob_connection_string.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    azure_blob_sas_token: this.fields.azure_blob_sas_token.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    azure_blob_root_path: this.fields.azure_blob_root_path.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
    printers: this.fields.printers.control.valueChanges
    .pipe(mergeMap(() => validateFormOnControlChange(this.formGroup, async () => {
    let data: any = null;
    return  await this.validate_form(data);
  }
  ))),
  }
  

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: BarTender_ShellService,
private datasources: BarTender_DatasourceService,
private flows: BarTender_FlowService,
private reports: BarTender_ReportService,
private localization: BarTender_LocalizationService,
private operations: BarTender_OperationService,
private logger: CleanupLoggerService,
) { 
    super();
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'BarTender configurations';
  
    const $form = this;
    const $utils = this.utils;

    
    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .azure_blob_connection_string
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .azure_blob_sas_token
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .azure_blob_root_path
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .printers
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_printers_value_changed();
      });
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: BarTender_configurations_formComponent,
  
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.logger.log('BarTender', 'configurations_form.on_init');
  $form.outParams.is_confirmed = false;
  
  await $flows.BarTender.initialize_configurations({});
  
  await $form.load_form_data({isInit: true});
  
  
  }
  load_form_data(event = null) {
    return this.load_form_dataInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async load_form_dataInternal(
    $form: BarTender_configurations_formComponent,
  
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.logger.log('BarTender', 'configurations_form.load_form_data');
  const isInit = $event?.isInit ?? false;
  
  // Get all BarTender configurations
  const response = await $datasources.BarTender.ds_get_configurations.getList({});
  
  // Capture records 
  const azureBlobConnectionString = response.result.find(r => r.Name === $types.BarTender.ConfigurationsEnum.AzureBlobConnectionString);
  const azureBlobSasToken = response.result.find(r => r.Name === $types.BarTender.ConfigurationsEnum.AzureBlobSasToken);
  const azureBlobRootPath = response.result.find(r => r.Name === $types.BarTender.ConfigurationsEnum.AzureBlobRootPath);
  const azureBlobPrinters = response.result.find(r => r.Name === $types.BarTender.ConfigurationsEnum.Printers);
  
  // Load data into fields on the form
  $form.fields.azure_blob_connection_string.control.value = azureBlobConnectionString?.Value;
  $form.fields.azure_blob_sas_token.control.value = azureBlobSasToken?.Value;
  $form.fields.azure_blob_root_path.control.value = azureBlobRootPath.Value;
  $form.vars.printers = azureBlobPrinters.Value?.split(',');
  
  
  if (isInit) {
      $form.fields.azure_blob_connection_string['entity'] = azureBlobConnectionString;
      $form.fields.azure_blob_sas_token['entity'] = azureBlobSasToken;
      $form.fields.azure_blob_root_path['entity'] = azureBlobRootPath;
      $form.fields.printers['entity'] = azureBlobPrinters;
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: BarTender_configurations_formComponent,
  
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.logger.log('BarTender', 'configurations_form.on_cancel_clicked');
  $form.close();
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: BarTender_configurations_formComponent,
  
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.logger.log('BarTender', 'configurations_form.on_confirm_clicked');
  // Update configurations
  if ($form.fields.azure_blob_connection_string.control.value !== $form.fields.azure_blob_connection_string['entity']?.Value) {
      update_config($form.fields.azure_blob_connection_string['entity']?.Id, $form.fields.azure_blob_connection_string.control.value)
  }
  
  if ($form.fields.azure_blob_sas_token.control.value !== $form.fields.azure_blob_sas_token['entity']?.Value) {
      update_config($form.fields.azure_blob_sas_token['entity']?.Id, $form.fields.azure_blob_sas_token.control.value)
  }
  
  if ($form.fields.azure_blob_root_path.control.value !== $form.fields.azure_blob_root_path['entity']?.Value) {
      update_config($form.fields.azure_blob_root_path['entity']?.Id, $form.fields.azure_blob_root_path.control.value)
  }
  
  if ($form.fields.printers.control.value !== $form.fields.printers['entity']?.Value) {
      update_config($form.fields.printers['entity']?.Id, $form.vars.printers.join(','));
  }
  $shell.BarTender.openToaster('Configurations have been updated', null, EToasterType.Success, { timeOut: 5000, positionClass: EToasterPosition.topRight });
  $form.close();
  
  
  
  /******************************
   * FUNCTIONS
  *******************************/
  async function update_config(config_id: string, config_value: string) {
      $form.outParams.is_confirmed = true;
  
      if (!$utils.isDefinedTrimmed(config_value)) { config_value = ' '; }
  
      const result = await $flows.Utilities.configurations_update({
          input: [{ configuration_id: config_id, value: config_value }],
          criteria: {
              application_name: 'BARTENDER'
          }
      });
  
      if (!result.success) { throw new Error(result.error.map(e => typeof e === 'string' ? e : JSON.stringify(e)).join(', ')); }
  }
  }
  delete_all_clicked(event = null) {
    return this.delete_all_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async delete_all_clickedInternal(
    $form: BarTender_configurations_formComponent,
  
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.logger.log('BarTender', 'configurations_form.delete_all_clicked');
  const isConfirmed = await $shell.BarTender.openConfirmationDialog('Reset all configurations?', 'All current configurations will be lost', 'Reset configs', 'Cancel');
  if (!isConfirmed) { return; }
  
  // Get all configurations
  let configs = (await $datasources.BarTender.ds_get_configurations.getList({})).result;
  
  // Escape if no configs found
  if (configs.length === 0) { return; }
  
  // Delete configurations
  await $flows.Utilities.configurations_delete({
      input: configs.map(config => ({ configuration_id: config.Id })),
      criteria: {
          application_name: 'BARTENDER'
      }
  })
  
  $form.on_init();
  
  $shell.BarTender.openToaster('Configurations have been reset', null, EToasterType.Success, { timeOut: 5000, positionClass: EToasterPosition.topRight });
  }
  on_delete_selected_clicked(event = null) {
    return this.on_delete_selected_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_selected_clickedInternal(
    $form: BarTender_configurations_formComponent,
  
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.logger.log('BarTender', 'configurations_form.on_delete_selected_clicked');
  if (!$utils.isDefined($form.fields.printers.control.value)) { return; }
  
  await new Promise(result => setTimeout(result, 100)); 
  
  $form.vars.printers = $form.vars.printers.filter(p => !$form.fields.printers.control.value.includes(p));
  $form.fields.printers.control.value = null;
  }
  on_printers_value_changed(event = null) {
    return this.on_printers_value_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_printers_value_changedInternal(
    $form: BarTender_configurations_formComponent,
  
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
    $event: any
  ) {
    this.logger.log('BarTender', 'configurations_form.on_printers_value_changed');
  if ($utils.isDefined($form.fields.printers.control.value)) {
      $form.vars.printers = [...new Set([...$form.vars.printers, ...$form.fields.printers.control.value])].filter(p => $utils.isDefinedTrimmed(p));
      $form.fields.delete_selected_printers.control.readOnly = false;
  } else {
      $form.fields.delete_selected_printers.control.readOnly = true;
  }
  }
  //#endregion private flows
  //#region validation flows
  
  validate_form(fieldErrors: { azure_blob_connection_string: Pick<string[], 'push'>,azure_blob_sas_token: Pick<string[], 'push'>,azure_blob_root_path: Pick<string[], 'push'>,printers: Pick<string[], 'push'>, } = null) {
    const emptyResult = { azure_blob_connection_string:[],azure_blob_sas_token:[],azure_blob_root_path:[],printers:[], };
    if (!this.initialized){
      return Promise.resolve(emptyResult);
    }
    return this.validate_formInternal(
      this,
      { fieldErrors: fieldErrors ?? emptyResult },
      this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization
      );
  }
  async validate_formInternal(
    $form: BarTender_configurations_formComponent,
    $validation:{
      fieldErrors: { azure_blob_connection_string: Pick<string[], 'push'>,azure_blob_sas_token: Pick<string[], 'push'>,azure_blob_root_path: Pick<string[], 'push'>,printers: Pick<string[], 'push'>, }
    },
    $shell: BarTender_ShellService,
    $datasources: BarTender_DatasourceService,
    $flows: BarTender_FlowService,
    $reports: BarTender_ReportService,
    $settings: SettingsValuesService,
    $operations: BarTender_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: BarTender_LocalizationService,
  ) {
    this.logger.log('BarTender', 'configurations_form.validate_form');
    let allowConfirm = true;
  let hasChangedValue = false;
  
  for (let key in $form.fields) {
      // Check all required fields have a value
      if ($form.fields[key].required && !$utils.isDefined($form.fields[key].control.value)) {
          allowConfirm = false;
          break;
      }
  
      // Check if any fields have changed
  
      if ($form.fields[key].control.value !== $form.fields[key]['entity']?.Value) {
          hasChangedValue = true;
      }
  
  }
  if (!hasChangedValue) {
      allowConfirm = false;
  }
  
  
  $form.toolbar.confirm.control.readOnly = !allowConfirm;
    return $validation.fieldErrors as { azure_blob_connection_string:[],azure_blob_sas_token:[],azure_blob_root_path:[],printers:[], };
  }
  //#endregion validation flows
}
