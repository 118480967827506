import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { Addresses_DatasourceService } from './Addresses.datasource.index';
import { Carriers_DatasourceService } from './Carriers.datasource.index';
import { LoadContainers_DatasourceService } from './LoadContainers.datasource.index';
import { AsnOrders_DatasourceService } from './AsnOrders.datasource.index';
import { Owners_DatasourceService } from './Owners.datasource.index';
import { Discussions_DatasourceService } from './Discussions.datasource.index';
import { Document360_DatasourceService } from './Document360.datasource.index';
import { Usersnap_DatasourceService } from './Usersnap.datasource.index';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Inventory_DatasourceService } from './Inventory.datasource.index';
import { UccLabels_DatasourceService } from './UccLabels.datasource.index';
import { DockAppointments_DatasourceService } from './DockAppointments.datasource.index';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { SalesOrders_DatasourceService } from './SalesOrders.datasource.index';
import { Materials_DatasourceService } from './Materials.datasource.index';
import { Locations_DatasourceService } from './Locations.datasource.index';
import { PurchaseOrders_DatasourceService } from './PurchaseOrders.datasource.index';
import { Reports_DatasourceService } from './Reports.datasource.index';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';

import { app_custom_ds_load_container_editorService } from './app.datasource.index';
import { app_custom_ds_materials_udf_gridService } from './app.datasource.index';
import { app_ds_get_inventory_by_ownerService } from './app.datasource.index';
import { app_ds_inventory_by_licenseplate_totalService } from './app.datasource.index';

@Injectable({ providedIn: 'root' })
export class app_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.Addresses = this.injector.get(Addresses_DatasourceService);
    this.Carriers = this.injector.get(Carriers_DatasourceService);
    this.LoadContainers = this.injector.get(LoadContainers_DatasourceService);
    this.AsnOrders = this.injector.get(AsnOrders_DatasourceService);
    this.Owners = this.injector.get(Owners_DatasourceService);
    this.Discussions = this.injector.get(Discussions_DatasourceService);
    this.Document360 = this.injector.get(Document360_DatasourceService);
    this.Usersnap = this.injector.get(Usersnap_DatasourceService);
    this.Notifications = this.injector.get(Notifications_DatasourceService);
    this.Inventory = this.injector.get(Inventory_DatasourceService);
    this.UccLabels = this.injector.get(UccLabels_DatasourceService);
    this.DockAppointments = this.injector.get(DockAppointments_DatasourceService);
    this.Invoices = this.injector.get(Invoices_DatasourceService);
    this.SalesOrders = this.injector.get(SalesOrders_DatasourceService);
    this.Materials = this.injector.get(Materials_DatasourceService);
    this.Locations = this.injector.get(Locations_DatasourceService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_DatasourceService);
    this.Reports = this.injector.get(Reports_DatasourceService);
    this.FootPrintManager = this.injector.get(FootPrintManager_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
    public Addresses: Addresses_DatasourceService;
    public Carriers: Carriers_DatasourceService;
    public LoadContainers: LoadContainers_DatasourceService;
    public AsnOrders: AsnOrders_DatasourceService;
    public Owners: Owners_DatasourceService;
    public Discussions: Discussions_DatasourceService;
    public Document360: Document360_DatasourceService;
    public Usersnap: Usersnap_DatasourceService;
    public Notifications: Notifications_DatasourceService;
    public Inventory: Inventory_DatasourceService;
    public UccLabels: UccLabels_DatasourceService;
    public DockAppointments: DockAppointments_DatasourceService;
    public Invoices: Invoices_DatasourceService;
    public SalesOrders: SalesOrders_DatasourceService;
    public Materials: Materials_DatasourceService;
    public Locations: Locations_DatasourceService;
    public PurchaseOrders: PurchaseOrders_DatasourceService;
    public Reports: Reports_DatasourceService;
    public FootPrintManager: FootPrintManager_DatasourceService;
  public app: app_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_ds_load_container_editor: app_custom_ds_load_container_editorService;
  public get custom_ds_load_container_editor(): app_custom_ds_load_container_editorService {
    if(!this._custom_ds_load_container_editor) {
      this._custom_ds_load_container_editor = this.injector.get(app_custom_ds_load_container_editorService);
    }
    return this._custom_ds_load_container_editor;
  }
  private _custom_ds_materials_udf_grid: app_custom_ds_materials_udf_gridService;
  public get custom_ds_materials_udf_grid(): app_custom_ds_materials_udf_gridService {
    if(!this._custom_ds_materials_udf_grid) {
      this._custom_ds_materials_udf_grid = this.injector.get(app_custom_ds_materials_udf_gridService);
    }
    return this._custom_ds_materials_udf_grid;
  }
  private _ds_get_inventory_by_owner: app_ds_get_inventory_by_ownerService;
  public get ds_get_inventory_by_owner(): app_ds_get_inventory_by_ownerService {
    if(!this._ds_get_inventory_by_owner) {
      this._ds_get_inventory_by_owner = this.injector.get(app_ds_get_inventory_by_ownerService);
    }
    return this._ds_get_inventory_by_owner;
  }
  private _ds_inventory_by_licenseplate_total: app_ds_inventory_by_licenseplate_totalService;
  public get ds_inventory_by_licenseplate_total(): app_ds_inventory_by_licenseplate_totalService {
    if(!this._ds_inventory_by_licenseplate_total) {
      this._ds_inventory_by_licenseplate_total = this.injector.get(app_ds_inventory_by_licenseplate_totalService);
    }
    return this._ds_inventory_by_licenseplate_total;
  }
}

