import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_outbound_bol_get_pallet_detailsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { shipmentId: number, palletClassName: string }): 
  Promise<{ result: { Amount?: number, PalletClassName?: string, PalletClassId?: number } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.shipmentId)) {
      missingRequiredInParams.push('\'shipmentId\'');
    }
    if (isNil(inParams.palletClassName)) {
      missingRequiredInParams.push('\'palletClassName\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_outbound_bol_get_pallet_details/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }


    
    hasMissingRequiredInParams(inParams: { 
      shipmentId: number, palletClassName: string    }) {
        if(isNil(inParams.shipmentId)) {
          return true; 
        }
        if(isNil(inParams.palletClassName)) {
          return true; 
        }
      return false;
    }
}
