import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  FormControl,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';
import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';


import { FootPrintManager_material_components_gridComponent } from './FootPrintManager.material_components_grid.component';
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'

type EntityType = { 
    Id?: number, Description?: string, LookupCode?: string}; 

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_material_components_gridComponent),
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
  ],
  selector: 'FootPrintManager-material_kit_editor',
  templateUrl: './FootPrintManager.material_kit_editor.component.html'
})
export class FootPrintManager_material_kit_editorComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  inParams: { material_id: number, project_id: number, packaging_id?: number } = { material_id: null, project_id: null, packaging_id: null };
  //#region Inputs
  @Input('material_id') set $inParams_material_id(v: number) {
    this.inParams.material_id = v;
  }
  get $inParams_material_id(): number {
    return this.inParams.material_id;
  }
  @Input('project_id') set $inParams_project_id(v: number) {
    this.inParams.project_id = v;
  }
  get $inParams_project_id(): number {
    return this.inParams.project_id;
  }
  @Input('packaging_id') set $inParams_packaging_id(v: number) {
    this.inParams.packaging_id = v;
  }
  get $inParams_packaging_id(): number {
    return this.inParams.packaging_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  entity: EntityType;

  formGroup: FormGroup = new FormGroup({
    material_packaging: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  get valid(): boolean {
    return this.formGroup.valid;
  }

  toolbar = {
  };

  fields = {
    material_packaging: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['material_packaging'] as DatexFormControl, 
  null, null,
  false, 
  '', null)
, new ControlContainerStyles(null, null), 'Material packaging', false),
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Label of newGroup1', true, false, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      material_components: new TabItemModel(
        this.rootTabGroup, 
        'Kit components', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_material_components_material_components_grid_inParams_material_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.material_id;
      
      return expr;
    }
  
    get $tabs_material_components_material_components_grid_inParams_packaging_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.fields.material_packaging.control.value ?? $editor.inParams.packaging_id;
      
      return expr;
    }
  
    get $tabs_material_components_material_components_grid_inParams_project_id(): number {
      const $editor = this;
      const $utils = this.utils;
      const expr = $editor.inParams.project_id;
      
      return expr;
    }
  
    get $tabs_material_components_material_components_grid_inParams_suppress_new_row(): boolean {
      const $editor = this;
      const $utils = this.utils;
      const expr = false;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_material_components', { read: FootPrintManager_material_components_gridComponent }) $tabs_material_components: FootPrintManager_material_components_gridComponent;
    //#endregion tabs children

  //#region fields inParams
  get $fields_material_packaging_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $editor = this;
    const $utils = this.utils;
    const expr = $editor.inParams.material_id;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    material_packaging: this.fields.material_packaging.control.valueChanges
    ,
  }
  

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) { 
    super();
    this.$subscribeFormControlValueChanges();
    
    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.material_components,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if (!this.$hasMissingRequiredInParams) {
      this.$init();
    } else {
      this.$initEmpty();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      } else {
        this.$initEmpty();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.material_id)) {
        this.$missingRequiredInParams.push('material_id');
      }
      if(isNil(this.inParams.project_id)) {
        this.$missingRequiredInParams.push('project_id');
      }
  }

  initialized = false;

  $hasDataLoaded = false;

  async $init() {
    this.title = 'material_kit_editor';
    
    await this.on_init();

    await this.$dataLoad();
    this.initialized = true;
  }

  async $dataLoad() {
    const $editor = this;
    const $utils = this.utils;

    const dsParams = {
      material_id:  $editor.inParams.material_id 
    };

    const data = await this.datasources.FootPrintManager.ds_material_kit_editor.get(dsParams);

    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;

      await this.$applyLinkedDatasourcesAndCustomColumns(dsParams, data);
      
      this.entity = data.result as EntityType;

      await this.$dataLoaded();
    }
  }

  
    private async $applyLinkedDatasourcesAndCustomColumns(inParams: any, outParams: any) {
      const $datasource = { inParams: inParams };
      const $utils = this.utils;
  
    }

  async $dataLoaded() {
    const $editor = this;
    const $utils = this.utils;
   

    await this.on_data_loaded();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
      if (childToSkip !== '$tabs_material_components') {
        if (!isNil(this.$tabs_material_components) && !this.tabs.material_components.hidden) {
          this.$tabs_material_components.refresh(true, false, null);
        }
      }    
    //#endregion tabs children
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
  
  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .material_packaging
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  //#region private flows
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $editor: FootPrintManager_material_kit_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const lookupcode = $editor.entity.LookupCode;
  const description = $editor.entity.Description;
  let package_name = '';
  
  const packaging_id = $editor.inParams.packaging_id;
  if ($utils.isDefined(packaging_id)){
  
  package_name = (await $datasources.Materials.ds_get_material_inventory_measurements_by_unitId.get({
      unitId: packaging_id
  })).result[0].ShortName;
  
  package_name = `(${package_name})`
  
  }
  
  $editor.title = `Kit for ${lookupcode} - ${description} ${package_name}`;
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $editor: FootPrintManager_material_kit_editorComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const packaging = $editor.inParams.packaging_id;
  
  if ($utils.isDefined(packaging)) {
  
      $editor.fields.material_packaging.hidden = true;
  }
  }
  //#endregion private flows
  
}
