import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './FootPrintManager.frontend.types'
import { $frontendTypes as $types} from './FootPrintManager.frontend.types' 

import { EModalSize, EToasterType, EToasterPosition } from 'wavelength-ui';



interface IFootPrintManager_materials_navigational_gridComponentEntity {
Id?: number, ControllerTypeId?: number, Description?: string, IsFixedLength?: boolean, IsFixedVolume?: boolean, IsFixedWeight?: boolean, LookupCode?: string, Name?: string, ProjectId?: number, Project?: { LookupCode?: string, Name?: string, Owner?: { LookupCode?: string, Name?: string } }, PrimaryPickTotals?: { TotalLocationCount?: number, TotalPackagedAmount?: number, MaterialId?: number }, NonPrimaryPickTotals?: { TotalLocationCount?: number, TotalPackagedAmount?: number, MaterialId?: number }}

interface IFootPrintManager_materials_navigational_gridComponentInParams {
  ownerId?: number, projectId?: number, materialIds?: number[]}


class FootPrintManager_materials_navigational_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_materials_navigational_gridComponent;
  entity: IFootPrintManager_materials_navigational_gridComponentEntity;


  options: { attachments: ButtonModel } = {
    attachments: new ButtonModel('attachments', new ButtonStyles(null, null), false, 'Attachments', '', null)

  }

 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    lot_controlled_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    serial_controlled_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_fixed_weight_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_fixed_volume_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    is_fixed_dimension_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    lookupcode: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    owner: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    project: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    source_catalog: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    total_primarypick_location_count: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    total_primarypick_location_sum: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    total_non_primarypick_location_sum: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
    lot_controlled: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['lot_controlled_display'] as DatexFormControl, null, true, '', null)
,
null
      ),
    serial_controlled: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['serial_controlled_display'] as DatexFormControl, null, true, '', null)
,
null
      ),
    is_fixed_weight: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['is_fixed_weight_display'] as DatexFormControl, null, true, '', null)
,
null
      ),
    is_fixed_volume: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['is_fixed_volume_display'] as DatexFormControl, null, true, '', null)
,
null
      ),
    is_fixed_dimension: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['is_fixed_dimension_display'] as DatexFormControl, null, true, '', null)
,
null
      ),
    description: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null, null, null, null)
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_materials_navigational_gridComponent, entity: IFootPrintManager_materials_navigational_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_materials_navigational_gridComponent, entity?: IFootPrintManager_materials_navigational_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      fullTextSearch:  $grid.fullTextSearch ,
      materialIds:  $grid.inParams.materialIds ,
    };
    const data = await this.datasources.Materials.ds_materials_navigational_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.total_primarypick_location_count.displayControl as TextModel).text = $utils.isDefined($row.entity.PrimaryPickTotals?.TotalLocationCount) ? $row.entity.PrimaryPickTotals?.TotalLocationCount?.toString() : '0';
    (this.cells.total_primarypick_location_sum.displayControl as TextModel).text = $utils.isDefined($row.entity.PrimaryPickTotals?.TotalPackagedAmount) ? $row.entity.PrimaryPickTotals?.TotalPackagedAmount?.toString() : '0';
    (this.cells.total_non_primarypick_location_sum.displayControl as TextModel).text = $utils.isDefined($row.entity.NonPrimaryPickTotals?.TotalPackagedAmount) ? $row.entity.NonPrimaryPickTotals?.TotalPackagedAmount?.toString() : '0';
    (this.cells.is_fixed_weight.displayControl as CheckBoxModel).reset($row.entity.IsFixedWeight);
    (this.cells.is_fixed_volume.displayControl as CheckBoxModel).reset($row.entity.IsFixedVolume);
    (this.cells.is_fixed_dimension.displayControl as CheckBoxModel).reset($row.entity.IsFixedLength);
    (this.cells.description.displayControl as TextModel).text = $row.entity.Description;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'lookupcode' : {
        this.on_material_clicked();
        break;
      }
      case 'project' : {
        this.on_project_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_materials_navigational_gridComponentRowModel,
  $grid: FootPrintManager_materials_navigational_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  var ControllerTypeId = $row.entity.ControllerTypeId;
  
  
  if (ControllerTypeId === 1){
  $row.cells.lot_controlled.displayControl.value = false;
  $row.cells.serial_controlled.displayControl.value = false;
  
  }
  if (ControllerTypeId === 2){
  $row.cells.lot_controlled.displayControl.value = true;
  $row.cells.serial_controlled.displayControl.value = false;  
  
  }
  if (ControllerTypeId === 3){
  $row.cells.lot_controlled.displayControl.value = false;
  $row.cells.serial_controlled.displayControl.value = true;    
  
  }
  if (ControllerTypeId === 4){
  $row.cells.lot_controlled.displayControl.value = true;
  $row.cells.serial_controlled.displayControl.value = true;  
  
  }
  // Apply custom project string logic
  if ($row.entity.Project?.LookupCode === $row.entity.Project?.Name) {
      $row.cells.project.displayControl.text = $row.entity.Project?.LookupCode
  } else {
      $row.cells.project.displayControl.text = $row.entity.Project?.LookupCode + ' ' + $row.entity.Project?.Name
  }
  
  // Apply custom owner string logic
  if ($row.entity.Project?.Owner?.LookupCode === $row.entity.Project?.Owner?.Name) {
      $row.cells.owner.displayControl.text = $row.entity.Project?.Owner?.LookupCode
  } else {
      $row.cells.owner.displayControl.text = $row.entity.Project?.Owner?.LookupCode + ' ' + $row.entity.Project?.Owner?.Name
  }
  
  
  // Material catalog feature
  let versionCheck = await $flows.Utilities.check_footprint_version({ minimumVersion: '24.10.25' });
  
  if (versionCheck.meetsMinimumVersion) {
  
      // @ts-ignore 
      const material_catalog_details = (await $datasources.Catalogs.ds_get_material_catalog_details_by_material_id.get({ material_id: $row.entity.Id })).result;
      if ($utils.isDefined(material_catalog_details) && $utils.isDefined(material_catalog_details[0]?.MaterialCatalogSourceMaterialId)) {
  
          $row.cells.source_catalog.displayControl.text = material_catalog_details[0].MaterialCatalog?.Name;
  
  
  
      }
  }
         
  }
  on_material_clicked(event = null) {
    return this.on_material_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_clickedInternal(
    $row: FootPrintManager_materials_navigational_gridComponentRowModel,
  $grid: FootPrintManager_materials_navigational_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootPrintManager.opensingle_material_hub({materialId: $row.entity.Id});
  }
  on_project_clicked(event = null) {
    return this.on_project_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_clickedInternal(
    $row: FootPrintManager_materials_navigational_gridComponentRowModel,
  $grid: FootPrintManager_materials_navigational_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  $shell.FootPrintManager.opensingle_project_hub({projectId: $row.entity.ProjectId});
  }
  on_attachments_clicked(event = null) {
    return this.on_attachments_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_attachments_clickedInternal(
    $row: FootPrintManager_materials_navigational_gridComponentRowModel,
  $grid: FootPrintManager_materials_navigational_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  await $shell.FootPrintManager.openmaterial_attachments_hubDialog({ materialId: $row.entity.Id });
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-materials_navigational_grid',
  templateUrl: './FootPrintManager.materials_navigational_grid.component.html'
})
export class FootPrintManager_materials_navigational_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_materials_navigational_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_materials_navigational_gridComponentInParams = { ownerId: null, projectId: null, materialIds: [] };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Material', false, false, null),       owner: new GridHeaderModel(new HeaderStyles(null, null), 'Owner', false, false, null),       project: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),       source_catalog: new GridHeaderModel(new HeaderStyles(null, null), 'Source catalog', false, false, null),       total_primarypick_location_count: new GridHeaderModel(new HeaderStyles(null, null), 'Total primary pick count', false, false, null),       total_primarypick_location_sum: new GridHeaderModel(new HeaderStyles(null, null), 'Total primary pick sum', false, false, null),       total_non_primarypick_location_sum: new GridHeaderModel(new HeaderStyles(null, null), 'Total non primary pick sum', false, false, null),       lot_controlled: new GridHeaderModel(new HeaderStyles(null, null), 'Lot controlled', false, false, null),       serial_controlled: new GridHeaderModel(new HeaderStyles(null, null), 'Serial controlled', false, false, null),       is_fixed_weight: new GridHeaderModel(new HeaderStyles(null, null), 'Fixed weight', false, false, null),       is_fixed_volume: new GridHeaderModel(new HeaderStyles(null, null), 'Fixed volume', false, false, null),       is_fixed_dimension: new GridHeaderModel(new HeaderStyles(null, null), 'Fixed dimension', false, false, null),       description: new GridHeaderModel(new HeaderStyles(null, null), 'Description', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_materials_navigational_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('materialIds') set $inParams_materialIds(value: any) {
    this.inParams['materialIds'] = value;
  }
  get $inParams_materialIds(): any {
    return this.inParams['materialIds'] ;
  }

  topToolbar = {
      update: new ToolModel(new ButtonModel('update', new ButtonStyles(null, null), false, 'Update', 'icon-ic_fluent_edit_20_regular', null)
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular', null)
    )
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Materials';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    this.pageSize = 100;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      fullTextSearch:  $grid.fullTextSearch ,
      materialIds:  $grid.inParams.materialIds ,
    };
    try {
    const data = await this.datasources.Materials.ds_materials_navigational_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_materials_navigational_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_materials_navigational_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Start on read only
  $grid.topToolbar.on_delete.control.readOnly = true;
  
  const selectedRowsCount = $grid.selectedRows.length;
  const selectedRows = $grid.selectedRows;
  
  // One or more rows selected
  if (selectedRowsCount > 0) {
  
      // Deletion
      $grid.topToolbar.on_delete.control.readOnly = !(await checkAllDeletable());
  
  
      for (const row of selectedRows) {
          // Material catalog lockdown
          let versionCheck = await $flows.Utilities.check_footprint_version({ minimumVersion: '24.10.25' });
  
          if (versionCheck.meetsMinimumVersion) {
  
              // @ts-ignore 
              const material_catalog_details = (await $datasources.Catalogs.ds_get_material_catalog_details_by_material_id.get({ material_id: row.entity.Id })).result;
              if ($utils.isDefined(material_catalog_details) && $utils.isDefined(material_catalog_details[0]?.MaterialCatalogSourceMaterialId)) {
                  $grid.topToolbar.update.control.readOnly = true;
              }
  
          }
     
      }
  }
  
  
  /**************************************************** 
   * FUNCTIONS
  *****************************************************/
  
  async function checkAllDeletable() {
  
      let allow: boolean = false;
      let material_ids = $grid.selectedRows.map(r => r.entity.Id);
  
      const result = await $flows.Materials.is_material_deletable_flow({ material_ids: material_ids });
  
      let material_ids_not_deletable: number[] = [];
  
      if ($utils.isDefined(result?.have_contents)) {
          material_ids_not_deletable.push(...result?.have_contents);
      }
      if ($utils.isDefined(result?.have_tasks)) {
          material_ids_not_deletable.push(...result?.have_tasks);
      }
      if ($utils.isDefined(result?.have_lines)) {
          material_ids_not_deletable.push(...result?.have_lines);
      }
  
      const valid_rows = $grid.selectedRows.filter(s => material_ids_not_deletable.indexOf(s.entity.Id) === -1) ?? [];
  
      if (valid_rows.length > 0) {
          allow = true;
      } else {
          allow = false;
      }
  
      return allow;
  }
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintManager_materials_navigational_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Error deleting materials', 'No materials selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
  
      let material_ids = selectedRows.map(r => r.entity.Id);
  
      const result = await $flows.Materials.is_material_deletable_flow({ material_ids: material_ids });
      const reason = result.reason;
  
      let material_ids_not_deletable: number[] = [];
  
      if ($utils.isDefined(result?.have_contents)) {
          errors.push(...selectedRows.filter(s => result.have_contents.indexOf(s.entity.Id) >= 0 && material_ids_not_deletable.indexOf(s.entity.Id) === -1).map(s => `Material ${s.entity.LookupCode} has contents.`));
          material_ids_not_deletable.push(...result?.have_contents);
      }
      if ($utils.isDefined(result?.have_tasks)) {
          errors.push(...selectedRows.filter(s => result.have_tasks.indexOf(s.entity.Id) >= 0 && material_ids_not_deletable.indexOf(s.entity.Id) === -1).map(s => `Material ${s.entity.LookupCode} has tasks.`));
          material_ids_not_deletable.push(...result?.have_tasks);
      }
      if ($utils.isDefined(result?.have_lines)) {
          errors.push(...selectedRows.filter(s => result.have_lines.indexOf(s.entity.Id) >= 0 && material_ids_not_deletable.indexOf(s.entity.Id) === -1).map(s => `Material ${s.entity.LookupCode} has lines.`));
          material_ids_not_deletable.push(...result?.have_lines);
      }
  
      const valid_rows = selectedRows.filter(s => material_ids_not_deletable.indexOf(s.entity.Id) === -1) ?? [];
      const invalid_rows = selectedRows.filter(s => material_ids_not_deletable.indexOf(s.entity.Id) >= 0) ?? [];
  
      if (invalid_rows.length > 0) { failures.push(...invalid_rows) }
      if (valid_rows.length > 0) { candidates.push(...valid_rows) }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Error deleting materials';
          const errorMessage = `Material(s) ${failures.map(c => c.entity.LookupCode).join(', ')} cannot be deleted`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Material(s) ${candidates.map(c => c.entity.LookupCode).join(', ')} - Once deleted, the material cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some material(s) cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Delete the following material(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const CandidateSuccess = [];
              const CandidateFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      let reasons: string[];
  
                      const flowParams = {
                          materialId: candidate.entity.Id
                      }
                      let reason = (await $flows.Materials.delete_material_flow(flowParams)).reason;
                      if ($utils.isDefined(reason)) {
                          reasons = [reason];
                      }
  
  
  
                      if ($utils.isDefined(reasons)) {
                          CandidateFailures.push(candidate);
                          errorMsgList.push(`Material ${candidate.entity.LookupCode} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: reasons })).formattedValue}`);
                      } else {
                          CandidateSuccess.push(candidate);
                      }
  
                  } catch (error) {
                      CandidateFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Material ${candidate.entity.LookupCode} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (CandidateSuccess.length === candidates.length) {
                  const title = 'All material(s) deleted';
                  const message = `Material(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} deleted`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (CandidateFailures.length === candidates.length) {
                      const title = 'All material(s) failed to delete';
                      const message = `Material(s) ${CandidateFailures.map(c => c.entity.LookupCode).join(',')} could not be deleted`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some material(s) could not be deleted';
                      const success = `Material(s) ${CandidateSuccess.map(c => c.entity.LookupCode).join(',')} were deleted.`;
                      const errors = `The following material(s) could not be deleted: ${CandidateFailures.map(c => c.entity.LookupCode).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_update_clicked(event = null) {
    return this.on_update_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_update_clickedInternal(
    $grid: FootPrintManager_materials_navigational_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Material Update Error', 'No materials selected.');
      return;
  } else {
  
      const dialogResult = await $shell.FootPrintManager.openmaterial_update_formDialog();
      const userConfirmed = dialogResult.confirm;
      const materialGroupId = dialogResult.materialGroupId;
      const shelfLifeSpan = dialogResult.shelfLifeSpan;
      const statusId = dialogResult.statusId;
      const allocationStrategyId = dialogResult.allocationStrategyId
      const allocationStrategyWorkflowId = dialogResult.allocationStrategyWorkflowId;
  
      let payload: any = {};
  
  
      if (userConfirmed) {
          for (const row of selectedRows) {
  
              if ($utils.isDefined(materialGroupId)) {
                  payload.MaterialGroupId = materialGroupId;
              }
              if (shelfLifeSpan) {
                  payload.ShelfLifeSpan = shelfLifeSpan;
              }
              if (statusId) {
                  payload.StatusId = statusId;
              }
              if ($utils.isDefined(allocationStrategyId)) {
                  payload.AllocationStrategyId = allocationStrategyId;
              }
              if ($utils.isDefined(allocationStrategyWorkflowId)) {
                  payload.AllocationStrategyWorkflowId = allocationStrategyWorkflowId;
              }
  
  
              await $flows.Utilities.crud_update_flow({ entitySet: 'Materials', id: row.entity.Id, entity: payload });
  
          }
          await $grid.refresh();
      }
  }
  }
  on_excel_export_flow(event = null) {
    return this.on_excel_export_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_excel_export_flowInternal(
    $grid: FootPrintManager_materials_navigational_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const wb = $event;
  const clientExportLimit = $settings.FootPrintManager.ExcelExportLimit;;
  const serverLimit = 5000;
  const records = [];
  
  try {
      // Call the api with the same filters(grid inputs)
      // Get the count of the query
      // First time call is the call the decides if we need more calls or not based on the server side limit.
      const { totalCount: requiredCount } = (await $datasources.Materials.ds_materials_navigational_grid.getList({
          materialIds: $grid.inParams.materialIds,
          ownerId: $grid.inParams.ownerId,
          projectId: $grid.inParams.projectId,
          fullTextSearch: $grid.fullTextSearch,
          $top: 0
      }));
  
  
      if(requiredCount > clientExportLimit){
          await $shell.Materials.openInfoDialog(`Export limitation of ${clientExportLimit} exceeded`, `Excel will only export the first ${clientExportLimit} records`);
      }
  
      if (serverLimit < clientExportLimit) {
          // Generate a list of promises for concurrent requests
          const fetchPromises = [];
          let currentOffset = 0;
          let currentRemainingCounter = clientExportLimit;
  
          while (currentRemainingCounter > 0) {
          const limit = Math.min(serverLimit, currentRemainingCounter);
          fetchPromises.push(fetchRecords(currentOffset, limit));
          currentOffset += limit;
          currentRemainingCounter -= limit;
          }
  
          // Wait for all promises to resolve
          const additionalResponses = await Promise.all(fetchPromises);
  
          // Combine results from all additional calls
          additionalResponses.forEach((apiResponse) => {
          records.push(...apiResponse.result);
          });
      } else {
          // Make a normal call to get all the required records
          const { result: response } =
          await $datasources.Materials.ds_materials_navigational_grid.getList({
              materialIds: $grid.inParams.materialIds,
              ownerId: $grid.inParams.ownerId,
              projectId: $grid.inParams.projectId,
              fullTextSearch: $grid.fullTextSearch,
              $top: clientExportLimit
          });
          records.push(...response);
      }
  
      // Compile all data into JSON
      const formatedResults = records.map(record => {
          let lotControlled = false;
          let serialControlled = false;
  
          if (record.ControllerTypeId === 2) {
              lotControlled = true;
          } else if (record.ControllerTypeId === 3) {
              serialControlled = true;
          } else if (record.ControllerTypeId === 4) {
              lotControlled = true;
              serialControlled = true;
          }
  
          let projectText = record.Project?.LookupCode;
          if (record.Project?.LookupCode !== record.Project?.Name) {
              projectText += ' ' + record.Project?.Name;
          }
  
          let ownerText = record.Project?.Owner?.LookupCode;
          if (record.Project?.Owner?.LookupCode !== record.Project?.Owner?.Name) {
              ownerText += ' ' + record.Project?.Owner?.Name;
          }
  
          return {
              "Lookupcode": record.LookupCode,
              "Owner": ownerText,
              "Project": projectText,
              "Total primary pick count": record.PrimaryPickTotals?.TotalLocationCount ?? 0,
              "Total primary pick sum": record.PrimaryPickTotals?.TotalPackagedAmount ?? 0,
              "Total non primary pick count": record.NonPrimaryPickTotals?.TotalPackagedAmount ?? 0,
              "Lot controlled": lotControlled,
              "Serial controlled": serialControlled,
              "Fixed weight": record.IsFixedWeight,
              "Fixed volume": record.IsFixedVolume,
              "Fixed dimension": record.IsFixedLength,
              "Description": record.Description,
          };
      });
  
  
      // Generate the excel sheet from the JSON
      const ws = $utils.excel.json_to_sheet(formatedResults);
      $utils.excel.book_append_sheet(wb, ws, "Materials");
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      throw new Error(errorMessage);
  }
  
  // Helpers
  
  // Function to fetch records with offset and limit
  async function fetchRecords (offset: number, limit:number) : Promise<any> {
      return await $datasources.Materials.ds_materials_navigational_grid.getList({
          materialIds: $grid.inParams.materialIds,
          ownerId: $grid.inParams.ownerId,
          projectId: $grid.inParams.projectId,
          fullTextSearch: $grid.fullTextSearch,
          $skip: offset,
          $top: limit
      });
  };
  
  
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_excel_export_flow(wb);

    writeExcelFile(wb, 'FootPrintManager_materials_navigational_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
